/**
 * @namespace Steps
 * @memberof PRSapp
 */
(function($, viewport) {
    "use strict";

    PRSapp.Steps = (function() {
        var init,
            currentStep = 1,
            totalSteps;

        /**
         * @function init
         * @memberof! PRSapp.Steps
         */
        init = function() {

            hideAllSteps();

            showStep(currentStep);

            setUpStepsNavigation();

            getTotalSteps();

            positionStepsProgressBar();

        };

        function hideAllSteps() {
            $('[data-step]').removeClass('active');
        }

        function showStep(stepNumber) {
            $('[data-step="'+stepNumber+'"]').addClass('active');
            currentStep = stepNumber;

            configureButtons();
        }

        function setUpStepsNavigation() {
            setUpProgressBar();
            setUpBackButton();
            setUpNextButton();
        }

        function setUpProgressBar() {
            $('.steps__nav-link').on('click', function(e) {
                e.preventDefault();

                var stepNumber = parseInt($(this).attr('href'));

                hideAllSteps();
                showStep(stepNumber);
            });
        }

        function setUpBackButton() {
            $('.steps__footer-back').on('click', function() {
                var previousStep = currentStep - 1;

                hideAllSteps();
                showStep(previousStep);
                snapToTop($(this));
            });
        }

        function setUpNextButton() {
            $('.steps__footer-next').on('click', function() {
                var nextStep = currentStep + 1;

                hideAllSteps();
                showStep(nextStep);
                snapToTop($(this));
            });
        }

        function snapToTop($element) {
            enquire.register("screen and (max-width:768px)", {
                match : function() {
                    $element.parents('.steps__container')[0].scrollIntoView( true );
                }
            });
        };

        function getTotalSteps() {
            totalSteps = $('[data-step-count]').data('step-count');
        }

        function configureButtons() {
            if(currentStep === 1) {
                PRSapp.Utils.disableButton($('.steps__footer-back'));
            }
            
            if(currentStep > 1) {
                PRSapp.Utils.enableButton($('.steps__footer-back'));
            }
            
            if(currentStep < totalSteps) {
                PRSapp.Utils.enableButton($('.steps__footer-next'));
            }
            
            if(currentStep === totalSteps) {
                PRSapp.Utils.disableButton($('.steps__footer-next'));
            }
        }

        function positionStepsProgressBar() {
            var progressBarClass = '';

            switch(totalSteps) {
                case 2:
                    progressBarClass = 'steps__nav-list--two-steps'
                    break;
                case 3:
                    progressBarClass = 'steps__nav-list--three-steps'
                    break;
                case 4:
                    progressBarClass = 'steps__nav-list--four-steps'
                    break;
                case 5:
                    progressBarClass = 'steps__nav-list--five-steps'
                    break;
                case 6:
                    progressBarClass = 'steps__nav-list--six-steps'
                    break;
            }

            $('.steps__nav-list').addClass(progressBarClass);
        }
        
        return {
          init : init
        };
    }());

    if($('[data-steps="true"]').length > 0) {
        PRSapp.Steps.init();
    }

})(jQuery, ResponsiveBootstrapToolkit);