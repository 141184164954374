/**
 * @namespace Campaign
 * @memberof PRSapp
 */
(function ($, _window) {
  PRSapp.Campaign = (function () {
    const DELAY_SCROLLING = 250;

    let links;
    let linkIndex = 0;
    let isScrolling = false;
    let featureIndex = 0;
    let features;

    function hideCoverPhoto($playButton) {
      $playButton.hide();
      $playButton.next('.video__cover-image').hide();
    }

    const injectVideoAPI = (apiJavascriptUrl) => {
      const tag = document.createElement('script');
      const firstScriptTag = document.getElementsByTagName('script')[0];

      tag.src = apiJavascriptUrl;
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    };

    const playBrightcoveVideo = ($playButton) => {
      const videoId = $playButton.siblings('.video-js').attr('id');
      console.log(videoId);
      videojs(videoId).play();
    };

    const setUpVideoPlay = () => {
      $('.video__play-button-container').on('click', function () {
        hideCoverPhoto($(this));
        playBrightcoveVideo($(this));
      });
    };

    const isMobile = () => $('.visible-sm').is(':visible');
    const isTabletOrMobile = () => $('.visible-md').is(':visible');

    const scrollToLinkNew = (index, isReverse) => {
      $('.features-container .feature-card').removeClass('active');

      const width = isTabletOrMobile() ? 448 : 600;
      const xPos = width * index;

      $('.features-container').stop().animate({scrollLeft: xPos}, DELAY_SCROLLING);
      $('.features-container .feature-card').eq(index).addClass('active');
    };

    const scrollCards = () => {
      if (isScrolling || (featureIndex) >= features.length - 1) return;
      isScrolling = true;
      featureIndex += 1;
      if (featureIndex >= features.length) 
        featureIndex = features.length - 1;
      else {
        scrollToLinkNew(featureIndex, false);
        updateScrollButtonsStates(featureIndex);
        isScrolling = false;
      }
    };

    const scrollCardsBack = () => {
      if (isScrolling || featureIndex === 0) return;
      isScrolling = true;
      featureIndex -= 1;
      if (featureIndex < 0) featureIndex = 0;
      scrollToLinkNew(featureIndex, true);
      updateScrollButtonsStates(featureIndex);
      isScrolling = false;
    };

    const setupScrollButtons = () => {
      $('#arrow-scroll-left').on('click', scrollCardsBack);
      $('#arrow-scroll-right').on('click', scrollCards);

       updateScrollButtonsStates(0);

    };

    const setUpFeatureReferences = () => {
      features = $('.features-container .feature-card').map(function () {
        return `${this.id}`;
      }).get();
    };

    const disableButton = (id) => {
      $(id).removeClass('active');
    };

    const activateButton = (id) => {
      $(id).addClass('active');
    };

    const showCard = (id) => {
      $(id).removeClass('hidden');
    };

    const hideCard = (id) => {
      $(id).addClass('hidden');
    };

    const updateButtonsStates = (index) => {
      if (index >= links.length - 1) {
        disableButton('#arrow-right');
      } else {
        activateButton('#arrow-right');
      }

      if (index === 0) {
        disableButton('#arrow-left');
      } else {
        activateButton('#arrow-left');
      }
    };

    const updateScrollButtonsStates = (index) => {
      if ((isTabletOrMobile() && index === features.length - 1) || 
        (!isTabletOrMobile() && index === features.length - 3)) {
          disableButton('#arrow-scroll-right');
        } else {
          activateButton('#arrow-scroll-right');
        }

      if (index === 0) {
        disableButton('#arrow-scroll-left');
      } else {
        activateButton('#arrow-scroll-left');
      }
    };

    const changeCard = (isForward) => {
      if (linkIndex + 1 === links.length && isForward) return;
      if (linkIndex === 0 && !isForward) return;
      hideCard(links[linkIndex]);
      linkIndex = isForward ? linkIndex + 1 : linkIndex - 1;
      showCard(links[linkIndex]);
      updateButtonsStates(linkIndex);
    };

    const setupArrowButtons = () => {
      activateButton('#arrow-right');
      $('#arrow-left').on('click', () => changeCard(false));
      $('#arrow-right').on('click', () => changeCard(true));
    };

    const setUpLinkReferences = () => {
      links = $('.m-mag-video-container .m-mag-card').map(function () {
        return `#${this.id}`;
      });
    };

    const initialiseTipStorage = () => {
      const tips = $('.tip-bg').map(function () {
        return `${this.id}`;
      }).get();

      localStorage.setItem('tips-unseen', JSON.stringify(tips));
      localStorage.setItem('tips-seen', '[]');
    };

    const setUpTipCards = () => {
      $('.tip-bg').addClass('hidden');

      const seenCards = localStorage.getItem('tips-seen');
      const unseenCards = JSON.parse(localStorage.getItem('tips-unseen')) || [];

      if (!seenCards || unseenCards.length === 0) initialiseTipStorage();
    };

    const chooseTipCard = () => {
      const unseenCards = JSON.parse(localStorage.getItem('tips-unseen'));
      const seenCards = JSON.parse(localStorage.getItem('tips-seen'));

      const cardToShowId = unseenCards.shift();
      localStorage.setItem('tips-unseen', JSON.stringify(unseenCards));
      localStorage.setItem('tips-seen', JSON.stringify([...seenCards, cardToShowId]));

      $('.tip-bg').each(function () {
        const cardElm = $(this);
        if (cardElm[0].id === cardToShowId) { cardElm.removeClass('hidden'); } else { cardElm.addClass('hidden'); }
      });
    };

    /**
    * @function init
    * @memberof! PRSapp.Campaign
    */
    const init = () => {
      setUpLinkReferences();
      injectVideoAPI('//players.brightcove.net/60142679001/wpAJR8LGB_default/index.min.js');
      setUpVideoPlay();
      setupArrowButtons();
      setUpTipCards();
      chooseTipCard();
      setUpFeatureReferences();
      setupScrollButtons();
    };


  return {
      init,
    };
  }());

  if ($('#campaign-page').length > 0) {
    PRSapp.Campaign.init();
  }
// eslint-disable-next-line no-undef
}(jQuery, window));
