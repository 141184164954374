(function ($) {
  "use strict";
  PRSapp.iceSingleSignOn = (function () {
    var config = {};

    function init() {
      config = {
        button: "#ice-submit",
        form: "#icesinglesign-form",
            };
      bindUIActions();
    };


    var bindUIActions = function () {
      onConfirmChanges();
    }

    var onConfirmChanges = function () {

      $(config.button).click(function (e) {
        e.preventDefault();       
        var button = $(this);
        $(config.button).attr("disabled", true); 
        $(config.form).submit();      
       
        });
    
    };

    return {
      init: init,
      
    };

  }());

  if ($("#icesinglesign-form").length > 0) {
    PRSapp.iceSingleSignOn.init();
   
  }
}(jQuery));