(function ($) {
    "use strict";
    PRSapp.sitecoreCaptcha = (function () {
        var config = {};
        function init() { 
            config = {
                errorMessage: errorMessage
            };
            $('.recaptcha').click(function (e) {
                var recaptchaResponse = grecaptcha.getResponse();
                if (recaptchaResponse.length != 0) {
                  $('.googleRecaptchaClass').val(recaptchaResponse);
                  $('.msg-error').text('');
                }
                else{
                    $('.msg-error').text(errorMessage);
                }
              })
        };      
        return {
            init: init
            };

    }());

    if ($("#feedback-form").length > 0 && $(".g-recaptcha").length > 0 ) {
        PRSapp.sitecoreCaptcha.init();
       }

}(jQuery));


