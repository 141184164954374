(function ($) {
  "use strict";
  PRSapp.manualaddress = (function () {

    var config = {};
    function init() {

      config = {
        currentStep: currentStep,
      };

      bindUIActions();
    };



    function setFormsValidation() {
      var rules = {
       
        'Address.OldAddress1': {
          required: true,
          maxlength: 150,
          regex: "^[a-zA-Z0-9,.'-+\\s]*$"
         },
         'Address.OldAddress2': {
          maxlength: 150,
          regex: "^[a-zA-Z0-9,.'-+\\s]*$"
         },
         'Address.OldAddress3': {
          maxlength: 150,
          regex: "^[a-zA-Z0-9,.'-+\\s]*$"
         },
         'Address.OldAddress4': {
          maxlength: 150,
          regex: "^[a-zA-Z0-9,.'-+\\s]*$"
         },
         'Address.NewAddress1': {
          required: true,
          maxlength: 150,
          regex: "^[a-zA-Z0-9,.'-+\\s]*$"
        },
        'Address.NewAddress2': {
          maxlength: 150,
          regex: "^[a-zA-Z0-9,.'-+\\s]*$"
         },
         'Address.NewAddress3': {
          maxlength: 150,
          regex: "^[a-zA-Z0-9,.'-+\\s]*$"
         },
         'Address.NewAddress4': {
          maxlength: 150,
          regex: "^[a-zA-Z0-9,.'-+\\s]*$"
         },
        'Address.NewEmail': {
          required: true,
          email: true
        },
        'Address.OldPostCode': {
          required: true,
          maxlength: 15
        },
        'Address.NewPostCode': {
          required: true,
          maxlength: 15
        }
        
      };

      var messages = {
        
        'Address.OldAddress1': {
          required: 'This field is required',
          maxlength: 'Maximum 150 characters are allowed',
          regex: 'Invalid field. Please use standard characters'
         },
        'Address.OldAddress2': {
          maxlength: 'Maximum 150 characters are allowed',
          regex: 'Invalid field. Please use standard characters'
         },
        'Address.OldAddress3': {
          maxlength: 'Maximum 150 characters are allowed',
          regex: 'Invalid field. Please use standard characters'
         },
        'Address.OldAddress4': {
          maxlength: 'Maximum 150 characters are allowed',
          regex: 'Invalid field. Please use standard characters'
         },
        'Address.NewAddress1': {
          required: 'This field is required',
          maxlength: 'Maximum 150 characters are allowed',
          regex: 'Invalid field. Please use standard characters'
        },
        'Address.NewAddress2': {
          maxlength: 'Maximum 150 characters are allowed',
          regex: 'Invalid field. Please use standard characters'
        },
        'Address.NewAddress3': {
          maxlength: 'Maximum 150 characters are allowed',
          regex: 'Invalid field. Please use standard characters'
        },
        'Address.NewAddress4': {
          maxlength: 'Maximum 150 characters are allowed',
          regex: 'Invalid field. Please use standard characters'
        },
        'Address.NewEmail': {
          required: 'This field is required',
          email: 'This field is not valid'
        },
        'Address.OldPostCode': {
          required: 'This field is required',
          maxlength: 'Maximum 15 characters are allowed'
        },
        'Address.NewPostCode': {
          required: 'This field is required',
          maxlength: 'Maximum 15 characters are allowed'
        },
        
      };

      var isValid = $('#manual-address-form').validate({
        errorClass: 'has-error',
        validClass: 'has-success',
        ignore: ':not(:visible)',
        highlight: function (element, errorClass, validClass) {
          $(element).parents('.form-group').addClass(errorClass).removeClass(validClass);
          $(element).parents('.form-group').find('.sprite').removeClass('hidden');
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).parents('.form-group').addClass(validClass).removeClass(errorClass);
          $(element).parents('.form-group').find('.sprite').addClass('hidden');
        },
        errorPlacement: function (error, element) {
          error.insertAfter(element);
        },
        rules: rules,
        messages: messages
      });
      
      $('#Next').on("click", function () {
        if ($('#manual-address-form').valid()) {
        currentStep = parseInt(currentStep) + parseInt(1);
        displayControls(currentStep);
        }
      });

      $('#Back').on("click", function () {
        currentStep = parseInt(currentStep) - parseInt(1);
        displayControls(currentStep);
      });

      $('#Submit').on("click", function () {
        if ($('#manual-address-form').valid()) {         
          $('#Submit').attr('disabled','disabled');
          $('#manual-address-form').submit();
        }
      });

    }

    function displayControls(currentStep)
    {
       if(currentStep == 1)
       {
        $("#addressHeading").text("Step 1 of 4");
        $("#OldAddressSection").show();
        $("#NewAddressSection").hide();
        $("#ContactDetailsSection").hide();
        $("#MoreDetailsSection").hide();
        $("#Next").show();
        $("#Back").hide();
        $("#Submit").hide();
       }
       else if(currentStep == 2)
       {
        $("#addressHeading").text("Step 2 of 4");
        $("#OldAddressSection").hide();
        $("#NewAddressSection").show();
        $("#ContactDetailsSection").hide();
        $("#MoreDetailsSection").hide();
        $("#Next").show();
        $("#Back").show();
        $("#Submit").hide();
       }
       else if(currentStep == 3)
       {
        $("#addressHeading").text("Step 3 of 4");
        $("#OldAddressSection").hide();
        $("#NewAddressSection").hide();
        $("#ContactDetailsSection").show();
        $("#MoreDetailsSection").hide();
        $("#Next").show();
        $("#Back").show();
        $("#Submit").hide();
       }
       else 
       {
        $("#addressHeading").text("Step 4 of 4");
        $("#OldAddressSection").hide();
        $("#NewAddressSection").hide();
        $("#ContactDetailsSection").hide();
        $("#MoreDetailsSection").show();
        $("#Next").hide();
        $("#Back").show();
        $("#Submit").show();
       }
    }

    function bindUIActions() {
      displayControls(currentStep);
    }

    return {
      init: init,
      setFormsValidation: setFormsValidation,
     };

  }());

  if ($("#manual-address-form").length > 0) {

    PRSapp.manualaddress.init();
    PRSapp.manualaddress.setFormsValidation();
   

  }

}(jQuery));