(function ($) {
  "use strict";
  PRSapp.editUserPermission = (function () {
    var config = {};

    function init() {
      config = {
        button: "#approve",
        editUrl: editPermissionsUrl,
        form: "#edit-permissions-form",
        approveMessage: approveMessage

      };
      bindUIActions();
    };


    var bindUIActions = function () {
      onConfirmChanges();
    }

    var onConfirmChanges = function () {
      $("#edit-permissions-form").prev().remove();
      var selectedCheckBoxes = $("#edit-permissions-form").find("input:checked");
      if (selectedCheckBoxes.length < 1) { $(button).attr("disabled", true); }
      $('.onoffswitch-checkbox').change(function () {
        $("#message").hide();
        $("#approve").attr("disabled", false);
        var selectedCheckBoxes =  $("#edit-permissions-form").find("input:checked").not("input[name ='isadmin']");;
        if (selectedCheckBoxes.length < 1) { $("#approve").attr("disabled", true); }
      });
      $(config.button).click(function (e) {
        e.preventDefault();
        var button = $(this);
        var url = $(this).attr("href");
        var selectedCheckBoxes =  $("#edit-permissions-form").find("input:checked").not("input[name ='isadmin']");;
        var hiddenCheckBoxes = $("#edit-permissions-form").find("input[name ='hpf']");
        var isAdmin = $("#edit-permissions-form").find("input[name ='isadmin']:checked").length > 0 ? true : false;
        config.selectedAppRoles = [];

        if (selectedCheckBoxes.length > 0) {
          $.each(selectedCheckBoxes, function (key, value) {
            var appRoleId = $(value).attr("value");
            config.selectedAppRoles.push(appRoleId);
          });
        }

        else { return; }

        if (hiddenCheckBoxes.length > 0) {
          $.each(hiddenCheckBoxes, function (key, value) {
            var appRoleId = $(value).attr("value");
            config.selectedAppRoles.push(appRoleId);
          });
        }


        $("#approve").attr("disabled", true);
        var model = {
          'ProfileId': $("#ProfileId").val(),
          'SelectedAppRoles': config.selectedAppRoles,
          'isAdmin': isAdmin,
          'OnSaveUrl': config.editUrl
        }

        return iamConfirmSwitchProfile(config.approveMessage, "", function (result) {
          if (result) {
            $.ajax({
              url: url,
              type: 'POST',
              data: { 'editPermissionModel': model },
              success: function (data, textStatus, xhr) {
                $("#message").show();
                $("html, body").animate({ scrollTop: 0 }, "slow");
                $("#approve").attr("disabled", false);
              },
              error: function (xhr) {
                alert(xhr.statusText);
                $("#approve").attr("disabled", false);
              }
            });
          }
          else {
            return false;
          }
        });

      });
    };

    return {
      init: init
    };

  }());

  if ($("#edit-permissions-form").length > 0) {
    PRSapp.editUserPermission.init();
  }
}(jQuery));