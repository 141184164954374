/**
 * @namespace home
 * @memberof PRSapp
 */
(function ($) {
  "use strict";

  PRSapp.home = (function () {
    var init,
      caeFlag,
      caeChecked = false;

    /**
     * @function init
     * @memberof! PRSapp.home
     */
    init = function () {

      $('.instagram-component .Header').css('display', 'none');
      $('.instagram-component .Footer').css('display', 'none');
    
      setUpHeroCount();

      setUpLicencesCta();

      if ($('.homepage-services__container').length > 0) {
        setUpServicesShowMore();
      }

      if ($('.value-toggle__button').length > 0) {
        setUpValueHiding();
      }

    };

    function setUpHeroCount() {

      var options = {
        useEasing: false,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ''
      };

      var countUpObj = [];

      $.each($('[data-count-start]'), function () {

        var newCountUp = {
          element: $(this).attr('id'),
          start: $(this).data('count-start'),
          end: $(this).data('count-end'),
          decimals: $(this).data('count-decimals'),
          countUp: null
        }

        newCountUp.countUp = new CountUp(newCountUp.element, newCountUp.start, newCountUp.end, newCountUp.decimals, 2, options);

        countUpObj.push(newCountUp);

      });

      $.each(countUpObj, function () {
        this.countUp.start();
      });
    };

    function setUpLicencesCta() {
      $('.homepage-licences__cta').on('click', function (e) {
        e.preventDefault();

        var newLocation = $('#homepage-licences__select').val();
        window.location.href = newLocation;

      });
    };

    function setUpServicesShowMore() {
      var openHeight;

      enquire.register("screen and (min-width:0px) and (max-width: 768px)", {
        match: function () {
          closeServiceExpander();
          openHeight = calculateServiceExpanderHeight(1);
        }
      });

      enquire.register("screen and (min-width:769px) and (max-width: 1200px)", {
        match: function () {
          closeServiceExpander();
          openHeight = calculateServiceExpanderHeight(2);
        }
      });

      enquire.register("screen and (min-width: 1201px)", {
        match: function () {
          closeServiceExpander();
          openHeight = calculateServiceExpanderHeight(3);
        }
      });

      $('.homepage-services__show-more').on('click', function (e) {
        var currentText = $(this).text();

        if (currentText.toLowerCase() === 'show more') {
          openServiceExpander(openHeight);
        }
        else {
          closeServiceExpander();
        }
      });

      $('.homepage-services__show-more').keyup(function (e) {
        if (e.keyCode == 13 || e.keyCode == 32) {
          $('.homepage-services__service').each(function () {
            if ($(this).attr('tabindex')) {
              $(this).removeAttr('tabindex');
              $('.homepage-services__service')[6].focus();
            }
            else {
              $(this).attr('tabindex', -1);
            }
          })
        }
      });
    };

    function openServiceExpander(serviceExpanderHeight) {
      $('.homepage-services__show-more').text('Show Less');

      $('.homepage-services__expander').css({
        'height': serviceExpanderHeight
      });
    };

    function closeServiceExpander() {
      $('.homepage-services__show-more').text('Show More');

      $('.homepage-services__expander').css({
        'height': 0
      });
    };

    function calculateServiceExpanderHeight(serviceColumns) {

      var $services = $('.homepage-services__expander').find('.homepage-services__service'),
        serviceExpanderHeight = 0;

      if (serviceColumns === 1) {

        var servicesHeight = 0;

        $services.each(function () {
          servicesHeight = servicesHeight + $(this).parent().height();
        });

        serviceExpanderHeight = servicesHeight / serviceColumns;

      }
      else if (serviceColumns === 2 || serviceColumns === 3) {

        var $services = $('.homepage-services__expander').find('.homepage-services__service'),
          serviceCount = $services.length,
          serviceHeight = $services.parent().height(),
          serviceRows = Math.ceil(serviceCount / serviceColumns);

        serviceExpanderHeight = serviceRows * serviceHeight;

      }

      return serviceExpanderHeight;
    }

    function setUpValueHiding() {

      if ($('#count_royalties').length > 0 && $('#count_royalties').hasClass('active'))
      {
        var element = $('.home-page__header__lead').children(".value-toggle");
        if(element.length > 0 && !(element.hasClass('active')))
          {
            element.addClass('active')
          }
      }

      $('.value-toggle__button').on('click', function () {
        $('.value-toggle').toggleClass('active');
        var flagValue;
        if ($(this).hasClass('active')) {
          flagValue = 1;
        }

        else {
          flagValue = 0;
        }

        $.post("/api/sitecore/Homepage/StoreDaoPreference", { cookieFlag: flagValue },function (data) {
         
        });

        var titleText = $(this).next('.home-page__header__stat__title').text();

        if (titleText.toLowerCase() === 'last payment') {
          $(this).next('.home-page__header__stat__title').text('Show My Last Payment');
        }
        else {
          $(this).next('.home-page__header__stat__title').text('Last Payment');
        }
      });
    };

    $('#amount-show-hide').click(function(e) {
      e.preventDefault();
      var txt = 'Hide';
      var flagValue = 1;

      if ($(this).find("strong").text() === 'Hide') {
        $('.bi-prs-show').addClass('bi-prs-hide').removeClass('bi-prs-show');
        flagValue = 0;
        txt = 'Show';
      } 
      else {
        $('.bi-prs-hide').addClass('bi-prs-show').removeClass('bi-prs-hide');
      }

      $(this).find("strong").text(txt);

      var cntr = $(this).parent().parent();
      cntr.find("#amount-total").animate({width:'toggle'});
      cntr.find("#amount-total-summary").toggle();

      $.post("/api/sitecore/Homepage/StoreDaoPreference", { cookieFlag: flagValue },function (data) {
         
      });
    });

    $('#more-switch').click(function(e) {
      e.preventDefault();
      $('#apps-panel').slideDown(500);

      $(this).hide();

      $('#less-switch').show();
    });

    $('#less-switch').click(function(e) {
      e.preventDefault();
      $('#apps-panel').slideUp(500);

      $(this).hide();

      $('#more-switch').show();
    });

    return {
      init: init
    };
  }());

  if ($('#home_page').length > 0) {
    PRSapp.home.init();
  }
}(jQuery));