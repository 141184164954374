(function ($) {
  "use strict";
  PRSapp.autoaddress = (function () {

    var config = {};
    function init() {

      config = {
        
      };

      bindUIActions();
    };



    function setFormsValidation() {
      var rules = {
       
        'Addressee': {
          required: true,
          maxlength: 150,
          regex: "^[a-zA-Z0-9,.'-+\\s]*$",
          custom : validateAddressee($(this))
         },
         'Addressee2': {
          maxlength: 150,
          regex: "^[a-zA-Z0-9,.'-+\\s]*$"
         },
         'PostCode': {
          required: true,
          maxlength: 15,
          custom: validatePostCode()
        },
        'AddressLine1': {
          required: true,
          maxlength: 150,
          regex: "^[a-zA-Z0-9,.'-+\\s]*$"
        },
        'AddressLine2': {
          maxlength: 150,
          regex: "^[a-zA-Z0-9,.'-+\\s]*$"
        },
        'AddressLine3': {
          maxlength: 150,
          regex: "^[a-zA-Z0-9,.'-+\\s]*$"
        },
        'Town': {
          required: true,
          maxlength: 100,
          regex: "^[a-zA-Z0-9,.'-+\\s]*$"
        }
        
      };

       var messages = {
        
        'Addressee': {
          required: 'This field is required',
          maxlength: 'Maximum 150 characters are allowed',
          regex: 'Invalid field. Please use standard characters',
          custom: 'Please use FAO instead of c\o on addressee line 1'
         },
         'Addressee2': {
          maxlength: 'Maximum 150 characters are allowed',
          regex: 'Invalid field. Please use standard characters',
         },
         'PostCode': {
          required: 'This field is required',
          maxlength: 'Maximum 15 characters are allowed',
          custom: 'Please supply a valid postcode'
          },
        'AddressLine1': {
          required: 'This field is required',
          maxlength: 'Maximum 150 characters are allowed',
          regex: 'Invalid field. Please use standard characters'
        },
        'AddressLine2': {
          maxlength: 'Maximum 150 characters are allowed',
          regex: 'Invalid field. Please use standard characters'
        },
        'AddressLine3': {
          maxlength: 'Maximum 150 characters are allowed',
          regex: 'Invalid field. Please use standard characters'
        },
        'Town': {
          required: 'This field is required',
          maxlength: 'Maximum 100 characters are allowed',
          regex: 'Invalid field. Please use standard characters'
        },
        
      };

      var isValid = $('#auto-address-form').validate({
        errorClass: 'has-error',
        validClass: 'has-success',
        ignore: ':not(:visible)',
        highlight: function (element, errorClass, validClass) {
          $(element).parents('.form-group').addClass(errorClass).removeClass(validClass);
          $(element).parents('.form-group').find('.sprite').removeClass('hidden');
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).parents('.form-group').addClass(validClass).removeClass(errorClass);
          $(element).parents('.form-group').find('.sprite').addClass('hidden');
        },
        errorPlacement: function (error, element) {
          error.insertAfter(element);
        },
        rules: rules,
        messages: messages
      });
      
      $('#AddressDetailsSection a').on("click", function (e) {
        e.preventDefault();
        var val = $(this).attr("name");
        $("#AddressDetailsSection").hide();
        $("#AddressFormSection").show();
        $("#SpecialInstructionsSection").hide();
        $('#UpdateType').val(val);
        if(val == 'PRS')
        {
          $("#appMP").html('Apply this address to MCPS account also');
          $("#AddressFormSection h2").html('PRS Membership');
          $('#ApplyToMCPS').show();
          $('#ApplyToPRS').hide();
        }
        if(val == 'MCPS')
        {
          $("#appMP").html('Apply this address to PRS account also');
          $("#AddressFormSection h2").html('MCPS Membership');
          $('#ApplyToMCPS').hide();
          $('#ApplyToPRS').show();
        }
      });
      
      $('#AddressFormSection #Cancel').on("click", function (e) {
        $("#AddressDetailsSection").show();
        $("#AddressFormSection").hide();
        $("#SpecialInstructionsSection").hide();
        resetForm();
      });
     
      $('#PostCode').on('input', function() {
        var $regexname = "^([A-PR-UWYZ][A-HK-Y0-9][A-Z0-9]?[ABEHMNPRVWXY0-9]? {1,2}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)$";
        if($(this).val().length > 0 && $(this).val().match($regexname))
        {
           $('#FindAddress').removeAttr('disabled');
        }
        else{
          $('#FindAddress').prop('disabled','true') 
        }
    });

      $('#AddressFormSection #Submit').on("click", function () {
         if ($('#auto-address-form').valid()) {    
          $('#Submit').attr('disabled','disabled');                   
          var formData = $("#auto-address-form").serialize();   
          var url = $(this).attr("action-url");
          $("#addressFormError").css('display','none');
            //to get alert popup   
            $.ajax({   
                url: url,   
                type: "POST",   
                dataType: 'json',  
                data: formData,
                contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                success: function (data, textStatus, xhr) {
                  location.reload();
                 
                },
                error: function (xhr) {
                  if(xhr.status == 200)
                  {
                    location.reload();
                  }
                  else
                  {
                    $("#Submit").attr("disabled", false);
                    $("#addressFormError").css('display','block');
                  
                  }
                }

                //contentType: 'application/x-www-form-urlencoded; charset=UTF-8',   
                
            });
        }
      });

      $('#SplInsSubmit').on("click", function () {
        var splIns = $('#spl-ins').val();
        var url = $(this).attr("action-url");
        if (splIns.length > 0) {         
          $('#SplInsSubmit').attr('disabled','disabled');
          $.ajax({
            url: url,
            type: 'POST',
            data: { 'splInstructions': splIns },
            success: function (data, textStatus, xhr) {
              $("#message").show();
              $("#SpecialInstructionsSection").hide();
              $("html, body").animate({ scrollTop: 0 }, "slow");
              $("#SplInsSubmit").attr("disabled", false);
            },
            error: function (xhr) {
              alert(xhr.statusText);
              $("#SplInsSubmit").attr("disabled", false);
            }
          });
        }

      });

      $('#addIns').on("click", function (e) {
        e.preventDefault();
        $(".MainAddressSection").hide();
        $("#AddressDetailsSection").hide();
        $("#AddressFormSection").hide();
        $("#SpecialInstructionsSection").show();
      });

      $('#SplInsCancel').on("click", function (e) {
        $(".MainAddressSection").show();
        $("#AddressDetailsSection").show();
        $("#AddressFormSection").hide();
        $("#SpecialInstructionsSection").hide();
      });

    }

    function resetForm()
    {
      $('#Addressee').val('');
      $('#PostCode').val('');
      $('#AddressLine1').val('');
      $('#AddressLine2').val('');
      $('#AddressLine3').val('');
      $('#AutoAddress_Town').val('');
    }

    function validatePostCode()
    {
      var txt = $('.chosen-single span').text();
       if(txt != null)
       {
          var $regexname = "^([A-PR-UWYZ][A-HK-Y0-9][A-Z0-9]?[ABEHMNPRVWXY0-9]? {1,2}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)$";
          if (!$('#PostCode').val().match($regexname)) 
          {
            return false;
          }
          return true;
       }
       return false;
    }

    function validateAddressee(e)
    {
      if(e.length > 0)
      {
        var address = e.text();
        if(address == '' || address.match("^C\O") || address.match("^C/O")){
          return false;
        }
        return true;
      }
      return false;
    }
   
    function bindUIActions() {
      $("#AddressDetailsSection").show();
      $("#AddressFormSection").hide();
      $("#SpecialInstructionsSection").hide();
      $('#message').hide();
      $('#FindAddress').attr('disabled','true') 
    }

    return {
      init: init,
      setFormsValidation: setFormsValidation,
     };

  }());

  if ($("#auto-address-form").length > 0) {

    PRSapp.autoaddress.init();
    PRSapp.autoaddress.setFormsValidation();
   

  }

}(jQuery));