/**
 * @namespace RaiseAQueryEdit
 * @memberof PRSapp
 */
(function($, viewport) {
  "use strict";

  PRSapp.RaiseAQueryEdit = (function() {
    var init;

    /**
     * @function init
     * @memberof! PRSapp.RaiseAQueryEdit
     */
    init = function() {
      // Some Elements as Variables
      var $editButton = $('#raiseAQueryEdit');
      var $submit = $('#rsSubmit'); 

      if($editButton.length < 1) return;
      
      var $personDetailsValues = $('.personDetails__value');
      var $formFields = $('#personDetails').find('.form-control');

      /**
       * When you click on the Edit Button Show the Input Fields
       * Hide the Values behind the the Label
       */
      $editButton.on('click', function() {
        $formFields.show();
        $personDetailsValues.hide();
        $(this).hide();
      });

      $submit.attr('disabled', false);

      $("#rAQForm").submit(function () {
        $submit.attr("disabled", true);
        return true;
    });

     


    };

    return {
      init : init
    };
  }());

  PRSapp.RaiseAQueryEdit.init();

})(jQuery, ResponsiveBootstrapToolkit);
