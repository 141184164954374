(function ($) {
  "use strict";
  PRSapp.signUpLicensees = (function () {

    function init() {
      $("div[hide-me*='true']").each(function (index) {
        $(this).toggle(false);
      });
      $("#gcaptch-error").css("display", "none");
      $("#company-type-error").css("display", "none");
    };

    function setFormsValidation() {
      var rules = {
        HasAccount: {
          required: true,
        },
        Firstname: {
          required: true,
          maxlength: 100
        },
        Secondname: {
          required: true,
          maxlength: 100
        },
        EMail: {
          required: true,
          email: true
        },
        CompanyName: {
          required: true,
          maxlength: 100
        },
        PrsAccountId: {
          required: true,
          number: true,
          minlength: 12,
          maxlength: 12
        },
        CompanyType: {
          required: true
        }
      };

      var messages = {
        HasAccount: {
          required: 'This field is required'
        },
        Firstname: {
          required: 'This field is required',
          maxlength: 'Maximum 100 characters are allowed'
        },
        Secondname: {
          required: 'This field is required',
          maxlength: 'Maximum 100 characters are allowed'
        },
        EMail: {
          required: 'This field is required',
          email: 'This field is not valid'
        },
        CompanyName: {
          required: 'This field is required',
          maxlength: 'Maximum 100 characters are allowed'
        },
        PrsAccountId: {
          required: 'This field is required',
          maxlength: 'Maximum 12 characters are allowed',
          minlength: 'Minimum 12 characters are required',
        },
        CompanyType: {
          required: "Please select an option from the list, if none are appropriate please select 'Other'",
        }

      };

      var validator =  $('#signup-licensees-form').validate({
        errorClass: 'has-error',
        validClass: 'has-success',
        ignore: ':not(:visible)',
        highlight: function (element, errorClass, validClass) {
          $(element).parents('.form-group').addClass(errorClass).removeClass(validClass);
          $(element).parents('.form-group').find('.sprite').removeClass('hidden');
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).parents('.form-group').addClass(validClass).removeClass(errorClass);
          $(element).parents('.form-group').find('.sprite').addClass('hidden');
        },
        errorPlacement: function (error, element) {
          if (element.attr("name") == "HasAccount") {
            error.insertAfter("#has-account-selection");
          }
          else {
            error.insertAfter(element);
          }
        },
        rules: rules,
        messages: messages
      });

      $('#CompanyType').on("change", function () {
        if ($('#CompanyType_chosen a.chosen-single span').text() == 'Please select') {
          $("#company-type-error").css("display", "block");
          $("#company-type-error").text("Please select an option from the list, if none are appropriate please select 'Other'");
          $("#company-type").addClass("has-error");
          $("#company-type").focus();
        }

        else {
          $("#company-type-error").css("display", "none");
          $("#company-type").removeClass("has-error");
        }
      });

      $('#signup-licensees-submit').on("click", function () {
        if ($('#signup-licensees-form').valid()) {
          if (!($('#company-type').css('display') == 'none')) {
            if ($('#CompanyType_chosen a.chosen-single span').text() == 'Please select') {
              $("#company-type-error").css("display", "block");
              $("#company-type-error").text("Please select an option from the list, if none are appropriate please select 'Other'");
              $("#company-type").addClass("has-error");
              //$('html, body').animate({
              //  scrollTop: $("#company-type").offset().top
              //}, 10);
              $('html, body').animate({
                scrollTop: ($('.has-error:visible').offset().top - 100)
              }, 500);
              return false;
            }
          }
          var captchResponse = $('#g-recaptcha-response').val();
          var isSelected = (captchResponse != undefined && captchResponse.length == 0) ? false : true;
          var isSelected = captchResponse.length == 0 ? false : true;
          if (!isSelected) {
            $("#gcaptch-error").css("display", "block");
            $("#gcaptch-error").text("This field is required");
            $("#gcaptch-section").addClass("has-error");
            $('html, body').animate({
              scrollTop: ($('.has-error:visible').offset().top - 100)
            }, 500);
            return false;
          }

          $('#signup-licensees-submit').attr('disabled', 'disabled');
          $('#signup-licensees-form').submit();
        }


      });
    }

    function onAccountTypeChange() {
      $("input[type=radio][name=HasAccount]").change(function () {
        var flag = this.value == 'Yes';
        if (flag) {
          $('#prs-account-id').toggle(true);
          $('#company-name').toggle(false);
          $('#company-type').toggle(false);
        }
        else {
          $('#company-name').toggle(true);
          $('#company-type').toggle(true);
          $('#prs-account-id').toggle(false);
        }
      });
    }
    return {
      init: init,
      setFormsValidation: setFormsValidation,
      onAccountTypeChange: onAccountTypeChange
    };
  }());

  if ($("#signup-licensees-form") != 'undefined') {

    PRSapp.signUpLicensees.init();
    PRSapp.signUpLicensees.setFormsValidation();
    PRSapp.signUpLicensees.onAccountTypeChange();

  }
}(jQuery));