/**
 * @namespace Navigation
 * @memberof PRSapp
 */
(function ($, viewport) {
  PRSapp.Navigation = (function () {
    let init;
    let toggleNavAutohide = true;

    /**
         * @function init
         * @memberof! PRSapp.Navigation
         */
    init = function () {
      if ($('#getting-started-page').length > 0 || $('#product-page').length > 0) {
        toggleNavAutohide = false;
      }

      setUpNavbarSticky();

      if ($('.system-status-message__container').length > 0) {
        setUpOffsetNavbarSticky();
        onSystemStatusMessageClose();
        const offsetTop = $('.system-status-message__container').outerHeight(true);
        $('#nav').autoHidingNavbar('setHideOffset', offsetTop);
      } else if (toggleNavAutohide) {
        $('#nav').addClass('navbar-fixed-top');
      }

      setUpNavbarOffCanvas();

      setUpNavbarSearch();

      setUpLoggedInDropdown();

      setNavigationBreakpoints();
    };

    function setUpOffsetNavbarSticky() {
      if (!toggleNavAutohide) return;

      $(document).on('scroll', () => {
        const distanceScrolled = $(document).scrollTop();
        const offsetTop = $('.system-status-message__container').outerHeight(true);

        if (distanceScrolled >= offsetTop) {
          if (!$('#nav').hasClass('navbar-fixed-top')) {
            $('#nav').addClass('navbar-fixed-top');
            $('#nav').autoHidingNavbar('setDisableAutohide', false);
          }
        } else if (distanceScrolled < offsetTop) {
          if ($('#nav').hasClass('navbar-fixed-top')) {
            $('#nav').removeClass('navbar-fixed-top');
            $('#nav').autoHidingNavbar('setDisableAutohide', true);
          }
        }
      });
    }

    function setUpNavbarSticky() {
      if (!toggleNavAutohide) return;

      $('#nav').autoHidingNavbar({
        showOnUpscroll: true,
        showOnBottom: false,
        animationDuration: 0,
      });

      $('#nav').on('focusin', function () {
        $(this).autoHidingNavbar('show');
      });
    }

    function setUpNavbarOffCanvas() {
      $('[data-toggle=offcanvas]').on('click', (e) => {
        e.preventDefault();

        toggleOffCanvas();
      });

      $('.offcanvas__close').click((e) => {
        e.preventDefault();

        toggleOffCanvas();
      });
    }

    function toggleOffCanvas() {
      const $trigger = $('.offcanvas--trigger');
      let delay = 0;

      $trigger.toggleClass('active');

      if (!$trigger.hasClass('active')) {
        delay = 300;
      }

      setTimeout(() => {
        $('.offcanvas--showhide').toggle();
      }, delay);
    }

    function setUpNavbarSearch() {
      $('.navbar__search-icon-container').on('click', function () {
        const $parent = $(this).parents('.navbar__search');
        const $navbar = $(this).parents('.navbar-fixed-top');

        $parent.toggleClass('active');
        $navbar.toggleClass('search-active');

        if (!$parent.hasClass('active')) {
          clearSearch();
          makeNotTabbable();
        } else {
          makeTabbable();
        }
      });

      $('.navbar__search-icon-button').on('click', function (e) {
        e.preventDefault();

        if ($('.navbar__search-bar').val().length > 0) {
          $(this).parents('form').submit();
        }
      });
    }

    function clearSearch() {
      $('.navbar__search-bar').val('');
    }

    function makeTabbable() {
      $('.navbar__search-icon-button').removeAttr('tabindex');
      $('.navbar__search-bar').removeAttr('tabindex');
      $('.navbar__search-button').removeAttr('tabindex');
    }

    function makeNotTabbable() {
      $('.navbar__search-icon-button').attr('tabindex', '-1');
      $('.navbar__search-bar').attr('tabindex', '-1');
      $('.navbar__search-button').attr('tabindex', '-1');
    }

    function setUpLoggedInDropdown() {
      $(document).on('click', '.navbar .dropdown-menu', (e) => {
        e.stopPropagation();
      });
    }

    function setNavigationBreakpoints() {
      enquire.register('screen and (max-width: 992px)', {

        match() {
          $('.visible-md .navbar__search').removeClass('active');

          if ($('.offcanvas__container').hasClass('active')) {
            $('.navbar-toggle').click();
          }

          if ($('#content_page.search-results').length > 0) {
            $('.visible-xxs .navbar__search').addClass('active');
          } else {
            $('#nav').removeClass('search-active');
          }
        },

      });

      enquire.register('screen and (min-width: 993px)', {

        match() {
          $('.visible-xxs .navbar__search').removeClass('active');

          if ($('.offcanvas__container').hasClass('active')) {
            $('.navbar-toggle').click();
          }

          if ($('#content_page.search-results').length > 0) {
            $('.visible-md .navbar__search').addClass('active');
          } else {
            $('#nav').removeClass('search-active');
          }
        },

      });
    }

    function onSystemStatusMessageClose() {
      $('.system-status-message__container').on('close.bs.alert', () => {
        $('#nav').addClass('navbar-fixed-top');
        if (!toggleNavAutohide) {
          $('#nav').autoHidingNavbar('setDisableAutohide', false);
        }
      });
    }

    /* App switcher */
    $('.seemoremenu').click((e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      $('.seemoremenu').hide();
      $('.seelessmenu').show();
      $('.more-link').addClass('active');
    });

    $('.seelessmenu').click(function (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      $(this).hide();
      $('.more-link').removeClass('active');
      $('.seemoremenu').show();
    });

    return {
      init,
    };
  }());

  PRSapp.Navigation.init();
}(jQuery, ResponsiveBootstrapToolkit));
