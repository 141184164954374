(function ($) {
  "use strict";
  PRSapp.produbsearch = (function () {
    var config = {};

    function init() {
      config = {
        button: "#produbsearch-submit",
        form: "#produbsearch-form",
      };
      bindUIActions();
    };


    var bindUIActions = function () {
      if ($("#produbsearch-data-table").length > 0)
      {
      $('#produbsearch-data-table').dataTable({  
        "bJQueryUI":true,
        "bSort":true,
        "bPaginate":true,
        "sPaginationType":"full_numbers",
         "iDisplayLength": 10
      }); 
    }
      onConfirmChanges();
    }

    var onConfirmChanges = function () {

      var rules = {
        SurnameSearch: {
          required: true,
          maxlength: 25,
          minlength: 3,
          regex: "^([a-zA-Z0-9 .&'-*]*)$"
         }
      };

      var messages = {       
        SurnameSearch: {
          required: 'This field is required',
          maxlength: 'Maximum 25 characters are allowed',
          minlength: 'Search criteria must contain at least 3 characters (excl. wildcard)' ,
          regex: 'Please enter standard characters'
         }
      };

      var isValid = $(config.form).validate({
        errorClass: 'has-error',
        validClass: 'has-success',
        ignore: ':not(:visible)',
        highlight: function (element, errorClass, validClass) {
          $(element).parents('.form-group').addClass(errorClass).removeClass(validClass);
          $(element).parents('.form-group').find('.sprite').removeClass('hidden');
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).parents('.form-group').addClass(validClass).removeClass(errorClass);
          $(element).parents('.form-group').find('.sprite').addClass('hidden');
        },
        errorPlacement: function (error, element) {
          error.insertAfter(element);
          
        },
        rules: rules,
        messages: messages
      });

      
      $(config.button).click(function (e) {
        e.preventDefault();
        if ($(config.form).valid()) {
          var captchResponse = $('#g-recaptcha-response').val();
          var isSelected = (captchResponse != undefined && captchResponse.length == 0) ? false : true;
          if (!isSelected) {
            $("#gcaptch-error").css("display", "block");
            $("#gcaptch-error").text("This field is required");
            $("#gcaptch-section").addClass("has-error");
            $('html, body').animate({
              scrollTop: ($('.has-error:visible').offset().top - 100)
            }, 500);
            return false;
          }
        var button = $(this);
        var url = $(this).attr("href");
        var surname =  $("#surname").val();
        if (surname.length <= 0) {
          return;
        }
        

        $(config.button).attr("disabled", true);       
        $('#Submit').attr('disabled','disabled');
        $(config.form).submit();
      }

      });
    
    };

    return {
      init: init,
      
    };

  }());

  if ($("#produbsearch-form").length > 0) {
    PRSapp.produbsearch.init();
   
  }
}(jQuery));