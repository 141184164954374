(function ($) {
    "use strict";
    PRSapp.manageUserProfiles = (function () {

        var config = {};

        function init() {
            config = {
                removeProfileLinkId: ".remove-prfile",
                confirmMessage: manageUserProfileConfirmMessage,
                profileCount: profileCount,
                pageHeader: pageHeader,
                pageHeaderId: "#page-header",
                overlayLoadingTextId: "#overlay-text",
                removeProfileLoadingText: "Removing Access"
            };
            bindUIActions();
        };

        var bindUIActions = function () {

            $("h1:first").remove();
            $(config.removeProfileLinkId).click(function (e) {
                e.preventDefault();
                var url = $(this).attr("href");
              var profileId = $(this).attr("profileId");
              var isMember = $(this).find("#mType").val();
              var button = $(this);
              var name = $(this).attr("profile-name");
              if (isMember == "True")
                config.confirmMessage = manageUserProfileMemberConfirmMessage;
              else
                config.confirmMessage = manageUserProfileConfirmMessage;
                $(button).attr("disabled", true);

                return iamConfirmSwitchProfile(config.confirmMessage.replace('||', name), config.confirmSubMessage || null, function (result) {
                  if (result) {
                    $(config.overlayLoadingTextId).text(config.removeProfileLoadingText);
                    $('body').addClass("switching-profile");
                        $.ajax({
                            type: "post",
                            url: url,
                            data: { "profileId": profileId },
                            success: function (data, textStatus, xhr) {
                                if (config.profileCount == 1) {
                                  // redirect 
                                  window.location.href = '/account/manage-users';
                                } else {
                                  // do not redirect
                                  var column = $(button).parents('div.remove-prfile-me');
                                  config.profileCount = $(config.pageHeaderId).children("span").text();
                                  $(config.pageHeaderId).children("span").text((config.profileCount - 1));
                                  $(column).hide('slow', function () { $(column).remove(); });
                                  $('body').removeClass("switching-profile");
                                }

                            },
                            error: function (xhr) {
                                $(button).removeAttr("disabled");
                                alert(xhr.statusText);
                                $('body').removeClass("switching-profile");
                            }
                        });
                    }
                });                
            });
        }

        return {
            init: init
        };

    }());

    if ($("#manage-user-profile-form").length > 0) {
        PRSapp.manageUserProfiles.init();

    }

}(jQuery));


