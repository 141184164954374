(function ($) {
  "use strict";
  PRSapp.bankDetails = (function () {

    var config = {
      container: $("#divWizard"),
      buttonText: ""
    }
    var init = function () {
      bindUIActions();
    }

    function loading(me, action) {
      if (action) {
        config.buttonText = $(me).attr("value");
        $(me).attr("value", $(me).attr("data-loading-text"));
        $(me).attr("disabled", true);
      }
      else {
        $(me).attr("value", config.buttonText);
        $(me).removeAttr("disabled");
      }
    }
    function validAlphaNum(strCharacters, strValue) {
      var re = new RegExp("^([a-zA-Z0-9\-\_\,]{" + strCharacters + "})$");
      return re.test(strValue);
    }    
    function validNumeric1(strValue) {
      if (isNaN(strValue)) {
        return false;
      }      
      return true;
    }   
    function validNumeric2(strCharacters, strValue) {
      var re = new RegExp("^([0-9]{" + strCharacters + "})$");
      return re.test(strValue);
    }   
    function validNumeric3(strMinChar, strMaxChar, strValue) {
      var re = new RegExp("^([0-9]{" + strMinChar + "," + strMaxChar + "})$");
      return re.test(strValue);
    }  
    function displayErrorMessages(errorMessages) {
      $("#divError").html('Please correct the following and try again:')
      var errorList = '<ul id="errorList">';
      $.each(errorMessages, function(index, value){
        errorList += '<li class="fa-close-small-before">' + value + '</li>';
      });
      errorList += '</ul>';
      $("#divError").append(errorList);
    }                 
    function validate_41(me, action) {
      $("#divError").html('');
      $("#e_o_41_bank ,#e_o_41_branch, #e_o_41_sc1 ,#e_o_41_sc2, #e_o_41_accno, #e_o_41_accname").addClass('hidden');      
      var isValid = true;
      var errorMessages = [];
      if (($("#BankName").val() == null) || ($("#BankName").val().trim() === '')) { isValid = false; $("#e_o_41_bank").removeClass('hidden');errorMessages.push('Name of Bank or Financial Institution')}
      if (($("#BankAddress").val() == null) || ($("#BankAddress").val().trim() === '')) { isValid = false; $("#e_o_41_branch").removeClass('hidden');errorMessages.push('Address of Bank or Financial Institution')}
      if (($("#BankCode").val() == null) || ($("#BankCode").val().trim() === '')) { 
        isValid = false; $("#e_o_41_sc1").removeClass('hidden');errorMessages.push('Bank Code must be populated')
      }
      else if (!validAlphaNum("3", $("#BankCode").val().trim())) {
        isValid = false; $("#e_o_41_sc1").removeClass('hidden');errorMessages.push('Bank code must have 3 digits')
      }
      if (($("#BranchCode").val() == null) || ($("#BranchCode").val() === '')) { 
        isValid = false; $("#e_o_41_sc2").removeClass('hidden');errorMessages.push('Branch Code must be populated')
      }
      else if (!validAlphaNum("5", $("#BranchCode").val().trim())) {
        isValid = false; $("#e_o_41_sc2").removeClass('hidden');errorMessages.push('Branch code must have 5 digits')
      }      
      if (($("#AccountNumber").val() == null) || ($("#AccountNumber").val().trim() === '')) { isValid = false; $("#e_o_41_accno").removeClass('hidden');errorMessages.push('Account number must be populated')}
      if (($("#AccountName").val() == null) || ($("#AccountName").val().trim() === '')) { isValid = false; $("#e_o_41_accname").removeClass('hidden');errorMessages.push('Name in which account is held must be populated')}
      if (isValid == false) {
        displayErrorMessages(errorMessages);
      } 
      else {
        $("#divError").html('');
      }
      return isValid;
    }
    function validate_42(me, action) {
      $("#divError").html('');
      $("#e_o_42_bank ,#e_o_42_branch, #e_o_42_sc1 ,#e_o_42_sc2, #e_o_42_accno, #e_o_42_accname").addClass('hidden');      
      var isValid = true;
      var errorMessages = [];
      if (($("#BankName").val() == null) || ($("#BankName").val().trim() === '')) { isValid = false; $("#e_o_42_bank").removeClass('hidden');errorMessages.push('Name of Bank or Financial Institution')}
      if (($("#BankAddress").val() == null) || ($("#BankAddress").val().trim() === '')) { isValid = false; $("#e_o_42_branch").removeClass('hidden');errorMessages.push('Address of Bank or Financial Institution')}
      if (($("#BankCode").val() == null) || ($("#BankCode").val().trim() === '')) { 
        isValid = false; $("#e_o_42_sc1").removeClass('hidden');errorMessages.push('Bank Code (part 1) must be populated')
      }
      else if (!validAlphaNum("3", $("#BankCode").val().trim())) {
        isValid = false; $("#e_o_42_sc1").removeClass('hidden');errorMessages.push('Bank code (part 1) must have 3 digits')
      }
      if (($("#BankCodePart2").val() == null) || ($("#BankCodePart2").val().trim() === '')) { 
        isValid = false; $("#e_o_42_sc2").removeClass('hidden');errorMessages.push('Bank Code (part 2) must be populated')
      }
      else if (!validAlphaNum("3", $("#BankCodePart2").val().trim())) {
        isValid = false; $("#e_o_42_sc2").removeClass('hidden');errorMessages.push('Bank code (part 2) must have 3 digits')
      }     
      if (($("#AccountNumber").val() == null) || ($("#AccountNumber").val().trim() === '')) { isValid = false; $("#e_o_42_accno").removeClass('hidden');errorMessages.push('Account number must be populated')}
      if (($("#AccountName").val() == null) || ($("#AccountName").val().trim() === '')) { isValid = false; $("#e_o_42_accname").removeClass('hidden');errorMessages.push('Name in which account is held must be populated')}
      if (isValid == false) {
        displayErrorMessages(errorMessages);
      } 
      else {
        $("#divError").html('');
      }
      return isValid;
    }    
    function validate_43(me, action) {
      $("#divError").html('');
      $("#e_o_43_bank ,#e_o_43_branch, #e_o_43_bic ,#e_o_43_iban, #e_o_43_accname").addClass('hidden');      
      var isValid = true;
      var errorMessages = [];
      if (($("#BankName").val() == null) || ($("#BankName").val().trim() === '')) { isValid = false; $("#e_o_43_bank").removeClass('hidden');errorMessages.push('Name of Bank or Financial Institution')}
      if (($("#BankAddress").val() == null) || ($("#BankAddress").val().trim() === '')) { isValid = false; $("#e_o_43_branch").removeClass('hidden');errorMessages.push('Address of Bank or Financial Institution')}
      if (($("#BIC").val() == null) || ($("#BIC").val().trim() === '')) { 
        isValid = false; $("#e_o_43_bic").removeClass('hidden');errorMessages.push('BIC (Bank Identifier Code) must be populated')
      }
      else if (!validAlphaNum("11", $("#BIC").val().trim())) {
        isValid = false; $("#e_o_43_bic").removeClass('hidden');errorMessages.push('BIC (Bank Identifier Code) must have 11 characters')
      }
      if (($("#IBAN").val() == null) || ($("#IBAN").val().trim() === '')) { 
        isValid = false; $("#e_o_43_iban").removeClass('hidden');errorMessages.push('IBAN (International Bank Account Number) must be populated')
      }
      else if (!validAlphaNum($("#MaxLength1").val(), $("#IBAN").val().trim())) {
        isValid = false; $("#e_o_43_iban").removeClass('hidden');errorMessages.push('IBAN (International Bank Account Number) must have ' + $("#MaxLength1").val() + ' characters')
      }     
      if (($("#AccountName").val() == null) || ($("#AccountName").val().trim() === '')) { isValid = false; $("#e_o_43_accname").removeClass('hidden');errorMessages.push('Name in which account is held must be populated')}
      if (isValid == false) {
        displayErrorMessages(errorMessages);
      } 
      else {
        $("#divError").html('');
      }
      return isValid;
    }  
    function validate_44(me, action) {
      $("#divError").html('');
      $("#e_o_44_bank ,#e_o_44_branch, #e_o_44_sc1 ,#e_o_44_sc2, #e_o_44_accno, #e_o_44_accname").addClass('hidden');      
      var isValid = true;
      var errorMessages = [];
      if (($("#BankName").val() == null) || ($("#BankName").val().trim() === '')) { isValid = false; $("#e_o_44_bank").removeClass('hidden');errorMessages.push('Name of Bank or Financial Institution')}
      if (($("#BankAddress").val() == null) || ($("#BankAddress").val().trim() === '')) { isValid = false; $("#e_o_44_branch").removeClass('hidden');errorMessages.push('Address of Bank or Financial Institution')}
      if (($("#BankCode").val() == null) || ($("#BankCode").val().trim() === '')) { 
        isValid = false; $("#e_o_44_sc1").removeClass('hidden');errorMessages.push('Bank Code must be populated')
      }
      else if (!validAlphaNum("3", $("#BankCode").val().trim())) {
        isValid = false; $("#e_o_44_sc1").removeClass('hidden');errorMessages.push('Bank code must have 3 digits')
      }
      if (($("#BranchCode").val() == null) || ($("#BranchCode").val() === '')) { 
        isValid = false; $("#e_o_44_sc2").removeClass('hidden');errorMessages.push('Branch Code must be populated')
      }
      else if (!validAlphaNum("5", $("#BranchCode").val().trim())) {
        isValid = false; $("#e_o_44_sc2").removeClass('hidden');errorMessages.push('Branch code must have 5 digits')
      }      
      if (($("#AccountNumber").val() == null) || ($("#AccountNumber").val().trim() === '')) { isValid = false; $("#e_o_44_accno").removeClass('hidden');errorMessages.push('Account number must be populated')}
      if (($("#AccountName").val() == null) || ($("#AccountName").val().trim() === '')) { isValid = false; $("#e_o_44_accname").removeClass('hidden');errorMessages.push('Name in which account is held must be populated')}
      if (isValid == false) {
        displayErrorMessages(errorMessages);
      } 
      else {
        $("#divError").html('');
      }
      return isValid;
    }    
    function validate_45(me, action) {
      $("#divError").html('');
      $("#e_o_45_bank ,#e_o_45_branch, #e_o_45_sc1 ,#e_o_45_sc2, #e_o_45_accno, #e_o_45_accname").addClass('hidden');      
      var isValid = true;
      var errorMessages = [];
      if (($("#BankName").val() == null) || ($("#BankName").val().trim() === '')) { isValid = false; $("#e_o_45_bank").removeClass('hidden');errorMessages.push('Name of Bank or Financial Institution')}
      if (($("#BankAddress").val() == null) || ($("#BankAddress").val().trim() === '')) { isValid = false; $("#e_o_45_branch").removeClass('hidden');errorMessages.push('Address of Bank or Financial Institution')}
      if (($("#BankCode").val() == null) || ($("#BankCode").val().trim() === '')) { 
        isValid = false; $("#e_o_45_sc1").removeClass('hidden');errorMessages.push('Bank Code must be populated')
      }
      else if (!validAlphaNum("3", $("#BankCode").val().trim())) {
        isValid = false; $("#e_o_45_sc1").removeClass('hidden');errorMessages.push('Bank code must have 3 digits')
      }
      if (($("#BranchCode").val() == null) || ($("#BranchCode").val() === '')) { 
        isValid = false; $("#e_o_45_sc2").removeClass('hidden');errorMessages.push('Branch Code must be populated')
      }
      else if (!validAlphaNum("5", $("#BranchCode").val().trim())) {
        isValid = false; $("#e_o_45_sc2").removeClass('hidden');errorMessages.push('Branch code must have 5 digits')
      }      
      if (($("#AccountNumber").val() == null) || ($("#AccountNumber").val().trim() === '')) { 
        isValid = false; $("#e_o_45_accno").removeClass('hidden');errorMessages.push('Account number must be populated')
      }
      else if (!validNumeric3("1", $("#MaxLength1").val(), $("#AccountNumber").val().trim())) {
        isValid = false; $("#e_o_45_accno").removeClass('hidden');errorMessages.push('Account number must have maximum ' + $("#MaxLength1").val() + ' numeric digits')
      }        
      if (($("#AccountName").val() == null) || ($("#AccountName").val().trim() === '')) { isValid = false; $("#e_o_45_accname").removeClass('hidden');errorMessages.push('Name in which account is held must be populated')}
      if (isValid == false) {
        displayErrorMessages(errorMessages);
      } 
      else {
        $("#divError").html('');
      }
      return isValid;
    }         
    function validate_47(me, action) {
      $("#divError").html('');
      $("#e_o_47_bank ,#e_o_47_branch, #e_o_47_sc1 ,#e_o_47_accno, #e_o_47_accname").addClass('hidden');      
      var isValid = true;
      var errorMessages = [];
      if (($("#BankName").val() == null) || ($("#BankName").val().trim() === '')) { isValid = false; $("#e_o_47_bank").removeClass('hidden');errorMessages.push('Name of Bank or Financial Institution')}
      if (($("#BankAddress").val() == null) || ($("#BankAddress").val().trim() === '')) { isValid = false; $("#e_o_47_branch").removeClass('hidden');errorMessages.push('Address of Bank or Financial Institution')}
      if (($("#BankCode").val() == null) || ($("#BankCode").val().trim() === '')) { 
        isValid = false; $("#e_o_47_sc1").removeClass('hidden');errorMessages.push('Bank Code/Routing Number must be populated')
      }
      else if (!validNumeric2("9", $("#BankCode").val().trim())) {
        isValid = false; $("#e_o_47_sc1").removeClass('hidden');errorMessages.push('Bank Code/Routing Number must have 9 numeric characters')
      }     
      if (($("#AccountNumber").val() == null) || ($("#AccountNumber").val().trim() === '')) { 
        isValid = false; $("#e_o_47_accno").removeClass('hidden');errorMessages.push('Account number must be populated')
      }
      else if (!validNumeric1($("#AccountNumber").val().trim())) {
        isValid = false; $("#e_o_47_accno").removeClass('hidden');errorMessages.push('Account number must have maximum 12 numeric digits')
      }        
      if (($("#AccountName").val() == null) || ($("#AccountName").val().trim() === '')) { isValid = false; $("#e_o_47_accname").removeClass('hidden');errorMessages.push('Name in which account is held must be populated')}
      if (isValid == false) {
        displayErrorMessages(errorMessages);
      } 
      else {
        $("#divError").html('');
      }
      return isValid;
    }    
    function validate_48(me, action) {
      $("#divError").html('');
      $("#e_o_48_bank ,#e_o_48_branch, #e_o_48_sc1 ,#e_o_48_accno, #e_o_48_accname").addClass('hidden');      
      var isValid = true;
      var errorMessages = [];
      if (($("#BankName").val() == null) || ($("#BankName").val().trim() === '')) { isValid = false; $("#e_o_48_bank").removeClass('hidden');errorMessages.push('Name of Bank or Financial Institution')}
      if (($("#BankAddress").val() == null) || ($("#BankAddress").val().trim() === '')) { isValid = false; $("#e_o_48_branch").removeClass('hidden');errorMessages.push('Address of Bank or Financial Institution')}
      if (($("#BankCode").val() == null) || ($("#BankCode").val().trim() === '')) { 
        isValid = false; $("#e_o_48_sc1").removeClass('hidden');errorMessages.push('Bank Code must be populated')
      }
      else if (!validNumeric2($("#MaxLength2").val(), $("#BankCode").val().trim())) {
        isValid = false; $("#e_o_48_sc1").removeClass('hidden');errorMessages.push('Bank Code must have ' + $("#MaxLength2").val() + ' numeric characters')
      }     
      if (($("#AccountNumber").val() == null) || ($("#AccountNumber").val().trim() === '')) { 
        isValid = false; $("#e_o_48_accno").removeClass('hidden');errorMessages.push('Account number must be populated')
      }
      else if (!validNumeric3("1", $("#MaxLength1").val(), $("#AccountNumber").val().trim())) {
        isValid = false; $("#e_o_48_accno").removeClass('hidden');errorMessages.push('Account number must have maximum ' + $("#MaxLength1").val() + ' numeric digits')
      }        
      if (($("#AccountName").val() == null) || ($("#AccountName").val().trim() === '')) { isValid = false; $("#e_o_48_accname").removeClass('hidden');errorMessages.push('Name in which account is held must be populated')}
      if (isValid == false) {
        displayErrorMessages(errorMessages);
      } 
      else {
        $("#divError").html('');
      }
      return isValid;
    }    
    function validate_413(me, action) {
      $("#divError").html('');
      $("#e_o_413_bank ,#e_o_413_branch, #e_o_413_sc1 ,#e_o_413_accno, #e_o_413_accname").addClass('hidden');      
      var isValid = true;
      var errorMessages = [];
      if (($("#BankName").val() == null) || ($("#BankName").val().trim() === '')) { isValid = false; $("#e_o_413_bank").removeClass('hidden');errorMessages.push('Name of Bank or Financial Institution')}
      if (($("#BankAddress").val() == null) || ($("#BankAddress").val().trim() === '')) { isValid = false; $("#e_o_413_branch").removeClass('hidden');errorMessages.push('Address of Bank or Financial Institution')}
      if (($("#BankCode").val() == null) || ($("#BankCode").val().trim() === '')) { 
        isValid = false; $("#e_o_413_sc1").removeClass('hidden');errorMessages.push('Bank Code must be populated')
      }
      else if (!validNumeric2("6", $("#BankCode").val().trim())) {
        isValid = false; $("#e_o_413_sc1").removeClass('hidden');errorMessages.push('Bank Code must have 6 numeric characters')
      }     
      if (($("#AccountNumber").val() == null) || ($("#AccountNumber").val().trim() === '')) { 
        isValid = false; $("#e_o_413_accno").removeClass('hidden');errorMessages.push('Account number must be populated')
      }
      else if (!validNumeric3("6", "9", $("#AccountNumber").val().trim())) {
        isValid = false; $("#e_o_413_accno").removeClass('hidden');errorMessages.push('Account number must have minimum 6 and maximum 9 numeric digits')
      }        
      if (($("#AccountName").val() == null) || ($("#AccountName").val().trim() === '')) { isValid = false; $("#e_o_413_accname").removeClass('hidden');errorMessages.push('Name in which account is held must be populated')}
      if (isValid == false) {
        displayErrorMessages(errorMessages);
      } 
      else {
        $("#divError").html('');
      }
      return isValid;
    }       
    function validate_414(me, action) {
      $("#divError").html('');
      $("#e_o_414_bank ,#e_o_414_branch, #e_o_414_sc1 ,#e_o_414_accno, #e_o_414_accname").addClass('hidden');      
      var isValid = true;
      var errorMessages = [];
      if (($("#BankName").val() == null) || ($("#BankName").val().trim() === '')) { isValid = false; $("#e_o_414_bank").removeClass('hidden');errorMessages.push('Name of Bank or Financial Institution')}
      if (($("#BankAddress").val() == null) || ($("#BankAddress").val().trim() === '')) { isValid = false; $("#e_o_414_branch").removeClass('hidden');errorMessages.push('Address of Bank or Financial Institution')}
      if (($("#BankCode").val() == null) || ($("#BankCode").val().trim() === '')) { 
        isValid = false; $("#e_o_414_sc1").removeClass('hidden');errorMessages.push('Bank Code must be populated')
      }
      else if (!validNumeric2("6", $("#BankCode").val().trim())) {
        isValid = false; $("#e_o_414_sc1").removeClass('hidden');errorMessages.push('Bank Code must have 6 numeric characters')
      }     
      if (($("#AccountNumber").val() == null) || ($("#AccountNumber").val().trim() === '')) { 
        isValid = false; $("#e_o_414_accno").removeClass('hidden');errorMessages.push('Account number must be populated')
      }
      else if (!validNumeric1($("#AccountNumber").val().trim())) {
        isValid = false; $("#e_o_414_accno").removeClass('hidden');errorMessages.push('Account number must have maximum 8 numeric digits')
      }        
      if (($("#AccountName").val() == null) || ($("#AccountName").val().trim() === '')) { isValid = false; $("#e_o_414_accname").removeClass('hidden');errorMessages.push('Name in which account is held must be populated')}
      if (isValid == false) {
        displayErrorMessages(errorMessages);
      } 
      else {
        $("#divError").html('');
      }
      return isValid;
    }          
    function validate_415(me, action) {
      $("#divError").html('');
      $("#e_o_415_bank ,#e_o_415_branch, #e_o_415_sc1 ,#e_o_415_accno, #e_o_415_accname, #e_o_415_identity").addClass('hidden');      
      var isValid = true;
      var errorMessages = [];
      if (($("#BankName").val() == null) || ($("#BankName").val().trim() === '')) { isValid = false; $("#e_o_415_bank").removeClass('hidden');errorMessages.push('Name of Bank or Financial Institution')}
      if (($("#BankAddress").val() == null) || ($("#BankAddress").val().trim() === '')) { isValid = false; $("#e_o_415_branch").removeClass('hidden');errorMessages.push('Address of Bank or Financial Institution')}
      if (($("#BankCode").val() == null) || ($("#BankCode").val().trim() === '')) { 
        isValid = false; $("#e_o_415_sc1").removeClass('hidden');errorMessages.push('Bank Code must be populated')
      }
      else if (!validNumeric2("6", $("#BankCode").val().trim())) {
        isValid = false; $("#e_o_415_sc1").removeClass('hidden');errorMessages.push('Bank Code must have 6 numeric characters')
      }     
      if (($("#AccountNumber").val() == null) || ($("#AccountNumber").val().trim() === '')) { 
        isValid = false; $("#e_o_415_accno").removeClass('hidden');errorMessages.push('Account number must be populated')
      }
      else if (!validNumeric1($("#AccountNumber").val().trim())) {
        isValid = false; $("#e_o_415_accno").removeClass('hidden');errorMessages.push('Account number must have maximum 12 numeric digits')
      }        
      if (($("#AccountName").val() == null) || ($("#AccountName").val().trim() === '')) { isValid = false; $("#e_o_415_accname").removeClass('hidden');errorMessages.push('Name in which account is held must be populated')}
      if ((($("#IdentityNumber").val() == null) || ($("#IdentityNumber").val().trim() === '')) && (($("#PassportNumber").val() == null) || ($("#PassportNumber").val().trim() === ''))) { 
        isValid = false; $("#e_o_415_identity").removeClass('hidden');errorMessages.push('Identity Number or Passport Number must be populated')
      }      
      if (isValid == false) {
        displayErrorMessages(errorMessages);
      } 
      else {
        $("#divError").html('');
      }
      return isValid;
    }  
    function validate_416(me, action) {
      $("#divError").html('');
      $("#e_o_416_bank ,#e_o_416_branch, #e_o_416_iban ,#e_o_416_accname").addClass('hidden');      
      var isValid = true;
      var errorMessages = [];
      if (($("#BankName").val() == null) || ($("#BankName").val().trim() === '')) { isValid = false; $("#e_o_416_bank").removeClass('hidden');errorMessages.push('Name of Bank or Financial Institution')}
      if (($("#BankAddress").val() == null) || ($("#BankAddress").val().trim() === '')) { isValid = false; $("#e_o_416_branch").removeClass('hidden');errorMessages.push('Address of Bank or Financial Institution')}
      if (($("#IBAN").val() == null) || ($("#IBAN").val().trim() === '')) { 
        isValid = false; $("#e_o_416_iban").removeClass('hidden');errorMessages.push('IBAN (International Bank Account Number) must be populated')
      }
      else if (!validAlphaNum("27", $("#IBAN").val().trim())) {
        isValid = false; $("#e_o_416_iban").removeClass('hidden');errorMessages.push('IBAN (International Bank Account Number) must have 27 characters')
      }     
      else if (!$("#IBAN").val().toUpperCase().startsWith("IT")) {
        isValid = false; $("#e_o_416_iban").removeClass('hidden');errorMessages.push('IBAN\'s first 2 characters must be IT')
      }         
      if (($("#AccountName").val() == null) || ($("#AccountName").val().trim() === '')) { isValid = false; $("#e_o_416_accname").removeClass('hidden');errorMessages.push('Name in which account is held must be populated')}     
      if (isValid == false) {
        displayErrorMessages(errorMessages);
      } 
      else {
        $("#divError").html('');
      }
      return isValid;
    }   
    function validate_421(me, action) {
      $("#divError").html('');
      $("#e_o_421_bank ,#e_o_421_branch, #e_o_421_sc1 ,#e_o_421_accno1, #e_o_421_accno2, #e_o_421_accname").addClass('hidden');      
      var isValid = true;
      var errorMessages = [];
      if (($("#BankName").val() == null) || ($("#BankName").val().trim() === '')) { isValid = false; $("#e_o_421_bank").removeClass('hidden');errorMessages.push('Name of Bank or Financial Institution')}
      if (($("#BankAddress").val() == null) || ($("#BankAddress").val().trim() === '')) { isValid = false; $("#e_o_421_branch").removeClass('hidden');errorMessages.push('Address of Bank or Financial Institution')}
      if (($("#BankCode").val() == null) || ($("#BankCode").val().trim() === '')) { isValid = false; $("#e_o_421_sc1").removeClass('hidden');errorMessages.push('Bank Code must be populated')}         
      if (($("#AccountNumber").val() == null) || ($("#AccountNumber").val().trim() === '')) { 
        isValid = false; $("#e_o_421_accno1").removeClass('hidden');errorMessages.push('Account number must be populated')
      }
      else if (!validAlphaNum("8", $("#AccountNumber").val().trim())) {   
        isValid = false; $("#e_o_421_accno1").removeClass('hidden');errorMessages.push('Account number must have 8 characters')
      }    
      if (($("#AccountSuffix").val() == null) || ($("#AccountSuffix").val().trim() === '')) { 
        isValid = false; $("#e_o_421_accno2").removeClass('hidden');errorMessages.push('Account suffix must be populated')
      }
      else if (!validAlphaNum("4", $("#AccountSuffix").val().trim())) {   
        isValid = false; $("#e_o_421_accno2").removeClass('hidden');errorMessages.push('Account suffix must have 4 characters')
      }           
      if (($("#AccountName").val() == null) || ($("#AccountName").val().trim() === '')) { isValid = false; $("#e_o_421_accname").removeClass('hidden');errorMessages.push('Name in which account is held must be populated')}     
      if (isValid == false) {
        displayErrorMessages(errorMessages);
      } 
      else {
        $("#divError").html('');
      }
      return isValid;
    }             
    function validate_424(me, action) {
      $("#divError").html('');
      $("#e_o_424_bank ,#e_o_424_branch, #e_o_424_sc1 ,#e_o_424_accno, #e_o_424_accname").addClass('hidden');      
      var isValid = true;
      var errorMessages = [];
      if (($("#BankName").val() == null) || ($("#BankName").val().trim() === '')) { isValid = false; $("#e_o_424_bank").removeClass('hidden');errorMessages.push('Name of Bank or Financial Institution')}
      if (($("#BankAddress").val() == null) || ($("#BankAddress").val().trim() === '')) { isValid = false; $("#e_o_424_branch").removeClass('hidden');errorMessages.push('Address of Bank or Financial Institution')}
      if (($("#BankCode").val() == null) || ($("#BankCode").val().trim() === '')) { isValid = false; $("#e_o_424_sc1").removeClass('hidden');errorMessages.push('Bank Code must be populated')}         
      if (($("#AccountNumber").val() == null) || ($("#AccountNumber").val().trim() === '')) { isValid = false; $("#e_o_424_accno").removeClass('hidden');errorMessages.push('Account number must be populated')}            
      if (($("#AccountName").val() == null) || ($("#AccountName").val().trim() === '')) { isValid = false; $("#e_o_424_accname").removeClass('hidden');errorMessages.push('Name in which account is held must be populated')}     
      if (isValid == false) {
        displayErrorMessages(errorMessages);
      } 
      else {
        $("#divError").html('');
      }
      return isValid;
    }   
    function validate_426(me, action) {
      $("#divError").html('');
      $("#e_o_426_bank ,#e_o_426_branch, #e_o_426_sc1 ,#e_o_426_accno, #e_o_426_accname").addClass('hidden');      
      var isValid = true;
      var errorMessages = [];
      if (($("#BankName").val() == null) || ($("#BankName").val().trim() === '')) { isValid = false; $("#e_o_426_bank").removeClass('hidden');errorMessages.push('Name of Bank or Financial Institution')}
      if (($("#BankAddress").val() == null) || ($("#BankAddress").val().trim() === '')) { isValid = false; $("#e_o_426_branch").removeClass('hidden');errorMessages.push('Address of Bank or Financial Institution')}
      if (($("#BankCode").val() == null) || ($("#BankCode").val().trim() === '')) { 
        isValid = false; $("#e_o_426_sc1").removeClass('hidden');errorMessages.push('Bank Code must be populated')
      }  
      else if (!validAlphaNum($("#MaxLength1").val(), $("#BankCode").val().trim())) {
        isValid = false; $("#e_o_426_sc1").removeClass('hidden');errorMessages.push('Bank Code must have ' + $("#MaxLength1").val() + ' characters')
      }                
      if (($("#AccountNumber").val() == null) || ($("#AccountNumber").val().trim() === '')) { 
        isValid = false; $("#e_o_426_accno").removeClass('hidden');errorMessages.push('Account number must be populated')
      }            
      // else if (!validAlphaNum($("#MaxLength2").val(), $("#AccountNumber").val().trim())) {
      //   isValid = false; $("#e_o_426_accno").removeClass('hidden');errorMessages.push('Bank Code must have ' + $("#MaxLength2").val() + ' characters')
      // }                      
      if (($("#AccountName").val() == null) || ($("#AccountName").val().trim() === '')) { isValid = false; $("#e_o_426_accname").removeClass('hidden');errorMessages.push('Name in which account is held must be populated')}     
      if (isValid == false) {
        displayErrorMessages(errorMessages);
      } 
      else {
        $("#divError").html('');
      }
      return isValid;
    }    
    function validate_427(me, action) {
      $("#divError").html('');
      $("#e_o_427_bank ,#e_o_427_branch, #e_o_427_sc1 ,#e_o_427_accno, #e_o_427_accname").addClass('hidden');      
      var isValid = true;
      var errorMessages = [];
      if (($("#BankName").val() == null) || ($("#BankName").val().trim() === '')) { isValid = false; $("#e_o_427_bank").removeClass('hidden');errorMessages.push('Name of Bank or Financial Institution')}
      if (($("#BankAddress").val() == null) || ($("#BankAddress").val().trim() === '')) { isValid = false; $("#e_o_427_branch").removeClass('hidden');errorMessages.push('Address of Bank or Financial Institution')}
      if (($("#BankCode").val() == null) || ($("#BankCode").val().trim() === '')) { 
        isValid = false; $("#e_o_427_sc1").removeClass('hidden');errorMessages.push('Bank Code must be populated')
      }  
      else if (!validNumeric3("3", "5", $("#BankCode").val().trim())) {
        isValid = false; $("#e_o_427_sc1").removeClass('hidden');errorMessages.push('Bank Code must have minimum 3 and maximum 5 numeric digits')
      }                
      if (($("#AccountNumber").val() == null) || ($("#AccountNumber").val().trim() === '')) { isValid = false; $("#e_o_427_accno").removeClass('hidden');errorMessages.push('Account number must be populated')}                              
      if (($("#AccountName").val() == null) || ($("#AccountName").val().trim() === '')) { isValid = false; $("#e_o_427_accname").removeClass('hidden');errorMessages.push('Name in which account is held must be populated')}     
      if (isValid == false) {
        displayErrorMessages(errorMessages);
      } 
      else {
        $("#divError").html('');
      }
      return isValid;
    }     
    function validate_428(me, action) {
      $("#divError").html('');
      $("#e_o_428_bank ,#e_o_428_branch, #e_o_428_sc1 ,#e_o_428_accno, #e_o_428_accname").addClass('hidden');      
      var isValid = true;
      var errorMessages = [];
      if (($("#BankName").val() == null) || ($("#BankName").val().trim() === '')) { isValid = false; $("#e_o_428_bank").removeClass('hidden');errorMessages.push('Name of Bank or Financial Institution')}
      if (($("#BankAddress").val() == null) || ($("#BankAddress").val().trim() === '')) { isValid = false; $("#e_o_428_branch").removeClass('hidden');errorMessages.push('Address of Bank or Financial Institution')}
      if (($("#ACHTransitRountingNo").val() == null) || ($("#ACHTransitRountingNo").val().trim() === '')) { 
        isValid = false; $("#e_o_428_sc1").removeClass('hidden');errorMessages.push('ACH Transit Routing Number must be populated')
      }  
      else if (!validAlphaNum("9", $("#ACHTransitRountingNo").val().trim())) {
        isValid = false; $("#e_o_428_sc1").removeClass('hidden');errorMessages.push('ACH Transit Routing Numbe must have 9 characters')
      }                
      if (($("#AccountNumber").val() == null) || ($("#AccountNumber").val().trim() === '')) { isValid = false; $("#e_o_428_accno").removeClass('hidden');errorMessages.push('Account number must be populated')}                              
      if (($("#AccountName").val() == null) || ($("#AccountName").val().trim() === '')) { isValid = false; $("#e_o_428_accname").removeClass('hidden');errorMessages.push('Name in which account is held must be populated')}     
      if (isValid == false) {
        displayErrorMessages(errorMessages);
      } 
      else {
        $("#divError").html('');
      }
      return isValid;
    }        
    function validate_50(me, action) {
      $("#divError").html('');
      $("#e_o_5_bic ,#e_o_5_bank, #e_o_5_branch ,#e_o_5_iban, #e_o_5_accno").addClass('hidden');      
      var isValid = true;
      var errorMessages = [];
      if (($("#BeneficiaryBankBIC").val() == null) || ($("#BeneficiaryBankBIC").val().trim() === '')) { isValid = false; $("#e_o_5_bic").removeClass('hidden');errorMessages.push('Beneficiary Bank BIC must be populated')}
      if (($("#BeneficiaryBankersName").val() == null) || ($("#BeneficiaryBankersName").val().trim() === '')) { isValid = false; $("#e_o_5_bank").removeClass('hidden');errorMessages.push('Full Name of Beneficiary\'s Bankers must be populated')}
      if (($("#BeneficiaryBankAddress").val() == null) || ($("#BeneficiaryBankAddress").val().trim() === '')) { isValid = false; $("#e_o_5_branch").removeClass('hidden');errorMessages.push('Full Address of Beneficiary\'s Bankers must be populated')}                 
      if (($("#BeneficiaryIBAN").val() == null) || ($("#BeneficiaryIBAN").val().trim() === '')) { isValid = false; $("#e_o_5_iban").removeClass('hidden');errorMessages.push('Beneficiary IBAN must be populated')}                              
      if (($("#BeneficiaryAccountNumber").val() == null) || ($("#BeneficiaryAccountNumber").val().trim() === '')) { isValid = false; $("#e_o_5_accno").removeClass('hidden');errorMessages.push('Beneficiary Account Number must be populated')}     
      if (isValid == false) {
        displayErrorMessages(errorMessages);
      } 
      else {
        $("#divError").html('');
      }
      return isValid;
    }   
    function validate_60(me, action) {
      $("#divError").html('');
      $("#e_o_6_bank ,#e_o_6_branch, #e_o_6_accno").addClass('hidden');      
      var isValid = true;
      var errorMessages = [];
      if (($("#BeneficiaryBankersName").val() == null) || ($("#BeneficiaryBankersName").val().trim() === '')) { isValid = false; $("#e_o_6_bank").removeClass('hidden');errorMessages.push('Full Name of Beneficiary\'s Bankers must be populated')}
      if (($("#BeneficiaryBankAddress").val() == null) || ($("#BeneficiaryBankAddress").val().trim() === '')) { isValid = false; $("#e_o_6_branch").removeClass('hidden');errorMessages.push('Full Address of Beneficiary\'s Bankers must be populated')}
      if (($("#BeneficiaryAccountNumber").val() == null) || ($("#BeneficiaryAccountNumber").val().trim() === '')) { isValid = false; $("#e_o_6_accno").removeClass('hidden');errorMessages.push('Beneficiary Account Number must be populated')}                 
      if (isValid == false) {
        displayErrorMessages(errorMessages);
      } 
      else {
        $("#divError").html('');
      }
      return isValid;
    }        
    function validate_70(me, action) {
      $("#divError").html('');
      $("#e_o_7_bank ,#e_o_7_branch, #e_o_7_sc1 ,#e_o_7_sc2, #e_o_7_sc3, #e_o_7_accno, #e_o_7_accname").addClass('hidden');      
      var isValid = true;
      var errorMessages = [];
      if (($("#BankName").val() == null) || ($("#BankName").val().trim() === '')) { isValid = false; $("#e_o_7_bank").removeClass('hidden');errorMessages.push('Name of bank or building society must be populated')}
      if (($("#BankAddress").val() == null) || ($("#BankAddress").val().trim() === '')) { isValid = false; $("#e_o_7_branch").removeClass('hidden');errorMessages.push('Address of bank or building society must be populated')}
      if (($("#SortCode1").val() == null) || ($("#SortCode1").val().trim() === '')) { 
        isValid = false; $("#e_o_7_sc1").removeClass('hidden');errorMessages.push('Sort code (part 1) must be populated')
      }
      else if (!validNumeric2("2", $("#SortCode1").val().trim())) {
        isValid = false; $("#e_o_7_sc1").removeClass('hidden');errorMessages.push('Sort code (part 1) must have 2 digits')
      }
      if (($("#SortCode2").val() == null) || ($("#SortCode2").val().trim() === '')) { 
        isValid = false; $("#e_o_7_sc2").removeClass('hidden');errorMessages.push('Sort code (part 2) must be populated')
      }
      else if (!validNumeric2("2", $("#SortCode2").val().trim())) {
        isValid = false; $("#e_o_7_sc2").removeClass('hidden');errorMessages.push('Sort code (part 2) must have 2 digits')
      }
      if (($("#SortCode3").val() == null) || ($("#SortCode3").val().trim() === '')) { 
        isValid = false; $("#e_o_7_sc3").removeClass('hidden');errorMessages.push('Sort code (part 3) must be populated')
      }
      else if (!validNumeric2("2", $("#SortCode3").val().trim())) {
        isValid = false; $("#e_o_7_sc3").removeClass('hidden');errorMessages.push('Sort code (part 3) must have 2 digits')
      }     
      if (($("#AccountNumber").val() == null) || ($("#AccountNumber").val().trim() === '')) { 
        isValid = false; $("#e_o_7_accno").removeClass('hidden');errorMessages.push('Account number must be populated')
      }
      else if (!validNumeric3("8", "10", $("#AccountNumber").val().trim())) {
        isValid = false; $("#e_o_7_accno").removeClass('hidden');errorMessages.push('Account number must have 8 to 10 numeric digits')
      }      
      if (($("#AccountName").val() == null) || ($("#AccountName").val().trim() === '')) { isValid = false; $("#e_o_7_accname").removeClass('hidden');errorMessages.push('Account name must be populated')}
      if (isValid == false) {
        displayErrorMessages(errorMessages);
      } 
      else {
        $("#divError").html('');
      }
      return isValid;
    }    
    function validate_step4(me, action) {
      $("#errSplInstr ,#errMembership").addClass('hidden');  
      $("#divError").html('');    
      var isValid = true;
      var errorMessages = [];
      if (!($("#SplInstructions").val() == null) && !($("#SplInstructions").val() === '')) { 
        if ($("#SplInstructions").val().trim().split('\n').length > 5 || $("#SplInstructions").val().trim().length > 375) {
          isValid = false; $("#errSplInstr").removeClass('hidden');errorMessages.push('Special Instruction should have maximum 5 lines')
        }
      }
      if (!($("#PRS")[0].checked) && !($("#MCPS")[0].checked)) {
        isValid = false; $("#errMembership").removeClass('hidden');errorMessages.push('Please indicate whether the amendment applies to your PRS and/or MCPS membership')
      } 
      if (isValid == false) {                 
        displayErrorMessages(errorMessages);
      } 
      else {
        $("#divError").html('');
      }        
      return isValid;        
    }
    function clearValidationUI() {
      $(".formError").each(function () {
        $(this).css('display', 'none');
        $(this).html(''); 
      }); 
      $(".errorAsterix").each(function () {
        $(this).addClass("hidden");
        $(this).prev().removeClass('inputError');
      });      
    }            
    function updateValidationUI() {
      $(".formError").each(function () {
        if ($(this).children().length > 0) {
            $(this).css('display', 'block');
        }
        else {
            $(this).css('display', 'none');
        }
      });
      var h2text = $("#dBanner").text();
      $('body').find('h1').text(h2text);
      $('body').find('h1').addClass("bankFormHeader");
      $('body').find('h2').css('display', 'none'); 
      $(".errorAsterix").each(function () {
           if ($(this).hasClass("hidden")) {
            $(this).prev().removeClass('inputError');
           } else {
            $(this).prev().addClass('inputError');
           }
      }); 
    }       
    var bindUIActions = function () {
           
      var token = $('input[name="__RequestVerificationToken"]', config.container).val(); 
      updateValidationUI();
      $(".helpSearchButton").on('click',
      function () {
          $(".popupControl").each(function () {
              $(this).css("display", "none");
          });
          $(this).prev().css("display", "inherit");
          $(this).prev().css("visibility", "visible");
      });
      if ($(".popupControl").length) {
          $(document).mouseup(function (e) {
              var container = $(".popupControl");
              if (!container.is(e.target) // if the target of the click isn't the container...
          && container.has(e.target).length === 0) // ... nor a descendant of the container
              {
                  container.hide();
              }
          });
      }
      $(".closeLoginPanel").on('click',
      function () {
          $(this).closest(".popupControl").css("display", "none");
          $(this).closest(".popupControl").css("visibility", "hidden");
      });                 
      $('body').on("click", "#StepNextButton_Step1", function (e) {
        var me = $(this);
        e.preventDefault();
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ManualFormStep1.Territory': $("#ManualFormStep1_Territory").val(),
          'ItemId' : $("#ItemId").val(),
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();            
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });

      $('body').on("click", "#StepNextButton_Step2", function (e) {
        var me = $(this);
        e.preventDefault();
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val(),
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();            
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });

      $('body').on("click", "#StepNextButton_Step3_41", function (e) {
        var me = $(this);
        e.preventDefault();
        if (!validate_41()) { updateValidationUI(); return false; }
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val(),
          'BankName' : $("#BankName").val(),
          'BankAddress' : $("#BankAddress").val(),
          'BankCode' : $("#BankCode").val(),
          'BranchCode' : $("#BranchCode").val(),
          'AccountNumber' : $("#AccountNumber").val(),
          'AccountName' : $("#AccountName").val(),
          'AccountType' : $("#AccountType").val(),          
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });  
      $('body').on("click", "#StepNextButton_Step3_42", function (e) {
        var me = $(this);
        e.preventDefault();
        if (!validate_42()) { updateValidationUI(); return false; }
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val(),
          'BankName' : $("#BankName").val(),
          'BankAddress' : $("#BankAddress").val(),
          'BankCode' : $("#BankCode").val(),
          'BankCodePart2' : $("#BankCodePart2").val(),
          'AccountNumber' : $("#AccountNumber").val(),
          'AccountName' : $("#AccountName").val(),      
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });       
      $('body').on("click", "#StepNextButton_Step3_43", function (e) {
        var me = $(this);
        e.preventDefault();
        if (!validate_43()) { updateValidationUI(); return false; }
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val(),
          'BankName' : $("#BankName").val(),
          'BankAddress' : $("#BankAddress").val(),
          'BIC' : $("#BIC").val(),
          'IBAN' : $("#IBAN").val(),
          'AccountName' : $("#AccountName").val(),      
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });   
      $('body').on("click", "#StepNextButton_Step3_44", function (e) {
        var me = $(this);
        e.preventDefault();
        if (!validate_44()) { updateValidationUI(); return false; }
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val(),
          'BankName' : $("#BankName").val(),
          'BankAddress' : $("#BankAddress").val(),
          'BankCode' : $("#BankCode").val(),
          'BranchCode' : $("#BranchCode").val(),
          'AccountNumber' : $("#AccountNumber").val(),
          'AccountName' : $("#AccountName").val(),
          'AccountType' : $("#AccountType").val(),       
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });  
      $('body').on("click", "#StepNextButton_Step3_45", function (e) {
        var me = $(this);
        e.preventDefault();
        if (!validate_45()) { updateValidationUI(); return false; }
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val(),
          'BankName' : $("#BankName").val(),
          'BankAddress' : $("#BankAddress").val(),
          'BankCode' : $("#BankCode").val(),
          'BranchCode' : $("#BranchCode").val(),
          'AccountNumber' : $("#AccountNumber").val(),
          'AccountName' : $("#AccountName").val(),
          'AccountType' : $("#AccountType").val(),       
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      }); 
      $('body').on("click", "#StepNextButton_Step3_47", function (e) {
        var me = $(this);
        e.preventDefault();
        if (!validate_47()) { updateValidationUI(); return false; }
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val(),
          'BankName' : $("#BankName").val(),
          'BankAddress' : $("#BankAddress").val(),
          'BankCode' : $("#BankCode").val(),
          'AccountNumber' : $("#AccountNumber").val(),
          'AccountName' : $("#AccountName").val(),
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });     
      $('body').on("click", "#StepNextButton_Step3_48", function (e) {
        var me = $(this);
        e.preventDefault();
        if (!validate_48()) { updateValidationUI(); return false; }
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val(),
          'BankName' : $("#BankName").val(),
          'BankAddress' : $("#BankAddress").val(),
          'BankCode' : $("#BankCode").val(),
          'AccountNumber' : $("#AccountNumber").val(),
          'AccountName' : $("#AccountName").val(),
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });        
      $('body').on("click", "#StepNextButton_Step3_413", function (e) {
        var me = $(this);
        e.preventDefault();
        if (!validate_413()) { updateValidationUI(); return false; }
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val(),
          'BankName' : $("#BankName").val(),
          'BankAddress' : $("#BankAddress").val(),
          'BankCode' : $("#BankCode").val(),
          'AccountNumber' : $("#AccountNumber").val(),
          'AccountName' : $("#AccountName").val(),
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });        
      $('body').on("click", "#StepNextButton_Step3_414", function (e) {
        var me = $(this);
        e.preventDefault();
        if (!validate_414()) { updateValidationUI(); return false; }
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val(),
          'BankName' : $("#BankName").val(),
          'BankAddress' : $("#BankAddress").val(),
          'BankCode' : $("#BankCode").val(),
          'AccountNumber' : $("#AccountNumber").val(),
          'AccountName' : $("#AccountName").val(),
          'AccountType' : $("#AccountType").val(),           
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });   
      $('body').on("click", "#StepNextButton_Step3_415", function (e) {
        var me = $(this);
        e.preventDefault();
        if (!validate_415()) { updateValidationUI(); return false; }
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val(),
          'BankName' : $("#BankName").val(),
          'BankAddress' : $("#BankAddress").val(),
          'BankCode' : $("#BankCode").val(),
          'AccountNumber' : $("#AccountNumber").val(),
          'AccountName' : $("#AccountName").val(),
          'PassportNumber' : $("#PassportNumber").val(),          
          'IdentityNumber' : $("#IdentityNumber").val(),           
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });   
      $('body').on("click", "#StepNextButton_Step3_416", function (e) {
        var me = $(this);
        e.preventDefault();
        if (!validate_416()) { updateValidationUI(); return false; }
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val(),
          'BankName' : $("#BankName").val(),
          'BankAddress' : $("#BankAddress").val(),
          'IBAN' : $("#IBAN").val(),
          'AccountName' : $("#AccountName").val(),           
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });       
      $('body').on("click", "#StepNextButton_Step3_421", function (e) {
        var me = $(this);
        e.preventDefault();
        if (!validate_421()) { updateValidationUI(); return false; }
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val(),
          'BankName' : $("#BankName").val(),
          'BankAddress' : $("#BankAddress").val(),
          'BankCode' : $("#BankCode").val(),
          'AccountNumber' : $("#AccountNumber").val(),
          'AccountSuffix' : $("#AccountSuffix").val(),                    
          'AccountName' : $("#AccountName").val(),           
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });    
      $('body').on("click", "#StepNextButton_Step3_424", function (e) {
        var me = $(this);
        e.preventDefault();
        if (!validate_424()) { updateValidationUI(); return false; }
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val(),
          'BankName' : $("#BankName").val(),
          'BankAddress' : $("#BankAddress").val(),
          'BankCode' : $("#BankCode").val(),
          'AccountNumber' : $("#AccountNumber").val(),                  
          'AccountName' : $("#AccountName").val(),           
          'AccountType' : $("#AccountType").val(),  
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });    
      $('body').on("click", "#StepNextButton_Step3_426", function (e) {
        var me = $(this);
        e.preventDefault();
        if (!validate_426()) { updateValidationUI(); return false; }
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val(),
          'BankName' : $("#BankName").val(),
          'BankAddress' : $("#BankAddress").val(),
          'BankCode' : $("#BankCode").val(),
          'AccountNumber' : $("#AccountNumber").val(),                  
          'AccountName' : $("#AccountName").val(),  
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });       
      $('body').on("click", "#StepNextButton_Step3_427", function (e) {
        var me = $(this);
        e.preventDefault();
        if (!validate_427()) { updateValidationUI(); return false; }
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val(),
          'BankName' : $("#BankName").val(),
          'BankAddress' : $("#BankAddress").val(),
          'BankCode' : $("#BankCode").val(),
          'AccountNumber' : $("#AccountNumber").val(),                  
          'AccountName' : $("#AccountName").val(),  
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      }); 
      $('body').on("click", "#StepNextButton_Step3_428", function (e) {
        var me = $(this);
        e.preventDefault();
        if (!validate_428()) { updateValidationUI(); return false; }
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val(),
          'BankName' : $("#BankName").val(),
          'BankAddress' : $("#BankAddress").val(),
          'ACHTransitRountingNo' : $("#ACHTransitRountingNo").val(),
          'AccountNumber' : $("#AccountNumber").val(),                  
          'AccountName' : $("#AccountName").val(),  
          'AccountType' : $("#AccountType").val(),          
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });   
      $('body').on("click", "#StepNextButton_Step3_50", function (e) {
        var me = $(this);
        e.preventDefault();
        if (!validate_50()) { updateValidationUI(); return false; }
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val(),
          'BeneficiaryBankBIC' : $("#BeneficiaryBankBIC").val(),
          'BeneficiaryBankersName' : $("#BeneficiaryBankersName").val(),
          'BeneficiaryBankAddress' : $("#BeneficiaryBankAddress").val(),
          'BeneficiaryIBAN' : $("#BeneficiaryIBAN").val(),                  
          'BeneficiaryAccountNumber' : $("#BeneficiaryAccountNumber").val(),  
          'BeneficiaryBankRoutingNo' : $("#BeneficiaryBankRoutingNo").val(),          
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });        
      $('body').on("click", "#StepNextButton_Step3_60", function (e) {
        var me = $(this);
        e.preventDefault();
        if (!validate_60()) { updateValidationUI(); return false; }
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val(),
          'BeneficiaryBankersName' : $("#BeneficiaryBankersName").val(),
          'BeneficiaryBankAddress' : $("#BeneficiaryBankAddress").val(),
          'BeneficiaryAccountNumber' : $("#BeneficiaryAccountNumber").val(),
          'BeneficiaryBankRoutingNo' : $("#BeneficiaryBankRoutingNo").val(),          
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });                                             
      $('body').on("click", "#StepNextButton_Step3_70", function (e) {
        var me = $(this);
        e.preventDefault();
        if (!validate_70()) { updateValidationUI(); return false; }
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val(),
          'BankName' : $("#BankName").val(),
          'BankAddress' : $("#BankAddress").val(),
          'SortCode1' : $("#SortCode1").val(),
          'SortCode2' : $("#SortCode2").val(),
          'SortCode3' : $("#SortCode3").val(),  
          'AccountNumber' : $("#AccountNumber").val(),
          'AccountName' : $("#AccountName").val(),
          'RollReferenceNumber' : $("#RollReferenceNumber").val(),          
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });           
      $('body').on("click", "#StepNextButton_Step4", function (e) {
        var me = $(this);
        e.preventDefault();
        if (!validate_step4()) { updateValidationUI();return false; }
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val(),
          'PRS' : $("#PRS")[0].checked,          
          'MCPS' : $("#MCPS")[0].checked,
          'SplInstructions' : $("#SplInstructions").val(),                
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();            
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });       
      $('body').on("click", "#bank-details-back", function () {
        var me = $(this);
        var url = $(this).attr("action-url");
        var step = $(this).attr("back-step");
        clearValidationUI();        
        loading(me, true);
        $.ajax({
          url: url,
          type: 'POST',
          dataType: 'html',
          data: { itemId : $("#ItemId").val(),
          step: step },
          success: function (result) {
            loading(me, false);
            config.container.html(result);
            updateValidationUI();            
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });        
    }

    return {
      init: init
    };

  }());
  if ($(".bank-form-manual").length > 0) {

    PRSapp.bankDetails.init();
  }
}(jQuery));