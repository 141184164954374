/**
 * @namespace FiltereeListPage
 * @memberof PRSapp
 */
(function($) {
    'use strict';

    PRSapp.FiltereeListPage = (function() {
        var init,
            filtersJSON,
            activeFilters,
            $defaultOption = $('<option></option>').attr('value', 0).text('Please Select');

        /**
         * @function init
         * @memberof! PRSapp.FiltereeListPage
         */
        init = function() {
            
            getFiltersJSON();
            listenForFilterChange();
            listenForRemoveFilterClick();

        };

        function getFiltersJSON() {
            filtersJSON = $.parseJSON($('.filtered-list__filter-json').val());
        }
        
        function listenForFilterChange() {

            $('.filtered-list__filter').on('change', function() {

                applyFiltering();
                
                if($(this).val() !== "0") {
                    var enableNextFilter = checkNextFilterState($(this));

                    if(enableNextFilter) {
                        enableFilter($(this).parent().next('.form-group').find('.filtered-list__filter'));
                        setFilterOptions($(this));
                    }
                }
                else {
                    $(this).parent().find('.filtered-list__remove-filter').click();
                }

            });
        }

        function applyFiltering() {
            activeFilters = checkForExistingFilters();

            if(activeFilters.length > 0) {
                loopThroughFilterableItems(activeFilters);
            }
            else {
                showAllItems();
            }
        }

        function checkForExistingFilters() {
            var filters = [];

            $('.filtered-list__filter').each(function() {
                if($(this).val() !== '0') {
                    filters.push($(this).val());
                    $(this).parent().addClass('active');
                }
            });

            return filters;
        }

        function loopThroughFilterableItems(filters) {

            var $firstLevel,
                $secondLevel,
                $thirdLevel;

            hideAllItems();

            switch (filters.length) {
                case 1:
                    $firstLevel = $('[data-filters="'+filters[0]+'"]');

                    $firstLevel.show();
                    $firstLevel.find('[data-filters]').show();
                    break;
                case 2:
                    $firstLevel = $('[data-filters="'+filters[0]+'"]');
                    $secondLevel = $firstLevel.find('[data-filters="'+filters[1]+'"]');

                    $firstLevel.show();
                    $secondLevel.show()
                    $secondLevel.find('[data-filters]').show();
                    break;
                case 3:
                    $firstLevel = $('[data-filters="'+filters[0]+'"]');
                    $secondLevel = $firstLevel.find('[data-filters="'+filters[1]+'"]');
                    $thirdLevel = $secondLevel.find('[data-filters="'+filters[2]+'"]');

                    $firstLevel.show();
                    $secondLevel.show();
                    $thirdLevel.show();
                    break;
                default:
                    showAllItems();
                    break;
            }

        }

        function showAllItems() {
            $('[data-filters]').show();
        }

        function hideAllItems() {
            $('[data-filters]').hide();
        }

        function checkNextFilterState($element) {
            var $nextFormGroup = $element.parent().next('.form-group');

            if($nextFormGroup.length > 0) {
                return true;
            }
            else {
                return false;
            }
        }

        function enableFilter($filter) {
            $filter.prop('disabled', false);
        }

        function setFilterOptions($currentFilter) {
            var filterItems = getFilterItems($currentFilter);

            resetFilters($currentFilter.parent().nextAll('.form-group').find('.filtered-list__filter'));
            appendNewFilters($currentFilter.parent().next('.form-group').find('.filtered-list__filter'), filterItems);

        }

        function getFilterItems($filter) {
            var filterOptions = [];

            if ($filter.parent().hasClass('filtered-list__filter--first-level')) {
                
                $.each(filtersJSON.FirstLevelItems, function(firstLevelKey, firstLevelFilter) {
                    if(firstLevelFilter.Title === activeFilters[0]) {
                        $.each(firstLevelFilter.SecondLevelItems, function(secondLevelKey, secondLevelFilter) {
                            filterOptions.push(secondLevelFilter.Title);
                        });
                    }
                });

            }
            else if ($filter.parent().hasClass('filtered-list__filter--second-level')) {

                $.each(filtersJSON.FirstLevelItems, function(firstLevelKey, firstLevelFilter) {
                    if(firstLevelFilter.Title === activeFilters[0]) {
                        $.each(firstLevelFilter.SecondLevelItems, function(secondLevelKey, secondLevelFilter) {
                            if(secondLevelFilter.Title === activeFilters[1]) {
                                $.each(secondLevelFilter.ThirdLevelItems, function(thirdLevelKey, thirdLevelFilter) {
                                    filterOptions.push(thirdLevelFilter.Title);
                                });
                            }
                        });
                    }
                });
                
            }

            return filterOptions;
        }

        function appendNewFilters($filter, filterItems) {

            $.each(filterItems, function(key, value) {
                if($.isArray(value)) {
                    $filter.append($('<option></option>').attr('value', key).text(key));
                }
                else {
                    $filter.append($('<option></option>').attr('value', value).text(value));
                }
            });

            updateChosenFilters();
        }

        function listenForRemoveFilterClick() {

            $('.filtered-list__filter-form').on('click', '.filtered-list__remove-filter', function(e) {
                e.preventDefault();

                var $parent = $(this).parent(),
                    $input = $parent.find('.filtered-list__filter');

                if ($parent.hasClass('filtered-list__filter--first-level')) {
                    removeFilterSelection($input);
                    deactivateFilter($('.filtered-list__filter-form').find('.form-group'));
                    resetChildFilters($parent.nextAll('.form-group'));
                    disableFilter($parent.nextAll('.form-group').find('.filtered-list__filter'));
                    updateChosenFilters();
                }
                else if ($parent.hasClass('filtered-list__filter--second-level')) {
                    removeFilterSelection($input);
                    deactivateFilter($parent);
                    resetChildFilters($parent.next());
                    disableFilter($parent.next().find('.filtered-list__filter'));
                    updateChosenFilters();
                }
                else if ($parent.hasClass('filtered-list__filter--third-level')) {
                    removeFilterSelection($input);
                    deactivateFilter($parent);
                    updateChosenFilters();
                }

                applyFiltering();

            });
        }

        function removeFilterSelection($filter) {
            $filter.prop('selectedIndex', 0);
        }

        function resetFilters($filter) {
            $filter.empty().append($defaultOption);
        }

        function resetChildFilters($element) {
            $element.find('.filtered-list__filter').empty().append($defaultOption);
        }

        function deactivateFilter($filterParent) {
            $filterParent.removeClass('active');
        }

        function disableFilter($filter) {
            $filter.prop('disabled', true);
        }

        function updateChosenFilters() {
            $('.filtered-list__filter').trigger('chosen:updated');
        }

        return {
          init : init
        };
    }());

    if($('#content_page.filtered-list').length > 0) {
        PRSapp.FiltereeListPage.init();
    }
}(jQuery));