/**
 * @namespace GlobalSearch
 * @memberof PRSapp
 */
(function($, viewport) {
    "use strict";

    PRSapp.GlobalSearch = (function() {
        var init;

        /**
         * @function init
         * @memberof! PRSapp.GlobalSearch
         */
        init = function() {

            setUpGlobalSearch();

        };

        function setUpGlobalSearch() {
            $('.navbar__search-bar').on('keyup', function() {
                var value = $(this).val();

                if(value.length > 0) {
                    PRSapp.Utils.enableButton($('.navbar__search-button'));
                }
                else {
                    PRSapp.Utils.disableButton($('.navbar__search-button'));
                }
            });
        }

        return {
          init : init
        };
    }());

    PRSapp.GlobalSearch.init();

})(jQuery, ResponsiveBootstrapToolkit);