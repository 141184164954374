(function ($) {
    "use strict";
  PRSapp.termsconditions = (function () {

        var config = {};

        var init = function () {
            config = {
              confirmMessage:termsconditionsConfirmMessage,
              subTitle: termsconditionsSubtitle,
              form: $('#terms-and-conditions-form')
            };
            bindUIActions();
        };

        var bindUIActions = function () {

          $('body').on('click', 'a#btnDecline', function (e) {
                e.preventDefault();
                var me = $(this);
                return iamConfirmSwitchProfile(config.confirmMessage, config.subTitle || null, function (result) {
                  if (result) {
                    $("#Decline").val(true);
                        config.form.submit();
                    }
                    else {
                        return false;
                    }
                });
          });

          $('body').on('click', 'a#btnBack', function (e) {
            e.preventDefault();
            window.history.back();
          });
        }

        return {
            init: init
        };
    }());

  if ($("#terms-and-conditions-form").length > 0) {
        PRSapp.termsconditions.init();
    }

}(jQuery));