/**
 * @namespace Glossary
 * @memberof PRSapp
 */
(function($, viewport) {
    "use strict";

    PRSapp.Glossary = (function() {
        var init;
        var isPending = false;

        /**
         * @function init
         * @memberof! PRSapp.Glossary
         */
        init = function() {

            setUpGlossaryLink();

        };

        function setUpGlossaryLink() {
            $('[data-toggle="glossary"]').on('click', function(e) {
                e.preventDefault();

                var glossaryTerm = getGlossaryTerm($(this));

                getGlossaryData(glossaryTerm);

            });
        };

        function getGlossaryTerm($element) {
            return $element.data('glossary-term');
        };

        function getGlossaryData(glossaryTerm) {
            // cancel if a request is already happening
            if(isPending) return;

            isPending = true;

            $.get('/api/glossary', {'term': glossaryTerm}, function(data) {

                var title = data.title,
                    description = data.description,
                    $container = $('#glossary__modal');

                $container.find('.modal__title').text(title);
                $container.find('.modal__description').html(description);

                $('#glossary__modal').modal({
                    keyboard: true,
                    backdrop: true
                });

                //all done
                isPending = false;

            }).fail(function() {
              //failed, let user try again
              isPending = false;
            });

        }

        return {
          init : init
        };
    }());

    PRSapp.Glossary.init();

})(jQuery, ResponsiveBootstrapToolkit);
