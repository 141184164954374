(function ($) {
    "use strict";
    PRSapp.manageUserProfieAccess = (function () {

        var config = {};

        function init() {
            config = {
                removeUserLinkId: ".remove-user-profile",
                confirmMessage: manageAccessRemoveProfileMessage,
                form: "#manage-profile-access-form"

            };
            bindUIActions();
        };

        var bindUIActions = function () {

            $(config.removeUserLinkId).click(function (e) {
                e.preventDefault();
                var url = $(this).attr("href");
                var userId = $(this).attr("profileid");
              var name = $(this).attr("profilename");
              var isMember = $("#mType").val();
              if (isMember == "True")
                config.confirmMessage = manageAccessRemoveMemberProfileMessage;
              else
                config.confirmMessage = manageAccessRemoveProfileMessage;
                return iamConfirmSwitchProfile(config.confirmMessage.replace('||', name), config.confirmSubMessage || null, function (result) {
                    if (result) {
                        $("#access-profile-id").val(userId);
                        $(config.form).submit();
                    }
                });
            });
        }

        return {
            init: init
        };

    }());

    if ($("#manage-profile-access-form").length > 0) {
        PRSapp.manageUserProfieAccess.init();

    }

}(jQuery));


