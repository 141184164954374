/**
 * @namespace Video
 * @memberof PRSapp
 */
(function($, viewport) {
    "use strict";

    PRSapp.Troubleshooter = (function() {
        var init, structuredData, filtersJSON, headlinesJSON, userTypeId;
        var $troubleshooter, $breadcrumbs, $headline, $headlineWithSelect, $select, $backBtn, $container, $contactLink, $breadcrumbStartBtn, $breadcrumbLastBtn, $breadcrumbLastBtnWrapper, $bottomLinksWrapper;

        /**
         * @function init
         * @memberof! PRSapp.Troubleshooter
         */
        init = function() {
          $troubleshooter = $('.troubleshooter');

          if($troubleshooter.length <= 0) return;

          //cach the selectors
          $container = $('.troubleshooter__step');
          $select = $('.troubleshooter__select');
          $headline = $('.troubleshooter__headline');
          $headlineWithSelect = $('.troubleshooter__headline--select');

          $backBtn = $('.troubleshooter__back');
          $contactLink = $('.troubleshooter__contact');
          $bottomLinksWrapper = $troubleshooter.find('.troubleshooter__bottom');

          $breadcrumbStartBtn = $('.troubleshooter__breadcrumbs-link--start');
          $breadcrumbLastBtnWrapper = $('.troubleshooter__breadcrumbs-item--last');
          $breadcrumbLastBtn = $('.troubleshooter__breadcrumbs-link--last');
          $breadcrumbs = $('.troubleshooter__breadcrumbs');

          //Hide values that are not shown on first step
          $backBtn.hide();
          $contactLink.hide();
          $bottomLinksWrapper.hide();

          $headline.hide();

          $breadcrumbs.hide();
          //$breadcrumbLastBtnWrapper.hide();
          //$breadcrumbStartBtn.hide();

          userTypeId = $select.val();

          getFiltersJSON();
          getPathFromQuery();
          listenForTypeChange();
          listenForBtnClick();
        };

        function listenForBtnClick() {
          $troubleshooter.on('click', function(evt) {
            var target = evt.target;

            //check if the button is part of the troubleshooter
            if($(target).hasClass('troubleshooter__nav')) {
              var id = $(target).attr('id');
              renderTopics(id);

            }
          });
        }

        function listenForTypeChange() {
          $select.on('change', function(evt) {
            userTypeId = $(this).val();

            renderTopics(userTypeId);
          });
        }

        function renderTopics(path) {
          //used to split up & create paths
          var separator = '--';
          //split the path so we can iterate over it
          //the path matches the structure of our JSON oject
          var paths = path.split(separator);
          $('.troubleshooter__breadcrumbs-item--dynamics').remove();

          //pathIdentifier: used to create the path for each rendered button
          var topics, pathIdentifier, prevLabel, backPath;

          //set the breadcrumb start btn
          $breadcrumbStartBtn.attr('id', paths[0]);

          // check if the ContactUsUrl is not empty or null
          var showHelpLink = structuredData[userTypeId].ContactUsUrl.length > 0 || structuredData[userTypeId].ContactUsUrl !== 'null';
          // if there is a Link get its Value and the Caption
          if (showHelpLink) {
            var helpLinkHref = structuredData[userTypeId].ContactUsUrl;
            var helpLinkText = structuredData[userTypeId].ContactUsCaption;
          }

          if(paths.length > 1) {
            $breadcrumbs.show();
          } else {
            $breadcrumbs.hide();
          }

          for(var i = 0; i < paths.length; i++) {

            var pathIndex = paths[i];

            if(i === 0 ) {// first json is an object so treated different
              topics = structuredData[pathIndex].Topics;
              pathIdentifier = paths[0];
              backPath = paths[i];

              $headlineWithSelect.show();
              $headline.hide();

              $backBtn.hide();
              $breadcrumbStartBtn.hide();
              $breadcrumbLastBtnWrapper.hide();

              $contactLink.hide();
              $bottomLinksWrapper.hide();

            } else {
              prevLabel = topics[pathIndex].Title;
              topics = topics[pathIndex].Topics;

              //if there is no next step, exit
              if(!topics) return;

              pathIdentifier += separator + pathIndex;

              $backBtn.attr('id', backPath).show();
              $bottomLinksWrapper.show();

              //Last item should not be a link
              if(i < (paths.length - 1)) {
                $breadcrumbLastBtnWrapper.before('<li class="troubleshooter__breadcrumbs-item troubleshooter__breadcrumbs-item--dynamics"><button id="' + pathIdentifier + '" class="troubleshooter__nav troubleshooter__breadcrumbs-link">' + prevLabel + '</button></li>');
              }

              $breadcrumbLastBtn.text(prevLabel);
              $breadcrumbLastBtnWrapper.show();

              backPath = pathIdentifier;
              $breadcrumbStartBtn.show();

              // if show Help Link is true show the $contactLink and change href and
              // text according to it's user type otherwise hide it
              if (showHelpLink) {
                $contactLink.attr('href', helpLinkHref);
                $contactLink.text(helpLinkText);
                $contactLink.show();
              } else {
                $contactLink.hide();
              }

              //update headlines
              $headlineWithSelect.hide();
              //headlinesJSON is missing first item (because of select)
              $headline.text(headlinesJSON[i - 1]).show();
            }
          }



          $container.fadeOut(300, function(){
              $container.empty();
                if(!topics) return;
                topics.map(function(topic,i) {
                  //if the topic has a SolutionLink = it is the end of the journey
                  //add pathIdentifier at the end so we can use it on the result page back btn
                  if(topic.SolutionLink) {
                    var queryStringStart = topic.SolutionLink.indexOf('?') > -1 ? '&' : '?';
                    $container.append($('<a href="' + topic.SolutionLink + queryStringStart + 'previousTopicId=' + topic.Id + '&path=' + pathIdentifier + '&userTypeId=' + userTypeId +'" class="btn btn-primary troubleshooter__btn"></a>').attr('id', pathIdentifier + separator + i).text(topic.Title));
                  } else {
                    $container.append($('<button class="btn btn-primary troubleshooter__nav troubleshooter__btn"></btn>').attr('id', pathIdentifier + separator + i).text(topic.Title));
                  }
                });

              $container.fadeIn();
              //reset focus
              $container.focus();
          });
        }

        function getFiltersJSON() {
            filtersJSON = $.parseJSON($('.troubleshooter__json').val());
            headlinesJSON = $.parseJSON($('.troubleshooter_subtitles__json').val());
            structuredData = {};

            //transform into an object we can easily use to navigate around
            filtersJSON.map(function(userId) {
              structuredData[userId.UserTypeId] = userId;
            });
        }

        function getParameterByName(name, url) {
            if (!url) url = window.location.href;

            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        }

        function getPathFromQuery() {
          var path = getParameterByName('path');
          if(path) {
            renderTopics(path);
          }
        }

        return {
          init : init
        };
    }());

    PRSapp.Troubleshooter.init();

})(jQuery, ResponsiveBootstrapToolkit);
