
// Automatically select/deselect all checkboxes whithin a .main-boxed-section where .js_mbs_select-all/.js_mbs_deselect-all buttons exist
jQuery(document).ready(function () {
    jQuery('body').on('click', '.js_mbs_select-all', function () {
        var checkboxes = jQuery(this).closest('.main-boxed-section').find(':checkbox');
        checkboxes.prop('checked', true);
    });
    jQuery('body').on('click', '.js_mbs_deselect-all', function () {
        var checkboxes = jQuery(this).closest('.main-boxed-section').find(':checkbox');
        checkboxes.prop('checked', false);
    });
    setModelMessageComponent();
});
//######## UTILS

// source: https://stackoverflow.com/questions/171251/how-can-i-merge-properties-of-two-javascript-objects-dynamically
/**
 * Overwrites obj1's values with obj2's and adds obj2's if non existent in obj1
 * @param obj1
 * @param obj2
 * @returns obj3 a new object based on obj1 and obj2
 */
function merge_options(obj1, obj2) {
    var obj3 = {};
    for (var attrname in obj1) {
        obj3[attrname] = obj1[attrname];
    }
    for (var attrname in obj2) {
        obj3[attrname] = obj2[attrname];
    }
    return obj3;
}


//######## MODALS

// Script to ensure all modals are towards the end of the screen/body tag
jQuery('.modal').appendTo('body');

// This function allows for 
function iamConfirmOk(options, callback) {

    if (!callback) alert("no callback for " + JSON.stringify(options)); // TODO: cleanup
    var id = options.id;
    // ensure the modal exists
    if (!jQuery(id).length) { // TODO: update that generic template to work
        jQuery('body').append('<div id="' + id + '" class="modal" role="dialog" aria-labelledby="' + id + '" aria-hidden="true"><div class="modal-dialog"><div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-hidden="true">Ã—</button><h3 id="dataConfirmLabel">Please Confirm</h3></div><div class="modal-body"><div class="modal-title"></div></div><div class="modal-footer"><button class="btn" data-dismiss="modal" aria-hidden="true">Cancel</button><a class="btn btn-primary" id="dataConfirmOK" data-confirm-ok="true">OK</a></div></div></div>');
    }

    if (callback) {
        // Remove bindings from that button
        if (options.yesLabel) jQuery(id).find('[data-modal-confirm]').text(options.yesLabel);
        jQuery(id).find('[data-modal-confirm]').off("click");
        // Add new binding
        jQuery(id).find('[data-modal-confirm]').on("click", function () {
            callback(true); // close..
            jQuery(id).modal('hide');
        });
    }

    // Cancel update label
    if (options.cancelLabel) jQuery(id).find('.btn[data-dismiss="modal"]').text(options.cancelLabel);

    // Deny
    if (jQuery(id).find('[data-modal-deny]').length) {
        // Remove bindings from that button
        if (options.noLabel) jQuery(id).find('[data-modal-deny]').text(options.noLabel);
        jQuery(id).find('[data-modal-deny]').off("click");
        // Add new binding
        jQuery(id).find('[data-modal-deny]').on("click", function () {
            callback(false);
            // close..
            jQuery(id).modal('hide');
        });
    }

    // Update the content and show
    if (options.title) jQuery(id).find('.modal-title').text(options.title);
    if (options.subtitle) jQuery(id).find('.modal-subtitle').text(options.subtitle);
    //jQuery('#dataConfirmOK').attr('href', href);
    jQuery(id).modal({
        show: true
    });
};

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

// Set up a new Modal providing the options
function setModelMessageComponent() {
    if(jQuery('#modalmessage__modal').length > 0) {
        var is_opera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        var is_Edge = navigator.userAgent.indexOf("Edge") > -1;
        var is_chrome = !!window.chrome && !is_opera && !is_Edge;
        var is_explorer= typeof document !== 'undefined' && !!document.documentMode && !is_Edge;
        var is_firefox = typeof window.InstallTrigger !== 'undefined';
        var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        jQuery('#modalmessage__modal').modal({
            keyboard: false,
            backdrop: false
        });
        jQuery('#modalmessage__modal').modal('show');
        if(jQuery('#modalmessage__modal').hasClass("chrome-only") && !is_chrome) {
            jQuery('#modalmessage__modal').modal('toggle');
        }
        else if(jQuery('#modalmessage__modal').hasClass("firefox-only") && !is_firefox) {
            jQuery('#modalmessage__modal').modal('toggle');
        }
        else if(jQuery('#modalmessage__modal').hasClass("safari-only") && !is_safari) {
            jQuery('#modalmessage__modal').modal('toggle');
        }
        else if(jQuery('#modalmessage__modal').hasClass("ie-only") && !is_explorer) {
            jQuery('#modalmessage__modal').modal('toggle');
        }
	}
}

// Set up a new Modal providing the options
function iamModal(options, callback) {

    var defaultOptions = {
        id: "#iam-modal-confirm-ok"
    };

    var mergedOptions = merge_options(defaultOptions, options);
    return iamConfirmOk(mergedOptions, callback);
}

var iamConfirmSwitchProfile = function (title, subtitle, callback) {
    var options = {
        id: "#iam-modal-confirm-ok",
        title: decodeHtml(title),
        subtitle: subtitle
    };
    return iamConfirmOk(options, callback);
};

var iamAlert = function (title, subtitle, callback) {
    var options = {
        id: "#iam-modal-alert",
        title: title,
        subtitle: subtitle
    };
    return iamConfirmOk(options, callback);
};

var iamConfirm = function (title, subtitle, callback) {

    var options = {
        id: "#iam-modal-confirm-yes-no",
        title: title,
        subtitle: subtitle
    };
    return iamConfirmOk(options, callback);
};
var iamApprove = function (title, subtitle, callback) {

    var options = {
        id: "#iam-modal-confirm-yes-no",
        title: title,
        subtitle: subtitle,
        yesLabel: "Approve",
        noLabel: "Reject",
        cancelLabel: "Abandon"
    };
    return iamConfirmOk(options, callback);
};
