(function ($) {
  "use strict";
  PRSapp.signUpMember = (function () {

    var config = {};
    function init() {

      config = {
        hasAdminAccountUrl: hasAdminAccountUrl,
        isValidMembershipNoUrl: isValidMembershipNo
      };

      $("div[hide-me*='true']").each(function (index) {
        $(this).toggle(false);
      });
      $("#gcaptch-error").css("display", "none");

      bindUIActions();
    };



    function setFormsValidation() {
      var rules = {
        MembershipNumber: {
          required: true,
          number: true,
          minlength: 6,
          maxlength: 11,
          remote: {
            type: 'post',
            url: config.isValidMembershipNoUrl,
            data: {
              'membershipNo': function () { return $('#MembershipNumber').val(); }
            },
            dataType: 'json'
          }
          
        },
        Firstname: {
          required: true,
          maxlength: 50,
          regex: "^([a-zA-Z0-9 .&'-]+)$"
        },
        Secondname: {
          required: true,
          maxlength: 50,
          regex: "^([a-zA-Z0-9 .&'-]+)$"
        },
        EMail: {
          required: true,
          email: true
        },
        MemberType: {
          required: true
        },
        AryYouTheMember: {
          required: true
        },
        JobDescription: {
          required: true,
          maxlength: 100
        },

        IdentityCheck: {
          required: true,
          maxlength: 4,
          number: true
        }
      };

      var messages = {
        MembershipNumber: {
          required: 'This field is required',
          number: "Only numbers are allowed",
          minlength: 'Membership number should be in between 6 to 11 numerical characters',
          maxlength: 'Membership number should be in between 6 to 11 numerical characters',
          remote: "This isn't a valid membership number. Please try again"
            },
        Firstname: {
          required: 'This field is required',
          maxlength: 'Maximum 50 characters are allowed',
          regex: "Please enter a valid name"
        },
        Secondname: {
          required: 'This field is required',
          maxlength: 'Maximum 50 characters are allowed',
          regex: "Please enter a valid name"
        },
        EMail: {
          required: 'This field is required',
          email: 'This field is not valid'
        },
        MemberType: {
          required: 'This field is required'
        },
        AryYouTheMember: {
          required: 'This field is required'
        },
        JobDescription: {
          required: 'This field is required',
          maxlength: 'Maximum 100 characters are allowed'
        },

        IdentityCheck: {
          required: 'This field is required',
          maxlength: 'Maximum 11 characters are allowed',
          number: 'Only numbers are allowed'
        }
      };

      var isValid = $('#signup-member-form').validate({
        errorClass: 'has-error',
        validClass: 'has-success',
        ignore: ':not(:visible)',
        highlight: function (element, errorClass, validClass) {
          $(element).parents('.form-group').addClass(errorClass).removeClass(validClass);
          $(element).parents('.form-group').find('.sprite').removeClass('hidden');
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).parents('.form-group').addClass(validClass).removeClass(errorClass);
          $(element).parents('.form-group').find('.sprite').addClass('hidden');
        },
        errorPlacement: function (error, element) {
          if (element.attr("name") == "MemberType") {
            error.insertAfter("#member-type-selection");
          }
          else if (element.attr("name") == "AryYouTheMember") {
            error.insertAfter("#are-you-the-member-selection");
          }
          else {
            error.insertAfter(element);
          }
        },
        rules: rules,
        messages: messages
      });
      $('#signup-member-submit').on("click", function () {
        if ($('#signup-member-form').valid()) {
          var captchResponse = $('#g-recaptcha-response').val();
          var isSelected = (captchResponse != undefined && captchResponse.length == 0) ? false : true;
          if (!isSelected) {
            $("#gcaptch-error").css("display", "block");
            $("#gcaptch-error").text("This field is required");
            $("#gcaptch-section").addClass("has-error");
            $('html, body').animate({
              scrollTop: ($('.has-error:visible').offset().top - 100)
            }, 500);
            //iamAlert("Captcha is required to proceed", "", function () { }); 
            return false;
          }
          $('#signup-member-submit').attr('disabled','disabled');
          $('#signup-member-form').submit();
        }
      });

    }

    function onMemberTypeChange() {
      $("input[type=radio][name=MemberType]").change(function () {
        var flag = this.value == 'Writer';
        $('#are-you-member').toggle(flag);
          $('#job-description').toggle(!flag);
      });
    }

    function onAreYouMemberChange() {
      $("input[type=radio][name=AryYouTheMember]").change(function () {
        var flag = this.value == 'No';
        if (flag == false) $('#JobDescription').val("");
        $('#job-description').toggle(flag);

      });
    }

    function bindUIActions() {
      $("#MembershipNumber").blur(function () {
        var me = $(this);
        if ($(this).valid() == true) {
          var membershipNumber = $(this).val();
          me.addClass('loading')

          $.ajax({
            type: "post",
            url: config.hasAdminAccountUrl,

            data: { 'membershipNo': membershipNumber },
            dataType: 'json',
            success: function (data, textStatus, xhr) {
              $("div[hide-me-admin*='true']").each(function (index) {
                $(this).toggle(!data);
              });
              me.removeClass('loading')

            },
            error: function (xhr) {
              me.removeClass('loading')
              alert(xhr.statusText);
            }
          });
        }
      });
    }

    return {
      init: init,
      setFormsValidation: setFormsValidation,
      onMemberTypeChange: onMemberTypeChange,
      onAreYouMemberChange: onAreYouMemberChange
    };

  }());

  if ($("#signup-member-form").length > 0) {

    PRSapp.signUpMember.init();
    PRSapp.signUpMember.setFormsValidation();
    PRSapp.signUpMember.onMemberTypeChange();
    PRSapp.signUpMember.onAreYouMemberChange();

  }

}(jQuery));