(function ($) {
  "use strict";
  PRSapp.manageNotification = (function () {
    var config = {};

    function init() {
      config = {
        button: "#approve",
        manageNotificationsUrl: manageNotificationsUrl,
        form: "#manage-notifications-form",
        confirmMessage: confirmMessage,
        confirmMessageSubTitle: confirmMessageSubTitle

      };
      bindUIActions();
    };


    var bindUIActions = function () {
      onConfirmChanges();
    }

    var onConfirmChanges = function () {
      $("#approve").attr("disabled", true);

      $('input:checkbox').change(function() {
        $("#approve").attr("disabled", false);
      });
      
      $("#selectAll").click(function(){
       $('input:checkbox').not(this).prop('checked', this.checked);
    });

      $(config.button).click(function (e) {
        e.preventDefault();
        var button = $(this);
        var url = $(this).attr("href");
        var checkBoxes =  $("#manage-notifications-form").find("input:checkbox").not("input[name ='selectAll']");
        config.RequestedPreferences = [];

          $.each(checkBoxes, function (key, value) {
            var emailTypeId = $(value).attr("value");
            var checked = $(value).is(':checked');
            config.RequestedPreferences.push(emailTypeId + '-' + checked);
          });
      
      
        $("#approve").attr("disabled", true);
        var manageNotificationsModel = {
          'RequestedPreferences': config.RequestedPreferences,
          'SaveUrl': config.manageNotificationsUrl
        }

        return iamConfirmSwitchProfile(config.confirmMessage, config.confirmMessageSubTitle, function (result) {
          if (result) {
            $.ajax({
              url: url,
              type: 'POST',
              data: { 'manageNotificationsModel': manageNotificationsModel },
              success: function (data, textStatus, xhr) {
                $("#message").show();
                $("html, body").animate({ scrollTop: 0 }, "slow");
                $("#approve").attr("disabled", false);
              },
              error: function (xhr) {
                alert(xhr.statusText);
                $("#approve").attr("disabled", false);
              }
            });
          }
          else {
            return false;
          }
        });

      });
    };

    return {
      init: init
    };

  }());

  if ($("#manage-notifications-form").length > 0) {
    PRSapp.manageNotification.init();
  }
}(jQuery));