(function ($) {
  "use strict";
  PRSapp.addNewUser = (function () {

    var config = {
      findUserByEmailUrl: "/api/sitecore/Access/FindUserByEmail",
      container: $("#add-new-user"),
      itemId: $("#itemId").val(),
      step1Header: "Add more details",
      step2Header: "Select atleast one account",
      step3Header: "Select permissions",
      atleastOneAccount: "Please select an account to continue",
      buttonText: "",
      loadingStatus: false  
    }

    var setFormsValidation = function () {
      var rules = {
        "Firstname": {
          required: true,
          maxlength: 100,
          unsafeCharacterDetectName: true
        },
        "Lastname": {
          required: true,
          maxlength: 100,
          unsafeCharacterDetectName: true
        },
        "Email": {
          required: true,
          email: true
        },
        "ConfirmEmail": {
          required: true,
          equalTo: '[name="Email"]'
        },
        "JobDescription": {
          unsafeCharacterDetectGeneralText: true
        }        
      };

      var messages = {
        "Firstname": {
          required: 'This field is required',
          maxlength: 'Maximum 100 characters are allowed',
          unsafeCharacterDetectName: 'Unsafe characters detected'
        },
        "Lastname": {
          required: 'This field is required',
          maxlength: 'Maximum 100 characters are allowed',
          unsafeCharacterDetectName: 'Unsafe characters detected'
        },
        "Email": {
          required: 'This field is required',
          email: 'Please enter a valid email address'
        },
        "ConfirmEmail": {
          required: 'This field is required',
          equalTo: 'The email addresses do not match'
         },
        "JobDescription": {
          unsafeCharacterDetectGeneralText: 'Unsafe characters detected'
        }
      };

      var isValid = config.container.validate({
        errorClass: 'has-error',
        validClass: 'has-success',
        ignore: ':not(:visible)',
        highlight: function (element, errorClass, validClass) {
          $(element).parents('.form-group').addClass(errorClass).removeClass(validClass);
          $(element).parents('.form-group').find('.sprite').removeClass('hidden');
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).parents('.form-group').addClass(validClass).removeClass(errorClass);
          $(element).parents('.form-group').find('.sprite').addClass('hidden');
        },
        errorPlacement: function (error, element) {
          error.insertAfter(element);
        },
        rules: rules,
        messages: messages
      });

    }
    var init = function () {
      bindUIActions();
      setFormsValidation();
      $("#email-type-error").css("display", "none");
      $('#ConfirmEmail').on("cut copy paste",function(e) {
        e.preventDefault();
     });

    }

    function loading(me, action) {
      if (action) {
        //var loadingText = $(me).attr("data-loading-text") == "" ? "LOADING..." : $(me).attr("data-loading-text");
        config.buttonText = $(me).attr("value");
        $(me).attr("value", $(me).attr("data-loading-text"));
        $(me).attr("disabled", true);
      }
      else {
        $(me).attr("value", config.buttonText);
        $(me).removeAttr("disabled");
      }

    }
    var bindUIActions = function () {

      var token = $('input[name="__RequestVerificationToken"]', config.container).val();   
      $('body').on("keypress", "#Email", function (e) {
        $("#email-type-error").css("display", "none");
        if (e.keyCode == 13) {
          var me = $("#add-new-user-input-email");      
           e.preventDefault();
           if ($("#Email").valid() == true && $("#ConfirmEmail").valid() == true) {
            loading(me, true);
            $.ajax({
              type: "post",
              url: $(me).attr("action-url"),
              data: { 'email': $("#Email").val() },
              dataType: 'html',
              success: function (response) {
                loading(me, false);
                config.container.html(response);
                $("html, body").animate({ scrollTop: 0 }, "slow");
              },
              error: function (xhr) {
                loading(me, false);
                alert("Please refresh your browser");
              }
            });
          }
        }

      });

      $('body').on("click", "#add-new-user-input-email", function (e) {
        var me = $(this);
        e.preventDefault();
        if ($("#Email").valid() == true && $("#ConfirmEmail").valid() == true) {
          var inputEmail = $("#Email").val();
          var currentEmail = $("#currentUser").val();
          if (inputEmail.toLowerCase() == currentEmail.toLowerCase()) {
            $("#email-type-error").css("display", "block");
            $("#email-type-error").text("Please enter a new email address");
            $("#email-type").addClass("has-error");
            return;
          }

          else {
            $("#email-type-error").css("display", "none");
            $("#email-type").removeClass("has-error");
          }
          loading(me, true);  
          $.ajax({
            type: "post",
            url: $(me).attr("action-url"),
            data: { 'email': $("#Email").val(),
            '__RequestVerificationToken': token },
            dataType: 'html',
            success: function (response) {
              loading(me, false);
              config.container.html(response);
              $("html, body").animate({ scrollTop: 0 }, "slow");
            },
            error: function (xhr) {
              loading(me, false);
              alert("Please refresh your browser");
            }
          });
        }
      });

      $('body').on("click", "#add-new-user-more-details", function () {
        var me = $(this);
        if (!$(config.container).valid()) { return false; }
        var url = $(this).attr("action-url");
        loading(me, true);
        $.ajax({
          url: url,
          type: 'POST',
          dataType: 'html',
          data: {
            firstname: $("#Firstname").val(), lastName: $("#Lastname").val(), JobDescription: $("#JobDescription").val(),
            '__RequestVerificationToken': token
          },
          success: function (result) {
            loading(me, false);
            config.container.html(result);
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });

      $('body').on("click", "#add-new-user-set-account", function () {
        var me = $(this);
        var url = $(this).attr("action-url");
        var accounts = [];
        $('#user-profiles-section input:checked').each(function () {
          accounts.push($(this).attr('value'));
        });

        if (accounts.length == 0) { iamAlert(config.atleastOneAccount, "", function () { }); return false; }
        loading(me, true);
        $.ajax({
          url: url,
          type: 'POST',
          data: { accounts: accounts, isRefresh: false },
          dataType: 'html',
          success: function (result) {
            loading(me, false);
            config.container.html(result);
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });
      
      $('body').on("change", ".onoffswitch-checkbox", function () {
        $("#add-new-user-set-permissions").attr("disabled", false);
        var selectedCheckBoxes = $("#add-new-user").find("input:checked").not("input[name ='isadmin']");
        if (selectedCheckBoxes.length < 1) { $("#add-new-user-set-permissions").attr("disabled", true); }
      });

      $('body').on("click", "#add-new-user-set-permissions", function () {
        var me = $(this);
        var url = $(this).attr("action-url");
        var selectedRoles = [];
        var selectedCheckBoxes = $("#add-new-user").find("input:checked").not("input[name ='isadmin']");
        var isAdmin = $("#add-new-user").find("input[name ='isadmin']:checked").length > 0 ? true : false;
        if (selectedCheckBoxes.length < 1) { $("#add-new-user-set-permissions").attr("disabled", true); return false; }
        $('#set-permissions input:checked').each(function () {
          selectedRoles.push($(this).attr('value'));
        });
        loading(me, true);
        $.ajax({
          url: url,
          type: 'POST',
          data: { 'orgType': $("#org-type").val(), 'appRoleId': selectedRoles, 'isAdmin': isAdmin, 'isRefresh' : false },
          dataType: 'html',
          success: function (result) {
            loading(me, false);
            if (result.indexOf("modal") > -1) { $("body").append(result); }
            else config.container.html(result);
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });

      $('body').on("click", "#add-new-user-save", function () {
        var me = $(this);
        var url = $(this).attr("action-url");
        $("#add-new-user-cancel").attr("disabled", true);
        loading(me, true);
        $.ajax({
          url: url,
          type: 'POST',
          data: {},
          dataType: 'html',
          success: function (result) {
            window.location.href = JSON.parse(result).nextUrl;
          },
          error: function (xhr) {
            loading(me, false);
            $("#add-new-user-cancel").removeAttr("disabled");
            alert("Please refresh your browser");
          }
        });
      });

      $('body').on("click", "#add-new-user-cancel", function () {
        var me = $(this);
        var url = $(this).attr("action-url");
        loading(me, true);
        $.ajax({
          url: url,
          type: 'POST',
          data: {},
          success: function (result) {
            loading(me, false);
            window.location.replace(result.url);
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });

      $('body').on("click", "#add-new-user-back", function () {
        var me = $(this);
        var url = $(this).attr("action-url");
        var step = $(this).attr("back-step");
        loading(me, true);
        $.ajax({
          url: url,
          type: 'POST',
          dataType: 'html',
          data: { step: step },
          success: function (result) {
            loading(me, false);
            config.container.html(result);
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });

      $('body').on("click", "#add-user-summary-modal-close", function () {
        $("#iam-modal-confirm-terms").remove();
      });
    }

    return {
      init: init
    };

  }());
  if ($("#add-new-user").length > 0) {

    PRSapp.addNewUser.init();
  }
}(jQuery));