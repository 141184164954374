(function ($) {
  "use strict";
  PRSapp.bankDetailsAuto = (function () {

    var config = {
      container: $("#Edit-Panel"),
      buttonText: ""
    }
    var init = function () {
      bindUIActions();
    }

    function loading(me, action) {
      if (action) {
        config.buttonText = $(me).attr("value");
        $(me).attr("value", $(me).attr("data-loading-text"));
        $(me).attr("disabled", true);
      }
      else {
        $(me).attr("value", config.buttonText);
        $(me).removeAttr("disabled");
      }
    }  
    function validAlphaNum(strCharacters, strValue) {
      var re = new RegExp("^([a-zA-Z0-9\_\,\\s\/\\-\&\.]{0," + strCharacters + "})$");
      return re.test(strValue);
    }     
    function validNumeric1(strValue) {
      if (isNaN(strValue)) {
        return false;
      }      
      return true;
    }   
    function validAccountNumber(strValue) {
      var re = new RegExp("^([0-9]{8,10})$");
      return re.test(strValue);
    }  
    function validSortCode(sortCode) {
      var data = {'SortCode': sortCode };
      var isValid = false;
      $.ajax({
        url: "/api/sitecore/BankDetails/ValidateSortCode",
        type: 'POST',
        async: false,
        contentType:'application/json',
        data: JSON.stringify(data),
        dataType:'json',
        success: function(result){
          $("#EditFormModel_BankId").val(result.bankId);
          $("#EditFormModel_BankName").val(result.bankName);
          $("#EditFormModel_BranchName").val(result.branchName);
          isValid = result.isValid;
        },
        error: function(xhr, ajaxOptions, thrownError) {
           //On error do this
             if (xhr.status == 200) {
     
                 alert(ajaxOptions);
             }
             else {
                 alert(xhr.status);
                 alert(thrownError);
             }
         }
      });
      return isValid;
    }      
    function displayErrorMessages(errorMessages) {
      $("#divError").html('<span class="sprite has-error"></span>Please correct the following errors and try submitting again:')
      var errorList = '<ul>';
      $.each(errorMessages, function(index, value){
        errorList += '<li class="fa-close-small-before">' + value + '</li>';
      });
      errorList += '</ul>';
      $("#divError").append(errorList);
    }                 
/*     function validate_70(me, action) {
      $("#divError").html('');
      $("#e_o_7_bank ,#e_o_7_branch, #e_o_7_sc1 ,#e_o_7_sc2, #e_o_7_sc3, #e_o_7_accno, #e_o_7_accname").addClass('hidden');      
      var isValid = true;
      var errorMessages = [];
      if (($("#BankName").val() == null) || ($("#BankName").val().trim() === '')) { isValid = false; $("#e_o_7_bank").removeClass('hidden');errorMessages.push('Name of bank or building society must be populated')}
      if (($("#BankAddress").val() == null) || ($("#BankAddress").val().trim() === '')) { isValid = false; $("#e_o_7_branch").removeClass('hidden');errorMessages.push('Address of bank or building society must be populated')}
      if (($("#SortCode1").val() == null) || ($("#SortCode1").val().trim() === '')) { 
        isValid = false; $("#e_o_7_sc1").removeClass('hidden');errorMessages.push('Sort code (part 1) must be populated')
      }
      else if (!validNumeric2("2", $("#SortCode1").val().trim())) {
        isValid = false; $("#e_o_7_sc1").removeClass('hidden');errorMessages.push('Sort code (part 1) must have 2 digits')
      }
      if (($("#SortCode2").val() == null) || ($("#SortCode2").val().trim() === '')) { 
        isValid = false; $("#e_o_7_sc2").removeClass('hidden');errorMessages.push('Sort code (part 2) must be populated')
      }
      else if (!validNumeric2("2", $("#SortCode2").val().trim())) {
        isValid = false; $("#e_o_7_sc2").removeClass('hidden');errorMessages.push('Sort code (part 2) must have 2 digits')
      }
      if (($("#SortCode3").val() == null) || ($("#SortCode3").val().trim() === '')) { 
        isValid = false; $("#e_o_7_sc3").removeClass('hidden');errorMessages.push('Sort code (part 3) must be populated')
      }
      else if (!validNumeric2("2", $("#SortCode3").val().trim())) {
        isValid = false; $("#e_o_7_sc3").removeClass('hidden');errorMessages.push('Sort code (part 3) must have 2 digits')
      }     
      if (($("#AccountNumber").val() == null) || ($("#AccountNumber").val().trim() === '')) { 
        isValid = false; $("#e_o_7_accno").removeClass('hidden');errorMessages.push('Account number must be populated')
      }
      else if (!validNumeric3("8", "10", $("#AccountNumber").val().trim())) {
        isValid = false; $("#e_o_7_accno").removeClass('hidden');errorMessages.push('Account number must have 8 to 10 numeric digits')
      }      
      if (($("#AccountName").val() == null) || ($("#AccountName").val().trim() === '')) { isValid = false; $("#e_o_7_accname").removeClass('hidden');errorMessages.push('Account name must be populated')}
      if (isValid == false) {
        displayErrorMessages(errorMessages);
      } 
      else {
        $("#divError").html('');
      }
      return isValid;
    }  */   
    function validate_editBank(me, action) { 
      $("#e_o_accname ,#e_o_payee, #e_o_accno ,#e_o_sortcode, #e_o_payref").addClass('hidden'); 
      $("#divError").html('');    
      var isValid = true;
      var errorMessages = [];
      if (($("#EditFormModel_AccountName").val() == null) || ($("#EditFormModel_AccountName").val().trim() === '')) { 
        isValid = false; $("#e_o_accname").removeClass('hidden');errorMessages.push('Please enter an account name')
      }      
      else if (!validAlphaNum("60", $("#EditFormModel_AccountName").val().trim())) {
          isValid = false; $("#e_o_accname").removeClass('hidden');errorMessages.push('Account name contains invalid characters')
      }
      if (($("#EditFormModel_PayeeName").val() == null) || ($("#EditFormModel_PayeeName").val().trim() === '')) { 
        isValid = false; $("#e_o_payee").removeClass('hidden');errorMessages.push('Please enter a payee name')
      }        
      else if (!validAlphaNum("100", $("#EditFormModel_PayeeName").val().trim())) {
          isValid = false; $("#e_o_payee").removeClass('hidden');errorMessages.push('Payee name contains invalid characters')
      }     
      if (($("#EditFormModel_AccountNumber").val() == null) || ($("#EditFormModel_AccountNumber").val().trim() === '')) { 
        isValid = false; $("#e_o_accno").removeClass('hidden');errorMessages.push('Please enter an account number')
      }      
      else if (!validAccountNumber($("#EditFormModel_AccountNumber").val().trim())) {
          isValid = false; $("#e_o_accno").removeClass('hidden');errorMessages.push('Account number must be 8 to 10 digits and only contain numbers 0-9')
      }    
      if (($("#EditFormModel_SortCode1").val() == null) || ($("#EditFormModel_SortCode1").val().trim() === '')) { 
        isValid = false; $("#e_o_sortcode").removeClass('hidden');errorMessages.push('Please enter a sort code')
      }  
      else if (($("#EditFormModel_SortCode2").val() == null) || ($("#EditFormModel_SortCode2").val().trim() === '')) { 
        isValid = false; $("#e_o_sortcode").removeClass('hidden');errorMessages.push('Please enter a sort code')
      }  
      else if (($("#EditFormModel_SortCode3").val() == null) || ($("#EditFormModel_SortCode3").val().trim() === '')) { 
        isValid = false; $("#e_o_sortcode").removeClass('hidden');errorMessages.push('Please enter a sort code')
      }                   
      else if (!validSortCode($("#EditFormModel_SortCode1").val().trim() + $("#EditFormModel_SortCode2").val().trim() + $("#EditFormModel_SortCode3").val().trim())) {
        isValid = false; $("#e_o_sortcode").removeClass('hidden');errorMessages.push('Please enter a valid sort code')
      }
      if (!validAlphaNum("18", $("#EditFormModel_PaymentRef").val().trim())) {
          isValid = false; $("#e_o_payref").removeClass('hidden');errorMessages.push('Payment ref contains invalid character')
      }           
      if (isValid == false) { 
        displayErrorMessages(errorMessages);        
      } 
      else {
        $("#divError").html('');
      }        
      return isValid;        
    }
    function validate_special_instructions(me, action) { 
      $("#e_o_spl_ins").addClass('hidden'); 
      $("#divError").html('');    
      var isValid = true;
      var errorMessages = [];
      if (($("#SpecialInstructionsFormModel_SpecialInstructions").val() == null) || ($("#SpecialInstructionsFormModel_SpecialInstructions").val().trim() === '')) { 
        isValid = false; $("#e_o_spl_ins").removeClass('hidden');errorMessages.push('Please enter your special instructions')
      }        
      if (isValid == false) { 
        displayErrorMessages(errorMessages);        
      } 
      else {
        $("#divError").html('');
      }        
      return isValid;          
    }      
    function clearValidationUI() {
      $(".formError").each(function () {
        $(this).css('display', 'none');
        $(this).html(''); 
      }); 
      $(".errorAsterix").each(function () {
        $(this).addClass("hidden");
        $(this).prev().removeClass('inputError');
      });      
    }            
    function updateValidationUI() {
      $(".formError").each(function () {
        if ($(this).children().length > 0) {
            $(this).css('display', 'block');
        }
        else {
            $(this).css('display', 'none');
        }
      });
      var h2text = $("#dBanner").text();
      $('body').find('h1').text(h2text);
      $('body').find('h1').addClass("bankFormHeader");
      $("#dBanner").css('display', 'none'); 
      $(".errorAsterix").each(function () {
           if ($(this).hasClass("hidden")) {
            $(this).prev().removeClass('inputError');
           } else {
            $(this).prev().addClass('inputError');
           }
      }); 
    }       
    var bindUIActions = function () {
           
      var token = $('input[name="__RequestVerificationToken"]', config.container).val(); 
      updateValidationUI();
      $(".helpSearchButton").on('click',
      function () {
          $(".popupControl").each(function () {
              $(this).css("display", "none");
          });
          $(this).prev().css("display", "inherit");
          $(this).prev().css("visibility", "visible");
      });
      if ($(".popupControl").length) {
          $(document).mouseup(function (e) {
              var container = $(".popupControl");
              if (!container.is(e.target) // if the target of the click isn't the container...
          && container.has(e.target).length === 0) // ... nor a descendant of the container
              {
                  container.hide();
              }
          });
      }
      $(".closeLoginPanel").on('click',
      function () {
          $(this).closest(".popupControl").css("display", "none");
          $(this).closest(".popupControl").css("visibility", "hidden");
      });                 
      $('body').on("click", "#auto-bank-edit-submit", function (e) {
        var me = $(this);
        e.preventDefault();
        loading(me, true); 
        if (!validate_editBank()) { updateValidationUI(); loading(me, false); return false; }     
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'EditFormModel.AccountName': $("#EditFormModel_AccountName").val(),
          'EditFormModel.PayeeName': $("#EditFormModel_PayeeName").val(),
          'EditFormModel.AccountNumber': $("#EditFormModel_AccountNumber").val(),
          'EditFormModel.SortCode1': $("#EditFormModel_SortCode1").val(),
          'EditFormModel.SortCode2': $("#EditFormModel_SortCode2").val(),
          'EditFormModel.SortCode3': $("#EditFormModel_SortCode3").val(),
          'EditFormModel.PaymentRef': $("#EditFormModel_PaymentRef").val(),
          'EditFormModel.BankId': $("#EditFormModel_BankId").val(),
          'EditFormModel.BankName': $("#EditFormModel_BankName").val(),
          'EditFormModel.BranchName': $("#EditFormModel_BranchName").val(),                              
          'EditFormModel.ApplyToBoth': ($('#EditFormModel_ApplyToBoth').length ? $('#EditFormModel_ApplyToBoth')[0].checked : false),
          'ItemId' : $("#ItemId").val(),
          '__RequestVerificationToken': token },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response);
            updateValidationUI();            
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });
      $('body').on("click", "#special-instructions-submit", function (e) {
        var me = $(this);
        e.preventDefault();
        loading(me, true);
        if (!validate_special_instructions()) { updateValidationUI(); loading(me, false); return false; }           
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'SpecialInstructionsFormModel.SpecialInstructions': $("#SpecialInstructionsFormModel_SpecialInstructions").val(),
          'ItemId' : $("#ItemId").val(),
          '__RequestVerificationToken': token },          
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response); 
            updateValidationUI();          
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });       
      $('body').on("click", "#AutoFormRedirectoToEdit", function (e) {
        var me = $(this);
        e.preventDefault();
        loading(me, true);  
        $.ajax({
          type: "post",
          url: $(me).attr("action-url"),
          data: { 'ItemId' : $("#ItemId").val() },
          dataType: 'html',
          success: function (response) {
            loading(me, false);
            config.container.html(response); 
            updateValidationUI();          
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });                  
      $('body').on("click", "#auto-bank-edit-back", function () {
        var me = $(this);
        var url = $(this).attr("action-url");
        var step = $(this).attr("back-step");
        clearValidationUI();        
        loading(me, true);
        $.ajax({
          url: url,
          type: 'POST',
          dataType: 'html',
          data: { itemId : $("#ItemId").val(),
          step: step },
          success: function (result) {
            loading(me, false);
            config.container.html(result);
            updateValidationUI();            
            $("html, body").animate({ scrollTop: 0 }, "slow");
          },
          error: function (xhr) {
            loading(me, false);
            alert("Please refresh your browser");
          }
        });
      });        
    }

    return {
      init: init
    };

  }());
  if ($(".bank-form-auto").length > 0) {
    PRSapp.bankDetailsAuto.init();    
  }
}(jQuery));