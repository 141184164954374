/**
 * @namespace ProductPage
 * @memberof PRSapp
 */
(function ($, _window) {
  PRSapp.ProductPage = (function () {
    /**
     * @function init
     * @memberof! PRSapp.ProductPage
     */

    const isTabletOrMobile = () => $('#tablet-mode').is(':visible');

    function requestFullScreen(videoElm) {
      const element = document.body;
      const requestMethod = element.requestFullScreen
    || element.requestFullscreen
    || element.webkitRequestFullscreen
    || element.webkitRequestFullScreen
    || element.mozRequestFullScreen
    || element.msRequestFullscreen;

      if (requestMethod) {
        requestMethod.apply(videoElm);
      } else {
        videoElm.webkitEnterFullscreen();
      }
    }

    function setUpVideoControls() {
      $('.video-hero').each(function () {
        if (isTabletOrMobile()) {
          const srcElm = $(this).children('source').eq(0);
          $(this).attr('src', srcElm.data('mobile-src'));
        }
      });

      $('.btn-video-play').on('click', function () {
        const videoId = $(this).data('video-target');
        const videoPlayer = $(videoId);
        if (isTabletOrMobile()) {
          requestFullScreen(videoPlayer[0]);
        }
        $(videoId).closest('.video-container').addClass('active');
        videoPlayer[0].play();

        $(videoId).on('fullscreenchange webkitfullscreenchange mozfullscreenchange webkitendfullscreen', () => {
          if (document.fullscreenElement === null
            || document.webkitFullscreenElement === null
             || document.webkitFullScreenElement === null) {
            videoPlayer[0].pause();
            $(videoId).closest('.video-container').removeClass('active');
          } else if (!videoPlayer[0].webkitDisplayingFullscreen) {
            videoPlayer[0].pause();
            $(videoId).closest('.video-container').removeClass('active');
          }
        });
      });

      $('.btn-video-exit').on('click', function () {
        const videoContainer = $(this).closest('.video-container');
        videoContainer.removeClass('active');
        videoContainer.find('.video-hero')[0].pause();
      });
    }

    const init = () => {
      setUpVideoControls();
    };

    return {
      init,
    };
  }());

  if ($('#product-page').length > 0) {
    PRSapp.ProductPage.init();
  }
// eslint-disable-next-line no-undef
}(jQuery, window));
