(function ($) {
    "use strict";
    PRSapp.navigationProfile = (function () {

        var config = {};

        function init() {
           config = {
                confirmMessage: navConfirmMessage,
                confirmMessageSubTitle: navConfirmMessageSubTitle,
				overlayLoadingTextId: "#overlay-text",
				removeProfileLoadingText: "Switching your account"				
            };
            bindUIActions();
        };

        function getQueryStrings() {
            var assoc = {};
            var decode = function (s) { return decodeURIComponent(s.replace(/\+/g, " ")); };
            var queryString = location.search.substring(1);
            var keyValues = queryString.split('&');

            for (var i in keyValues) {
                var key = keyValues[i].split('=');
                if (key.length > 1) {
                    assoc[decode(key[0]).toLowerCase()] = decode(key[1]);
                }
            }

            return assoc;
        }

        function createQueryString(queryDict) {
            var queryStringBits = [];
            for (var key in queryDict) {
                if (queryDict.hasOwnProperty(key)) {
                    queryStringBits.push(key + "=" + queryDict[key]);
                }
            }
            return queryStringBits.length > 0
                ? "?" + queryStringBits.join("&")
                : "";
        }

        var bindUIActions = function () {
            $(".navigation-profile-switch").click(function (e) {
                e.preventDefault();
                var link = $(this);
                return iamConfirmSwitchProfile(config.confirmMessage, config.confirmMessageSubTitle || null, function (result) {
                    if (result) {
						$(config.overlayLoadingTextId).text(config.removeProfileLoadingText);	
                        $('body').addClass("switching-profile");
                        $.ajax({
                            type: "post",
                            url: $(link).attr('href'),
                            data: { profileId: $(link).attr("profileid"), returnUrl: returnUrlNav },
                            success: function (data, textStatus, xhr)
                            {
                                window.location.href = data.url;
                                //var queryString = getQueryStrings();
                               // window.location.href = window.location.protocol + "//" + window.location.host + window.location.pathname + createQueryString(queryString);
                            },
                            error: function (xhr) {
                                alert("Please refresh your browser")
                            }
                        });
                    }
                    else {
                        return false;
                    }

              });

             
                //if (confirm(config.confirmMessage)) {
                //    $('body').addClass("switching-profile");
                //    e.preventDefault();
                //    var link = $(this);
                //    $.ajax({
                //        type: "post",
                //        url: $(link).attr('href'),
                //        data: { profileId: $(link).attr("profileid"), returnUrl: returnUrlNav },
                //        success: function (data, textStatus, xhr) { window.location = data.url; },
                //        error: function (xhr) {
                //            window.location.reload();
                //        }
                //    });
                //    return false;
                //}
                //else {
                //    return false;
                //}
            });
        }
        return {
            init: init
        };

    }());

    if ($("#nav").length > 0) {
        PRSapp.navigationProfile.init();

    }

}(jQuery));