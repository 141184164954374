/**
 * @namespace Video
 * @memberof PRSapp
 */
(function($, viewport) {
    "use strict";

    PRSapp.Video = (function() {
        var init,
            youTubePlayer;

        /**
         * @function init
         * @memberof! PRSapp.Video
         */
        init = function() {

            if($('.embed-responsive.youtube').length > 0) {
                injectVideoAPI('https://www.youtube.com/iframe_api');
            }

            if($('.embed-responsive.brightcove').length > 0) {
                injectVideoAPI('//players.brightcove.net/60142679001/default_default/index.min.js');
            }

            if($('.embed-responsive.brightcove-playlist').length > 0) {
                injectVideoAPI('//players.brightcove.net/60142679001/HkDDidr4g_default/index.min.js');
            }

            setUpVideoPlay();

        };

        function injectVideoAPI(apiJavascriptUrl) {
            var tag = document.createElement('script'),
                firstScriptTag = document.getElementsByTagName('script')[0];

            tag.src = apiJavascriptUrl;
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }

        function setUpVideoPlay() {
            $('.video__play-button-container').on('click', function() {
                var $parent = $(this).parent();
                
                hideCoverPhoto($(this));

                if($parent.hasClass('youtube')) {
                    playYouTubeVideo($(this));
                }
                else if($parent.hasClass('brightcove')) {
                    playBrightcoveVideo($(this), $parent);
                }
            });
        };

        function hideCoverPhoto($playButton) {
            $playButton.hide();
            $playButton.next('.video__cover-image').hide();
        };

        function playYouTubeVideo($playButton) {
            var videoElementId = $playButton.siblings('.youtube-player').attr('id'),
                youTubeId = $('#'+videoElementId).data('youtube-video-id');

            youTubePlayer = new YT.Player(videoElementId, {
                height: '390',
                width: '640',
                videoId: youTubeId,
                events: {
                    'onReady': onPlayerReady
                }
            });
        };

        function onPlayerReady(event) {
            event.target.playVideo();
        }

        function playBrightcoveVideo($playButton, $parent) {
            var videoId = $playButton.siblings('.video-js').attr('id');

            if($parent.hasClass('brightcove-playlist')) {
                var $context = $playButton.closest('.video');
                var height = $context.find('.vjs-playlist-item-list').outerHeight(true);

                $context.find('.vjs-playlist').animate({ height: height }, 700, function() {
                  setTimeout(function(){ $(this).height('auto'); }, 100);

                });
            }

            videojs(videoId).play();
        }

        return {
          init : init
        };
    }());

    PRSapp.Video.init();

})(jQuery, ResponsiveBootstrapToolkit);