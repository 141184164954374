(function ($) {
  "use strict";
  PRSapp.manageUser = (function () {

    var config = {};

    function init() {
      config = {
        removeUserLinkId: ".remove-user",
        confirmMessage: manageUserConfirmMessage,
        form: "#manage-user-form",
        overlayLoadingTextId: "#overlay-text",
        removeProfileLoadingText: "Removing Access"
      };
      bindUIActions();
    };

    var bindUIActions = function () {

      $(config.removeUserLinkId).click(function (e) {
        e.preventDefault();
        var url = $(this).attr("href");
        var userId = $(this).attr("userId");
        var button = $(this);
        var name = $(this).attr("user-name");
        var isMember = $(this).find("#mType").val();
        if (isMember == "True")
          config.confirmMessage = manageUserMemberConfirmMessage;
        else
          config.confirmMessage = manageUserConfirmMessage;
        $(button).attr("disabled", true);
        return iamConfirmSwitchProfile(config.confirmMessage.replace('||', name), config.confirmSubMessage || null, function (result) {
          if (result) {
            $("#UserId").val(userId);
            $(config.overlayLoadingTextId).text(config.removeProfileLoadingText);
            $('body').addClass("switching-profile");
            $(config.form).submit();
          }
        });
      });
    }
    return {
      init: init
    };

  }());

  if ($("#manage-user-form").length > 0) {
    PRSapp.manageUser.init();

  }

}(jQuery));


