(function ($) {
    "use strict";
    PRSapp.editMyProfile = (function () {

        function init() { };

        function setFormsValidation() {
            var rules = {
                "User.FirstName": {
                    required: true,
                    maxlength: 100
                },
                "User.LastName": {
                    required: true,
                    maxlength: 100
                },
                "User.UserEmail": {
                    required: true,
                    email: true
                }
                //"User.JobDescription": {
                //    required: true,
                //    maxlength: 200
                //}
            };

            var messages = {
                "User.FirstName": {
                    required: 'This field is required',
                    maxlength: 'Maximum 100 characters are allowed'
                },
                "User.LastName": {
                    required: 'This field is required',
                    maxlength: 'Maximum 100 characters are allowed'
                },
                "User.UserEmail": {
                    required: 'This field is required',
                    email: 'This field is not valid'
                }
                //"User.JobDescription": {
                //    required: 'This field is required',
                //    maxlength: 'Maximum 200 characters are allowed'
                //},
            };

            var isValid = $('#my-account-form').validate({
                errorClass: 'has-error',
                validClass: 'has-success',
                ignore: ':not(:visible)',
                highlight: function (element, errorClass, validClass) {
                    $(element).parents('.form-group').addClass(errorClass).removeClass(validClass);
                    $(element).parents('.form-group').find('.sprite').removeClass('hidden');
                },
                unhighlight: function (element, errorClass, validClass) {
                    $(element).parents('.form-group').addClass(validClass).removeClass(errorClass);
                    $(element).parents('.form-group').find('.sprite').addClass('hidden');
                },
                errorPlacement: function (error, element) {
                    error.insertAfter(element);
                },
                rules: rules,
                messages: messages,
                submitHandler: function (form) {
                    if ($(form).valid()) {
                        form.submit();
                    }
                    else {
                        return false;
                    }
                }

            });
        }
        return {
            init: init,
            setFormsValidation: setFormsValidation
        };

    }());

    if ($("#my-account-form") != 'undefined') {
        PRSapp.editMyProfile.init();
        PRSapp.editMyProfile.setFormsValidation();
    }

}(jQuery));


