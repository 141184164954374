/**
 * @namespace IFrame
 * @memberof PRSapp
 */
(function($, viewport) {
    "use strict";

    PRSapp.IFrame = (function() {
        var init;

        /**
         * @function init
         * @memberof! PRSapp.IFrame
         */
        init = function() {
            doResize(null); 
        };

        function doResize(event) {
            var $frameWrapper = $("#iframe-embed");
            var $frame = $frameWrapper.find("iframe");
            var frameScaleRatio = $frameWrapper.data("scaleratio");
            var scale, newWidth;
              
            scale = parseInt(frameScaleRatio) / 100 || 1;
            newWidth = (100 / parseInt(frameScaleRatio) || 1) * 100;

            $frameWrapper.css({
              "-ms-zoom": scale,
              "-moz-transform": "scale(" + scale + ")",
              "-moz-transform-origin": "0 0",
              "-o-transform": "scale(" + scale + ")",
              "-o-transform-origin": "0 0",
              "-webkit-transform": "scale(" + scale + ")",
              "-webkit-transform-origin": "0 0",
              "width": newWidth.toFixed(0) + "%"         
            });
          }

        return {
          init : init
        };
    }());

    PRSapp.IFrame.init();

})(jQuery, ResponsiveBootstrapToolkit);