/**
 * @namespace Gallery
 * @memberof PRSapp
 */
(function($, viewport) {
    "use strict";

    PRSapp.Gallery = (function() {
        var init;

        /**
         * @function init
         * @memberof! PRSapp.Gallery
         */
        init = function() {

            setUpSlideCounter();
            setUpLightbox();

        };

        function setUpSlideCounter() {
            $('.carousel').on('slid.bs.carousel', function(e) {
                $(this).find('.gallery__slide-number').text($(this).find('.active').index() + 1);
            });
        }

        function setUpLightbox() {

            enquire.register("screen and (max-width:768px)", {
                match : function() {
                    $('[data-toggle="lightbox"]').off('click');
                    $('[data-toggle="lightbox"]').on('click', function(e) {
                        e.preventDefault();
                    });
                }
            });

            enquire.register("screen and (min-width:769px)", {
                match : function() {
                    $('[data-toggle="lightbox"]').off('click');
                    $('[data-toggle="lightbox"]').on('click', function(e) {
                        e.preventDefault();
                        $(this).ekkoLightbox({
                            alwaysShowClose: true,
                            onContentLoaded: function() {
                                setLightboxHeight($(this));
                                updateLightboxSlideCounter($(this));
                            },
                            onHidden: function() {
                                $(this)[0]._$element.focus();
                            }
                        });
                    });
                }
            });
        }

        function setLightboxHeight($lightbox) {
            var imageHeight = $lightbox[0]._$modal.find('.in .img-fluid').height(),
                maxHeight = Math.round($(window).height() * 0.7);

            if(imageHeight > maxHeight) {
                $lightbox[0]._$modal.find('.ekko-lightbox-container').height(maxHeight);
                $lightbox[0]._$modal.find('.in .img-fluid').height(maxHeight);
                setLightboxWidth($lightbox);
            }
            else {
                $lightbox[0]._$modal.find('.ekko-lightbox-container').height(imageHeight);
            }
        }

        function setLightboxWidth($lightbox) {
            var imageWidth = $lightbox[0]._$modal.find('.in .img-fluid').width();
            $lightbox[0]._$modal.find('.modal-dialog').css({'max-width': imageWidth});
        }

        function updateLightboxSlideCounter($lightbox) {

            var totalSlides = getTotalSlides($lightbox),
                currentSlide = getCurrentSlide($lightbox),
                $galleryLightboxSlideNumber = $lightbox[0]._$modal.find('.gallery__slide-number');

            if($galleryLightboxSlideNumber.length === 0) {
                injectLightboxSlideCounter($lightbox, currentSlide, totalSlides);
            }
            else {
                updateLightboxSlideNumber($lightbox, currentSlide);
            }
        }

        function getTotalSlides($lightbox) {
            return $lightbox[0]._$galleryItems.length;
        }

        function getCurrentSlide($lightbox) {
            return $lightbox[0]._galleryIndex + 1;
        }

        function injectLightboxSlideCounter($lightbox, currentSlide, totalSlides) {
            var slideCounterHtml = '<span><span class="gallery__slide-number">'+currentSlide+'</span><span class="gallery__slide-number-divider"></span><span class="gallery__total-slides">'+totalSlides+'</span></span>'

            $lightbox[0]._$modal.find('.ekko-lightbox-nav-overlay a:first-child').after(slideCounterHtml);
        }

        function updateLightboxSlideNumber ($lightbox, currentSlide) {
            $lightbox[0]._$modal.find('.gallery__slide-number').text(currentSlide);
        }

        return {
          init : init
        };
    }());

    if($('.gallery').length > 0) {
        PRSapp.Gallery.init();
    }

})(jQuery, ResponsiveBootstrapToolkit);