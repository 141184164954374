/**
 * @namespace Login
 * @memberof PRSapp
 */
(function($) {
    "use strict";

    PRSapp.Login = (function() {
        var init,
            caeChecked = false;

        /**
         * @function init
         * @memberof! PRSapp.Login
         */
        init = function() {
            
            setUpFormValidation();

            preventFocusOnModal();

            setUpSignUpModalSelect();

            setUpSignUpModalLinkHref('');

            if($('#email').val().length > 0) {
                if($('#email').valid()) {
                    var emailAddress = $('#email').val();
                    var data = {
                        'email': emailAddress
                    };
                    PRSapp.Utils.checkEmailForCAE($('#login__form'), data);
                    caeChecked = true;
                }
            }

        };

        /**
         * @function setUpFormValidation
         * @memberof! PRSapp.Login
         */
        function setUpFormValidation() {
            $('#login__form').validate({
                errorClass: 'has-error',
                validClass: 'has-success',
                ignore: ':not(:visible)',
                highlight: function(element, errorClass, validClass) {
                    $(element).parents('.form-group').addClass(errorClass).removeClass(validClass);
                    $(element).parents('.form-group').find('.sprite').removeClass('hidden');
                    if($(element).attr('id') === 'password') {
                        $(element).parents('.form-group').find('.form-control__show-password').hide();    
                    }
                },
                unhighlight: function(element, errorClass, validClass) {
                    $(element).parents('.form-group').addClass(validClass).removeClass(errorClass);
                    $(element).parents('.form-group').find('.sprite').addClass('hidden');
                    if($(element).attr('id') === 'password') {
                        $(element).parents('.form-group').find('.form-control__show-password').show();    
                    }
                },
                onfocusout: function(element) {

                    if($(element).attr('id') === 'email' && $(element).valid()) {
                        caeChecked = false;
                        var emailAddress = $(element).val();
                        var data = {
                            'email': emailAddress
                        };
                        PRSapp.Utils.checkEmailForCAE($('#login__form'), data, false);
                        caeChecked = true;
                    }

                    if($(element).val().length > 0) {
                        $(element).valid();
                    }

                },
                rules: {
                    email: {
                        required: true,
                        email: true
                    },
                    password: {
                        required: true
                    },
                    cae: {
                        required: true
                    }
                },
                messages: {
                    email: {
                        required: 'We\'re missing some info. Please be sure to fill in all the fields.',
                        email: 'This doesn\'t look like an email. Missed a note?'
                    },
                    password: {
                        required: 'We\'re missing some info. Please be sure to fill in all the fields.'
                    },
                    cae: {
                        required: 'We\'re missing some info. Please be sure to fill in all the fields.'
                    }
                },
                submitHandler: function(form) {

                    if($(form).valid() && caeChecked) {
                        form.submit();
                    }
                    else if($(form).valid() && !caeChecked) {
                        var emailAddress = $('#email').val();
                        var data = {
                            'email': emailAddress
                        };
                        PRSapp.Utils.checkEmailForCAE($('#login__form'), data, true);
                        caeChecked = true;
                    }
                    else {
                        return false;
                    }

                }
            });
        }

        /**
         * @function preventFocusOnModal
         * @memberof! PRSapp.Login
         */
        function preventFocusOnModal() {
            var $relatedTarget;

            $('#login__signup-modal, #login__licence-modal, #login__help-modal').on('shown.bs.modal', function(e) {
                $(document).off('focusin.modal');

                $relatedTarget = $(e.relatedTarget);

                toggleModalButtonsVisibility();
            });
            
            $('#login__signup-modal, #login__licence-modal, #login__help-modal').on('hide.bs.modal', function(e) {
                toggleModalButtonsVisibility();
                $relatedTarget.focus();
            });
        }


        /**
         * @function toggleModalButtonsVisibility
         * @memberof! PRSapp.Login
         */
        function toggleModalButtonsVisibility() {
            $('.login-more-info .login-more-info__option-list').toggle();
        };

        /**
         * @function setUpSignUpModalSelect
         * @memberof! PRSapp.Login
         */
        function setUpSignUpModalSelect() {
            $('.signup-modal__select').on('change', function() {
                var newValue = $(this).val();

                if(this.options[this.selectedIndex].innerHTML === 'Other') {
                    $(this).parents('.custom-select__container').find('.custom-select__label').hide();
                }
                else {
                    $(this).parents('.custom-select__container').find('.custom-select__label').show();
                }

                setUpSignUpModalLinkHref(newValue);
            });
        }


        /**
         * @function setUpSignUpModalLinkHref
         * @memberof! PRSapp.Login
         * @param {String} href
         */
        function setUpSignUpModalLinkHref(href) {
            if(href.length <= 0) {
                href = $('.signup-modal__select').val();
            }

            $('.signup-modal__button').attr('href', href);
        }

        return {
          init : init
        };
    }());

    if($('#login_page').length > 0) {
        PRSapp.Login.init();
    }
}(jQuery));