/**
 * @namespace Form steps
 * @memberof PRSapp
 */
(function($, viewport) {
    "use strict";

    PRSapp.Fileupload = (function() {
        /**
         * @function init
         * @memberof! PRSapp.Fileupload
         */
        var $fileName, $fileSize, $fileError, $fileUploadedContainer, $fileUploadContainer;

        var init = function() {
          var $fileUploadWrapper = $('[data-behaviour="fileupload"]');

          if($fileUploadWrapper.length <= 0) return;

          var $uploadField = $fileUploadWrapper.find('.file-upload__field');
          var $removeBtn = $fileUploadWrapper.find('.file-upload__button--remove');

          $fileUploadContainer = $fileUploadWrapper.find('.file-upload__container');
          $fileUploadedContainer = $fileUploadWrapper.find('.file-uploaded__container').hide();

          $fileName = $fileUploadWrapper.find('.file-upload__name');
          $fileSize = $fileUploadWrapper.find('.file-upload__size');
          $fileError = $fileUploadWrapper.find('[data-file-error]');

          var maxFileSize = $uploadField.attr('data-maxsize');

          $uploadField.on('change', function(evt) {
            if(!evt.target.files[0]) return;

            getFileInfo(evt.target.files[0], maxFileSize);
          });

          $removeBtn.on('click', function(e) {
            e.preventDefault();
            resetField($uploadField);
            $fileUploadedContainer.hide();
          });
        };

        function setCopy(name, size) {
          name ? $fileName.text(name) : $fileName.text('');
          size ? $fileSize.text(size) : $fileSize.text('');
        }

        function resetField(e) {
          var form = e.wrap('<form>').parent('form');

          //stop form from validation on reset
          $(form).on('reset', function(evt){
            evt.stopPropagation();
            evt.preventDefault();
          });

          $(form).trigger('reset');

          e.unwrap();
          $fileUploadContainer.show();
          setCopy();
        }

        //returns a human readable file size
        function bytesToSize(bytes) {
          var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
          if (bytes == 0) return 'n/a';
          var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
          if (i == 0) return bytes + ' ' + sizes[i];
          return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
        };

        function getFileInfo(file, maxSize) {
          //check that the file is not too large before continuing
          if(file.size > maxSize) {
            $fileError.text(file.name);
            $fileError.closest('.has-error').show();
            return;
          } else {
            $fileError.closest('.has-error').hide()
          }

          var fileSize = bytesToSize(file.size);
          var fileName = file.name;

          $fileUploadContainer.hide();

          setCopy(fileName, fileSize);
          $fileUploadedContainer.css('display','flex');
        }

        return {
          init : init
        };
    }());

    PRSapp.Fileupload.init();

})(jQuery, ResponsiveBootstrapToolkit);
