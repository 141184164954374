/**
 * @namespace securityQuestion
 * @memberof PRSapp
 */
(function($, viewport) {
    "use strict";

    PRSapp.securityQuestion = (function() {
        var init,
            caeFlag,
            caeChecked = false;

        /**
         * @function init
         * @memberof! PRSapp.securityQuestion
         */
        init = function() {
            
            setUpFormValidation();

            enquire.register("screen and (max-width: 768px)", {
                match: function() {
                    if($('.terms-and-conditions__container').length > 0) {
                        setTermsAndConditionsHeight();
                    }
                }
            });

            if($('.buttons__decline-button').length > 0) {
                $('.buttons__decline-button').on('click', function(e) {
                    e.preventDefault();

                    $('.form__error-box').removeClass('hidden');
                });
            }

            if($('.buttons__print-button').length > 0) {
                $('.buttons__print-button').on('click', function(e) {
                    e.preventDefault();

                    window.print();
                });
            }

            if($('.buttons__accept-button').length > 0) {
                $('.buttons__accept-button').on('click', function(e) {
                    e.preventDefault();

                    $('.terms_accepted').val(1);
                    $('.security-question__terms-and-conditions-form').submit();
                });
            }

        };

        /**
         * @function setUpFormValidation
         * @memberof! PRSapp.securityQuestion
         */
        function setUpFormValidation() {
            $('.security-question__form').validate({
                errorClass: 'has-error',
                validClass: 'has-success',
                ignore: ':not(:visible)',
                highlight: function(element, errorClass, validClass) {
                    $(element).parents('.form-group').addClass(errorClass).removeClass(validClass);
                    $(element).parents('.form-group').find('.sprite').removeClass('hidden');
                },
                unhighlight: function(element, errorClass, validClass) {
                    $(element).parents('.form-group').addClass(validClass).removeClass(errorClass);
                    $(element).parents('.form-group').find('.sprite').addClass('hidden');
                },
                onkeyup: function(element, event) {
                    var questionValueLength = $('.security-question').val().length,
                        answerValueLength = $('.security-answer').val().length;

                    if(questionValueLength >= 6 && answerValueLength >= 6) {
                        $('.security-question__form').find('.btn').prop('disabled', false);
                    }
                    else {
                        $('.security-question__form').find('.btn').prop('disabled', true);
                    }

                    // DEFAULT JQUERY VALIDATE FUNCTION
                    if ( event.which === 9 && this.elementValue( element ) === "" ) {
                        return;
                    } else if ( element.name in this.submitted || element === this.lastElement ) {
                        this.element( element );
                    }
                },
                rules: {
                    security_question: {
                        required: true,
                        minlength: 6
                    },
                    security_answer: {
                        required: true,
                        minlength: 6
                    }
                },
                messages: {
                    security_question: {
                        required: 'We\'re missing some info. Please be sure to fill in all the fields.',
                        minlength: 'Your security question must be at least 6 characters long.'
                    },
                    security_answer: {
                        required: 'We\'re missing some info. Please be sure to fill in all the fields.',
                        minlength: 'Your security answer must be at least 6 characters long.'
                    }
                },
                submitHandler: function(form) {
                    form.submit();
                }
            });
        };

        function setTermsAndConditionsHeight() {
            var topHeight = getTopHeight(),
                bottomHeight = getBottomHeight(),
                pageHeight = getPageHeight();

            var termsAndConditionsHeight = pageHeight - (topHeight + bottomHeight);

            $('.terms-and-conditions').css({'height': termsAndConditionsHeight});
        }

        function getTopHeight() {
            var height;

            height = $('#security_question_template').find('nav').outerHeight(true) + $('#security_question_template').find('article > .row').outerHeight(true);

            return height;
        }

        function getBottomHeight() {
            var height;

            height = $('#security_question_template').find('.buttons__container').outerHeight(true);

            return height;
        }

        function getPageHeight() {
            var height;

            height = $('body').outerHeight(true);

            return height;
        }

        return {
          init : init
        };
    }());

    if($('#security_question_template').length > 0) {
        PRSapp.securityQuestion.init();
    }
}(jQuery, ResponsiveBootstrapToolkit));