(function ($) {
  "use strict";
  PRSapp.distributionUpdate = (function () {

      var config = {};

      function init() {
          config = {
              form: "#distribution-update-form",
              targetUrl : targetUrl
          };
          getUrl();
          bindUIActions();
      };

      var bindUIActions = function () {
        $('#DistCode').on('change', function () {
           getUrl();
        });
      }

      function getUrl(){
        var distCode = $('#DistCode').val();
        var url = '';
        if($('#DistCode').val() == '')
        {
          $("#distUrl").addClass('disabled');
        }
        else
        {
          $("#distUrl").removeClass('disabled');
          url = targetUrl + "?d=" + distCode ;
        }
        $("#distUrl").attr("href", url)
      }

      return {
          init: init
      };

  }());

  if ($("#distribution-update-form").length > 0) {
      PRSapp.distributionUpdate.init();

  }

}(jQuery));


