/**
 * @namespace GettingStarted
 * @memberof PRSapp
 */
(function ($, _window) {
  PRSapp.GettingStarted = (function () {
    // const CORRECTION = 45; // adjstment for padding on mobile
    const DELAY_SCROLLING = 250;

    let links; //= [ '#royalties-create', '#royalties-register', '#royalties-report', '#royalties-collect', '#royalties-match', '#royalties-to-you' ];
    let isScrolling = false;
    let currentIndex = 0;
    let cardsVisible = 3;

    /**
         * @function init
         * @memberof! PRSapp.GettingStarted
         */
    const init = () => {
      setUpLinkReferences();
      setupScrollSpy();
      setupScrollButtons();
      setUpMoreLink();
    };

    const isMobile = () => $('.visible-sm').is(':visible');
    const isTabletOrMobile = () => $('.visible-md').is(':visible');

    const disableButton = (id) => {
      $(id).removeClass('active');
    };

    const activateButton = (id) => {
      $(id).addClass('active');
    };

    const updateButtonsStates = (index) => {
      if (index + cardsVisible >= links.length) {
        disableButton('#arrow-right');
      } else {
        activateButton('#arrow-right');
      }

      if (index === 0) {
        disableButton('#arrow-left');
      } else {
        activateButton('#arrow-left');
      }
    };

    const scrollToLinkNew = (index, isReverse) => {
      if (index === 0) {
        $('.royalties-container').stop().animate({ scrollLeft: 0 }, DELAY_SCROLLING);
        return;
      }
      const left = isReverse ? index * -396 : index * 396;
      $('.royalties-container').stop().animate({ scrollLeft: $('.royalties-container').scrollLeft() + left }, DELAY_SCROLLING);
    };

    const scrollCards = () => {
      if (isScrolling || (currentIndex + cardsVisible) >= links.length) return;
      isScrolling = true;
      currentIndex += cardsVisible;
      if (currentIndex > links.length) currentIndex = links.length;
      scrollToLinkNew(currentIndex, false);
      updateButtonsStates(currentIndex);
      isScrolling = false;
    };

    const scrollCardsBack = () => {
      if (isScrolling || currentIndex === 0) return;
      isScrolling = true;
      currentIndex -= cardsVisible;
      if (currentIndex < 0) currentIndex = 0;
      scrollToLinkNew(currentIndex, true);
      updateButtonsStates(currentIndex);
      isScrolling = false;
    };

    const setupScrollButtons = () => {
      activateButton('#arrow-right');
      $('#arrow-left').on('click', scrollCardsBack);
      $('#arrow-right').on('click', scrollCards);
      cardsVisible = isTabletOrMobile() ? 2 : 3;
    };

    const setUpMoreLink = () => {
      const correction = _window.innerHeight / 2;
      const arrowPosition = $('#arrow-down').offset().top - correction;
      $('#arrow-down').on('click', () => {
        $('.below-fold').css({ display: 'flex' });
        $('.above-fold').css({ display: 'none' });
      });

      $('#arrow-up').on('click', () => {
        $('.below-fold').css({ display: 'none' });
        $('.above-fold').css({ display: 'flex' });
        _window.scrollTo(0, arrowPosition);
      });
    };

    const setupScrollSpy = () => {
      $('body').scrollspy({ target: '#get-start-nav' });
    };

    const setUpLinkReferences = () => {
      links = $('.royalties-card').map(() => `#${this.id}`);
    };

    return {
      init,
    };
  }());

  if ($('#getting-started-page').length > 0) {
    PRSapp.GettingStarted.init();
  }
// eslint-disable-next-line no-undef
}(jQuery, window));
