(function ($) {
    "use strict";
    PRSapp.manageAccessRequest = (function () {

        var config = {};

        function setFormsValidation() {
            var rules = {
                RejectionNotes: {
                    required: true,
                    maxlength: 500
                }
            };

            var messages = {
                RejectionNotes: {
                    required: 'This field is required',
                    maxlength: 'Maximum 500 characters are allowed'
                }
            };

            var isValid = $(config.form).validate({
                errorClass: 'has-error',
                validClass: 'has-success',
                ignore: ':not(:visible)',
                highlight: function (element, errorClass, validClass) {
                    $(element).parents('.form-group').addClass(errorClass).removeClass(validClass);
                    $(element).parents('.form-group').find('.sprite').removeClass('hidden');
                },
                unhighlight: function (element, errorClass, validClass) {
                    $(element).parents('.form-group').addClass(validClass).removeClass(errorClass);
                    $(element).parents('.form-group').find('.sprite').addClass('hidden');
                },
                errorPlacement: function (error, element) {
                    error.insertAfter(element);
                },
                rules: rules,
                messages: messages
            });
        }

        function init() {

            config = {
                approveButton: "#approve",
                rejectButton: "#reject",
                rejectConfirm: "#reject-confirm",
                rejectConfirmNotes: "#reject-confirm-notes",
                cancel: "#cancel",
                form: "#manage-access-request-form",
                approveMessage: approveMessage,
                rejectMessage: rejectMessage

            };

            setFormsValidation();
            bindUIActions();
        };

        var bindUIActions = function () {

            $(config.approveButton).click(function (e) {
                e.preventDefault();
                return iamConfirmSwitchProfile(config.approveMessage, "", function (result) {
                    if (result) {
                        $("#action").val("GIVE");
                        $(config.form).submit();
                    }
                    else {
                        return false;
                    }
                });

            });
            $(config.rejectButton).click(function (e) {
                e.preventDefault();
                $("#RejectionNotes").val($("#reject-notes-modal").val());
                //alert($("#RejectionNotes").val());
                $("#action").val("REJECT");
                $(config.form).submit();
            });

            $(config.rejectConfirm).click(function (e) {
              e.preventDefault();
              $(config.rejectConfirmNotes).wrap("<div class='iam-modal'></div>");
                $(config.rejectConfirmNotes).css("display", "block");
                return false;
            });

            $(config.cancel).click(function (e) {
                e.preventDefault();
                $(config.rejectConfirmNotes).css("display", "none");
                return false;
            });
            $('body').on("click", "#manage-access-modal-close", function () {
                $("#reject-confirm-notes").css("display", "none");
            });
        }

        return {
            init: init
        };

    }());

    if ($("#manage-access-request-form").length > 0) {
        PRSapp.manageAccessRequest.init();

    }

}(jQuery));


