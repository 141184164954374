/**
 * @namespace Form steps
 * @memberof PRSapp
 * Used for Raise a Query form
 */
(function($, viewport) {
    "use strict";

    PRSapp.Formsteps = (function() {
        var $formStepsWrapper, $formSteps, $select, $queryHelp, $queryLabel, $queryField, $fileField, defaultQueryHelp, defaultPlaceholder, defaultQueryLabel, defaultFileHelp, $fileUploadWrapper, $queryFieldWrapper;
        /**
         * @function init
         * @memberof! PRSapp.Formsteps
         */

        var completedClass = 'step-completed';

        var init = function() {

          $formStepsWrapper = $('[data-behaviour="form-steps"]');

          if($formStepsWrapper.length <= 0) return;

          $queryHelp = $formStepsWrapper.find('#query-help');
          $queryLabel = $formStepsWrapper.find('#query-label');
          $queryField = $formStepsWrapper.find('#query-field');
          $fileField = $formStepsWrapper.find('#file-help');

          defaultQueryHelp = $queryHelp.attr('data-default');
          defaultQueryLabel = $queryLabel.attr('data-default');
          defaultPlaceholder = $queryField.attr('data-default');
          defaultFileHelp = $fileField.attr('data-default');

          $fileUploadWrapper = $('[data-behaviour="fileupload"]').closest('.form-group--full');
          $queryFieldWrapper = $queryHelp.closest('.form-group--full');

          $formSteps = $formStepsWrapper.find('[data-behaviour="form-step"]');

          $select = $formStepsWrapper.find('select');

          setUpSteps();
        };


        function updateCopy(help, placeholderCopy, querylabel, fileHelp) {
          var fileHelpCopy = $.parseHTML(fileHelp);
          var fieldHelpCopy = $.parseHTML(help);

          help ? $queryHelp.text('').append(fieldHelpCopy) : $queryHelp.text(defaultQueryHelp);
          fileHelp ? $fileField.text('').append(fileHelpCopy) : $fileField.text(defaultFileHelp);
          querylabel ? $queryLabel.text(querylabel) : $queryLabel.text(defaultQueryLabel);
          placeholderCopy ? $queryField.attr('placeholder', placeholderCopy) : $queryField.attr('placeholder', defaultPlaceholder);
        }

        function toggleFields(fileUpload, queryField) {
          fileUpload === "true" ? $fileUploadWrapper.hide() : $fileUploadWrapper.show();
          queryField === "true" ? $queryFieldWrapper.hide() : $queryFieldWrapper.show();
        }

        function onChange(evt, step, i) {
          var parentId;

          //is it a dropdown?
          if(evt.target.options) {
            parentId = evt.target.value;
            toggleFields("true", "true");
            updateCopy();
          }

          //it is radio buttons
          if(evt.target.checked) {
            $(step).addClass(completedClass);

            var helpcopy = evt.target.getAttribute('data-helpcopy');
            var fileHelpcopy = evt.target.getAttribute('data-filehelpcopy');
            var querylabel = evt.target.getAttribute('data-querylabel');
            var placeholder = evt.target.getAttribute('data-placeholder');
            var hideFileupload = evt.target.getAttribute('data-hide-fileupload');
            var hideQueryField = evt.target.getAttribute('data-hide-query');


            toggleFields(hideFileupload, hideQueryField);
            updateCopy(helpcopy, placeholder, querylabel, fileHelpcopy);
          }

          showStep(i, (i + 1), parentId);
        }

        function setUpSteps() {
          var previousStep;
          $formSteps.each(function(i, step){
              $(step).on('change', function(evt) {
                  onChange(evt, step, i);
              });

              //Hide all steps but the first on load
              if(i > 0) {
                //hide step is previous step has not been completed
                if(!previousStep.hasClass(completedClass)) {
                  $(step).hide();
                }

                //if it contains a dropdown
                var prevSelected = previousStep.find('select');
                if(previousStep.find('select').length > 0) {
                  var topic = prevSelected[0].selectedOptions[0].value;
                  $(step).find("[data-parent]").hide();

                  if(topic.length > 0) {
                    //hide all subtopics
                    $(step).find("[data-parent=" + topic + "]").show();
                  }
                }

              }
              previousStep = $(step);
          });
        }

        function showStep(prevStep, stepToShow, parentId) {
          //check if next step has radio buttons, if so, uncheck them.
          var radiobuttons = $($formSteps[stepToShow]).find("[type=radio]");
          if(radiobuttons) {
            radiobuttons.each(function(i, btn) {
              $(btn).prop('checked', false);
            });
          }


          $($formSteps[stepToShow]).show().removeClass(completedClass);
          $($formSteps[stepToShow]).find("[data-parent]").hide();
          $($formSteps[stepToShow]).find("[data-parent=" + parentId + "]").show();
        }

        return {
          init : init
        };
    }());

    PRSapp.Formsteps.init();

})(jQuery, ResponsiveBootstrapToolkit);
