/**
 * @namespace ErrorPage
 * @memberof PRSapp
 */
(function($) {
    "use strict";

    PRSapp.ErrorPage = (function() {
        var init,
            caeFlag,
            caeChecked = false;

        /**
         * @function init
         * @memberof! PRSapp.ErrorPage
         */
        init = function() {
            
            setUpBackButtonClick();

        };

        function setUpBackButtonClick() {
            if($('.error-page__btn--back').length > 0) {
                $('.error-page__btn--back').on('click', function() {
                    window.history.back();
                });
            }
        };

        return {
          init : init
        };
    }());

    if($('#error_page').length > 0) {
        PRSapp.ErrorPage.init();
    }
}(jQuery));