(function ($) {
  "use strict";
  PRSapp.requestPermission = (function () {
    var config = {};

    function init() {
      config = {
        button: "#approve",
        requestUrl: requestPermissionsUrl,
        form: "#request-permissions-form",
        confirmMessage: confirmMessage,
        confirmMessageSubTitle: confirmMessageSubTitle

      };
      bindUIActions();
    };


    var bindUIActions = function () {
      onConfirmChanges();
    }

    var onConfirmChanges = function () {
      $("#request-permissions-form").prev().remove();
      var selectedCheckBoxes = $("#request-permissions-form").find("input:checked");
      if (selectedCheckBoxes.length < 1) { $(button).attr("disabled", true); }
      
      $(config.button).click(function (e) {
        e.preventDefault();
        var button = $(this);
        var url = $(this).attr("href");
        var checkBoxes =  $("#request-permissions-form").find("input:checkbox").not("input[name ='isadmin']");
        var isAdmin = $("#request-permissions-form").find("input[name ='isadmin']:checked").length > 0 ? true : false;
        config.requestedAppRoles = [];

          $.each(checkBoxes, function (key, value) {
            var appRoleId = $(value).attr("value");
            var checked = $(value).is(':checked');
            config.requestedAppRoles.push(appRoleId + '-' + checked);
          });
      
      
        $("#approve").attr("disabled", true);
        var model = {
          'ProfileId': $("#ProfileId").val(),
          'RequestedAppRoles': config.requestedAppRoles,
          'isAdmin': isAdmin,
          'SaveUrl': config.requestUrl
        }

        return iamConfirmSwitchProfile(config.confirmMessage, config.confirmMessageSubTitle, function (result) {
          if (result) {
            $.ajax({
              url: url,
              type: 'POST',
              data: { 'requestPermissionsModel': model },
              success: function (data, textStatus, xhr) {
                $("#message").show();
                $("html, body").animate({ scrollTop: 0 }, "slow");
                $("#approve").attr("disabled", false);
              },
              error: function (xhr) {
                alert(xhr.statusText);
                $("#approve").attr("disabled", false);
              }
            });
          }
          else {
            return false;
          }
        });

      });
    };

    return {
      init: init
    };

  }());

  if ($("#request-permissions-form").length > 0) {
    PRSapp.requestPermission.init();
  }
}(jQuery));