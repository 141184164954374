(function ($) {
  "use strict";
  PRSapp.requestPseudonym = (function () {
    var config = {};

    function init() {
      config = {
        button: "#pseudonym-submit",
        form: "#pseudonym-form",
        
      };
      bindUIActions();
    };


    var bindUIActions = function () {
      onConfirmChanges();
    }

    var onConfirmChanges = function () {

      var rules = {
        PseudonymSpecialInstructions: {
          required: true,
          maxlength: 1000
         }
      };

      var messages = {       
        PseudonymSpecialInstructions: {
          required: 'This field is required',
          maxlength: 'Maximum 1000 characters are allowed',
         }
      };

      var isValid = $(config.form).validate({
        errorClass: 'has-error',
        validClass: 'has-success',
        ignore: ':not(:visible)',
        highlight: function (element, errorClass, validClass) {
          $(element).parents('.form-group').addClass(errorClass).removeClass(validClass);
          $(element).parents('.form-group').find('.sprite').removeClass('hidden');
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).parents('.form-group').addClass(validClass).removeClass(errorClass);
          $(element).parents('.form-group').find('.sprite').addClass('hidden');
        },
        errorPlacement: function (error, element) {
          error.insertAfter(element);
          
        },
        rules: rules,
        messages: messages
      });

      $('#confirmation').hide();
      $('#message').hide();
      $(config.button).click(function (e) {
        e.preventDefault();
        if ($(config.form).valid()) {
        var button = $(this);
        var url = $(this).attr("href");
        var token = $('input[name="__RequestVerificationToken"]').val();
        var instructionsText =  $("#spl-ins").val();
        if (instructionsText.length <= 0) {
          return;
        }
        

        $(config.button).attr("disabled", true);       
        $.ajax({
          url: url,
          type: 'POST',
          data: { 'instructions': instructionsText,  '__RequestVerificationToken' : token },
          contentType: 'application/x-www-form-urlencoded; charset=utf-8',
          success: function (data, textStatus, xhr) {
            $('#confirmation').show();
            $('#message').hide();
            $('#pseudonym-controls').hide();
          },
          error: function (xhr) {
            alert(xhr.statusText);
            $('#message').show();
            $('#pseudonym-controls').show();
            $('#confirmation').hide();
            $(config.button).attr("disabled", false); 
          }
        });
      }

      });
    
    };

    return {
      init: init,
      
    };

  }());

  if ($("#pseudonym-form").length > 0) {
    PRSapp.requestPseudonym.init();
   
  }
}(jQuery));