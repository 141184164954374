/**
 * @namespace forgottenPassword
 * @memberof PRSapp
 */
(function($) {
    "use strict";

    PRSapp.forgottenPassword = (function() {
        var init,
            caeFlag,
            caeChecked = false;

        /**
         * @function init
         * @memberof! PRSapp.forgottenPassword
         */
        init = function() {
            
            setUpFormValidation();

            if($('#email').val().length > 0) {
                if($('#email').valid()) {
                    var emailAddress = $('#email').val();
                    var data = {
                        'email': emailAddress
                    };
                    PRSapp.Utils.checkEmailForCAE($('.forgotten-password__form'), data);
                    caeChecked = true;
                }
            }

        };

        /**
         * @function setUpFormValidation
         * @memberof! PRSapp.forgottenPassword
         */
        function setUpFormValidation() {
            $('.forgotten-password__form').validate({
                errorClass: 'has-error',
                validClass: 'has-success',
                ignore: ':not(:visible)',
                highlight: function(element, errorClass, validClass) {
                    $(element).parents('.form-group').addClass(errorClass).removeClass(validClass);
                    $(element).parents('.form-group').find('.sprite').removeClass('hidden');
                    if($(element).attr('id') === 'password') {
                        $(element).parents('.form-group').find('.form-control__show-password').hide();    
                    }
                },
                unhighlight: function(element, errorClass, validClass) {
                    $(element).parents('.form-group').addClass(validClass).removeClass(errorClass);
                    $(element).parents('.form-group').find('.sprite').addClass('hidden');
                    if($(element).attr('id') === 'password') {
                        $(element).parents('.form-group').find('.form-control__show-password').show();    
                    }
                },
                onfocusout: function(element) {

                    if($(element).attr('id') === 'email' && $(element).valid()) {
                        caeChecked = false;
                        var emailAddress = $(element).val();
                        var data = {
                            'email': emailAddress
                        };
                        PRSapp.Utils.checkEmailForCAE($('#login__form'), data, false);
                        caeChecked = true;
                    }

                    if($(element).val().length > 0) {
                        $(element).valid();
                    }

                },
                rules: {
                    email: {
                        required: true,
                        email: true
                    },
                    cae: {
                        required: true
                    }
                },
                messages: {
                    email: {
                        required: 'We\'re missing some info. Please be sure to fill in all the fields.',
                        email: 'This doesn\'t look like an email. Missed a note?'
                    },
                    cae: {
                        required: 'We\'re missing some info. Please be sure to fill in all the fields.'
                    }
                },
                submitHandler: function(form) {

                    if($(form).valid() && caeChecked) {
                        
                        $.post('/api/account/forgotpassword', $(form).serialize(), function() {
                            showForgottenPasswordSuccessMessage();
                            PRSapp.Utils.triggerBackToTop();
                        })
                        .fail( function() {
                            showForgottenPasswordSuccessMessage();
                            PRSapp.Utils.triggerBackToTop();
                        });
                        
                    }
                    else if($(form).valid() && !caeChecked) {
                        var emailAddress = $('#email').val();
                        var data = {
                            'email': emailAddress
                        };
                        PRSapp.Utils.checkEmailForCAE($('#login__form'), data, true);
                        caeChecked = true;
                    }
                    else {
                        return false;
                    }

                }
            });
        };

        function showForgottenPasswordSuccessMessage() {
            $('.forgotten-password__success').removeClass('hidden');
        }

        return {
          init : init
        };
    }());

    if($('.forgotten-password__form').length > 0) {
        PRSapp.forgottenPassword.init();
    }
}(jQuery));