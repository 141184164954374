/**
 * @namespace Reference
 * @memberof PRSapp
 */
(function($, viewport) {
    "use strict";

    PRSapp.Reference = (function() {
        var init,
            bloodhoundList;

        /**
         * @function init
         * @memberof! PRSapp.Reference
         */
        init = function() {

            setUpTypeahead();

        };

        function getSearchList($parent) {
            return $parent.find('.reference__typehead-data').val().split(','); 
        }

        function setUpBloodhound(searchList) {
            // constructs the suggestion engine
            return new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.whitespace,
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                // `countries` is an array of state names defined in "The Basics"
                local: searchList
            });
        }

        function setUpTypeahead() {

            $('.typeahead').each(function() {
            
                var $parent = $(this).parents('.reference__container'),
                    searchList = getSearchList($parent),
                    bloodhoundList = setUpBloodhound(searchList);

                $(this).typeahead({
                    hint: true,
                    highlight: true,
                    minLength: 1
                },
                {
                    source: bloodhoundList,
                    limit: 5
                });

                $(this).bind('typeahead:render', function(event) {
                    var searchTerm = event.target.value,
                        suggestions = Array.prototype.slice.call(arguments, 1);

                    $parent.find('.reference__data-item').hide();

                    $.each(suggestions, function(key, suggestion) {
                        $parent.find('.reference__data-item[data-name="'+suggestion+'"]').show();

                        var $title = $('.reference__data-item[data-name="'+suggestion+'"]').find('.reference__data-item-title'),
                            plainTitleText = $title.html().replace(/(<([^>]+)>)/ig,""),
                            regularExpression = new RegExp(searchTerm, 'i'),
                            highlightedTitleText = plainTitleText.replace(regularExpression, function(match) {
                                return '<span>' + PRSapp.Utils.matchCase(searchTerm, match) + '</span>';
                            });

                        $title.html(highlightedTitleText);
                    });
                });

                $(this).on('typeahead:select', function(event, suggestion) {
                    $parent.find('.reference__data-item').hide();
                    $parent.find('.reference__data-item[data-name="'+suggestion+'"]').find('.reference__data-item-title').html($('.reference__data-item[data-name="'+suggestion+'"]').find('.reference__data-item-title').html().replace(/(<([^>]+)>)/ig,""));
                    $parent.find('.reference__data-item[data-name="'+suggestion+'"]').show();
                });

                $(this).on('keyup', function(event) {
                    if(event.target.value === '') {
                        $parent.find('.reference__data-item').each(function() {
                            $(this).find('.reference__data-item-title').html($(this).find('.reference__data-item-title').html().replace(/(<([^>]+)>)/ig,""));
                            $(this).show();
                        });
                    }
                });
            });

            
        }

        return {
          init : init
        };
    }());

    if($('.reference__container').length > 0) {
        PRSapp.Reference.init();
    }

})(jQuery, ResponsiveBootstrapToolkit);