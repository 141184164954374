/**
 * @namespace searchResults
 * @memberof PRSapp
 */
(function($, viewport) {
    "use strict";

    PRSapp.searchResults = (function() {
        var init,
            caeFlag,
            caeChecked = false;

        /**
         * @function init
         * @memberof! PRSapp.searchResults
         */
        init = function() {
            
            setUpTryAgain();

        };

        function setUpTryAgain() {
            $('.search-results__try-again').find('.btn').on('click', function() {
                PRSapp.Utils.triggerBackToTop();
                
                $('.navbar__search-bar:visible').focus();
            });
        }

        return {
          init : init
        };
    }());

    if($('#content_page.search-results').length > 0) {
        PRSapp.searchResults.init();
    }
}(jQuery, ResponsiveBootstrapToolkit));