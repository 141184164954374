/**
 * @namespace EmbedTweet
 * @memberof PRSapp
 */
(function ($, twttr) {
    "use strict";

    PRSapp.EmbedTweet = (function () {
        var init;

        /**
         * @function init
         * @memberof! PRSapp.EmbedTweet
         */
        init = function () {
            initEmbedTweet();
        };

        function initEmbedTweet() {
            twttr.ready(() => {            
                $('div[embed-tweet]').each(function (iter, data) {
                    var tweetId = $(data).attr('embed-tweet').replace(/.+[\/]([0-9]+)$/, '$1');   
                    twttr.widgets.createTweet(
                    tweetId,
                    data);            
                })
            });
        }

        return {
            init: init
        };
    }());

    if ($('div[embed-tweet]').length > 0) {
        PRSapp.EmbedTweet.init();
    }

})(jQuery, twttr);
