(function ($) {
    "use strict";
    PRSapp.sitecoreForms = (function () {
  
      var config = {
        container: $("#Edit-Panel"),
        buttonText: ""
      }
      var init = function () {
        bindUIActions();
      }             
      var bindUIActions = function () {

        $.validator.setDefaults({
            showErrors: function (errorMap, errorList) {
                $.each(this.currentElements, function() {
                  var parent = $(this).parent();
                  parent.removeClass("form-group-error");
                });
                var errors = errorList.length;
                if (errors) {
                    $.each(errorMap, function(key, value) {
                        var parent = $('[name="' + key + '"]').parent();
                        parent.addClass("form-group-error");
                    });                    
                }                
                 this.defaultShowErrors();
             }
         });        
               
      } 
  
      return {
        init: init
      };
  
    }());
    if ($("#feedback-form").length > 0) {
      PRSapp.sitecoreForms.init();    
    }
  }(jQuery));