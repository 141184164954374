(function ($) {
    "use strict";
    PRSapp.switchProfile = (function () {

        var config = {};

        var init = function () {
            config = {
                confirmMessage: switchProfileConfirmMessage,
                defaultAccountConfirmMessage : defaultAccountConfirmMessage,
                subTitle: switchProfileSubtitle,
				overlayLoadingTextId: "#overlay-text",
                removeProfileLoadingText: "Switching your account",
                setDefaultAccountLoadingText: "Setting default account",
                form: $('#switch-profile')
            };
            bindUIActions();
            setProfileOrder();
            searchProfileResults();
            SetDefaultAccount();
        };

        var bindUIActions = function () {

            $('body').on('click', 'a.change-profile', function (e) {				
                e.preventDefault();
                var me = $(this);
                return iamConfirmSwitchProfile(config.confirmMessage, config.subTitle || null, function (result) {
                    if (result) {
						$(config.overlayLoadingTextId).text(config.removeProfileLoadingText);	
                        $('body').addClass("switching-profile");
                        $("#SelectedProfileId").val($(me).attr('profileId'));
                        config.form.submit();
                    }
                    else {
                        return false;
                    }
                });
          }); 
                  
          
        }

        function SetDefaultAccount() {
             $(".set-default").click(function (e) {
            e.preventDefault();
              var me = $(this);	
               return iamConfirmSwitchProfile(config.defaultAccountConfirmMessage, "" || null, function (result) {
                if (result) {
                    $(config.overlayLoadingTextId).text(config.setDefaultAccountLoadingText);	
                    $('body').addClass("switching-profile");
                        var currentDefault = $("a.set-default-disabled");
                        var profileId = $(me).attr('pId');
                        var returnUrl = $("input[name=returnUrl]").attr('value');
                        $.ajax({
                            type: "POST",
                            url: "api/Sitecore/Profile/SetDefaultAccount", 
                            data: { pId: profileId, returnUrl : returnUrl },         
                            dataType: 'html',
                            success: function (response) {
                             $(config.overlayLoadingTextId).text('');
                             var currentProfileId = $('.card_circle_heart img[pId]').attr('pId');
                             if(profileId == currentProfileId) {
                                $('.card_circle_heart img[pId]').attr("src","/images/iam/heart-filled.svg");
                             }
                             else{
                                $('.card_circle_heart img[pId]').attr("src","/images/iam/heart.svg");
                             }
                            
                             $(me).removeClass('set-default');                            
                             $(me).addClass('set-default-disabled');
                             $(me).children('.btn-set-default').children('img').attr("src","/images/iam/heart-filled.svg");
                             $(me).children('.btn-set-default').get(0).lastChild.nodeValue = 'Default account';
                             $(currentDefault).removeClass('set-default-disabled');
                            $(currentDefault).addClass('set-default');
                             $(currentDefault).children('.btn-set-default').children('img').attr("src","/images/iam/heart.svg")
                             $(currentDefault).children('.btn-set-default').get(0).lastChild.nodeValue = 'Set as default account';
                             $('body').removeClass("switching-profile");
                            },
                            error: function (response) {
                                alert('set default error occured');
                               
                              }
                        }) 
                    }
                    else {
                        return false;
                    } 
                });
          });   
        }

        function setProfileOrder() {
            var sortOrder =0;
            $(".sort_button").click(function (e) {
            if(sortOrder==0){
                  $(this).html("A-Z");  
                  sortOrder=1;
                  
            }
            else
            {
                  $(this).html("Z-A");
                  sortOrder=0;
            }
                  $('#user-profiles-section').reverseChildren();                   
                  
                return;
             });
        }

        $.fn.reverseChildren = function() {
            return this.each(function(){
              var $this = $(this);
              $this.children().each(function(){
                $this.prepend(this);
              });
            });
          };
          
          $.fn.ignore = function(sel){
            return this.clone().find(sel||">*").remove().end();
          };

        function searchProfileResults() {
            $('#CAESearch').on('keyup',function() {
                var caeornameQuery = $(this).val().toLowerCase(); 
                $("#user-profiles-section div.col-sm-4").filter(function() {
                    $(this).toggle($(this).text().toLowerCase().indexOf(caeornameQuery) > -1)
                  });
            });
        }

        return {
            init: init
        };
    }());

    if ($("#switch-profile").length > 0) {
        PRSapp.switchProfile.init();
    }

}(jQuery));