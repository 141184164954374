(function ($) {
    "use strict";
    PRSapp.amendUser = (function () {
        var config = {
            confirmChangesUrl: "/api/sitecore/ManageUsers/SubmitAmendUser",
            selectedAppRoles: []

        };

        var init = function () {
            bindUIActions();
        };

        var bindUIActions = function () {
            onConfirmChanges();
        }

        var onConfirmChanges = function () {

            $("#submit-amend-user-permissions").click(function () {
                var button = $(this);
                var url = $(this).attr("action-url");
                var selectedCheckBoxes = $("#user-app-roles").find("input:checked");
                config.selectedAppRoles = [];

                if (selectedCheckBoxes.length > 0) {
                    $.each(selectedCheckBoxes, function (key, value) {
                        var appRoleId = $(value).attr("value");
                        config.selectedAppRoles.push(appRoleId);
                    });

                    $(button).attr("disabled", true);
                    $.ajax({
                        url: url,
                        type: 'POST',
                        data: { profileId: $("#ProfileId").val(), selectedAppRoles: config.selectedAppRoles },
                        success: function (data, textStatus, xhr) {
                            $("#message").text(xhr.statusText);
                            $("html, body").animate({ scrollTop: 0 }, "slow");
                            $(button).attr("disabled", false);

                        },
                        error: function (xhr) {
                            alert(xhr.statusText);
                            $(button).attr("disabled", false);
                        }
                    });
                }
            });
        };

        return {
            init: init
        };

    }());

    if ($("#amend-user-permissions").length > 0) {
        PRSapp.amendUser.init();
    }
}(jQuery));