(function ($) {
    "use strict";
    PRSapp.signUpMember = (function () {

        var init = function () {
            bindUIActions();
        };

        function setFormsValidation() {
            var rules = {
                MembershipNumber: {
                    required: true,
                    maxlength: 11,
                    number: true
                },
                Comments: {
                    required: true,
                    maxlength: 500
                }
            };

            var messages = {
                MembershipNumber: {
                    required: 'This field is required',
                    maxlength: 'Maximum 11 characters are allowed'
                },
                Comments: {
                    required: 'This field is required',
                    maxlength: 'Maximum 100 characters are allowed'
                }
            };

            var isValid = $('#request-access-form').validate({
                errorClass: 'has-error',
                validClass: 'has-success',
                ignore: ':not(:visible)',
                highlight: function (element, errorClass, validClass) {
                    $(element).parents('.form-group').addClass(errorClass).removeClass(validClass);
                    $(element).parents('.form-group').find('.sprite').removeClass('hidden');
                },
                unhighlight: function (element, errorClass, validClass) {
                    $(element).parents('.form-group').addClass(validClass).removeClass(errorClass);
                    $(element).parents('.form-group').find('.sprite').addClass('hidden');
                },
                errorPlacement: function (error, element) {
                    error.insertAfter(element);
                },
                rules: rules,
                messages: messages
                //submitHandler: function (form) {
                //    alert("Hello");
                //    if ($(form).valid()) {
                //        //form.submit();
                //        $.ajax({
                //            type: "post",
                //            url: form.action,
                //            data: form.serialize(),
                //            success: function () { alert("S"); },
                //            error: function () { }
                //        });
                //        return false;
                //    }
                //    else {
                //        return false;
                //    }
                //}
            });

        }

        var bindUIActions = function () {            
            $("#request-access-btn").click(function (e) {
                var button = $(this);
                var form = $("#request-access-form");
                if ($(form).valid()) {
                    $(button).attr("disabled", true);
                    $.ajax({
                        type: "post",
                        url: $(form).attr('action'),
                        data: $(form).serialize(),
                        success: function (data, textStatus, xhr) { $(button).removeAttr("disabled"); $("#message").text(xhr.statusText); },
                        error: function (xhr) {
                            $(button).removeAttr("disabled");
                            $("#message").text(xhr.statusText);
                        }
                    });
                }
            });
        }


        return {
            init: init,
            setFormsValidation: setFormsValidation,
        };

    }());

    if ($("#signup-member-form") != 'undefined') {

        PRSapp.signUpMember.init();
        PRSapp.signUpMember.setFormsValidation();

    }

}(jQuery));