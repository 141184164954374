/**
 * @namespace BlockedContent
 * @memberof PRSapp
 */
(function($, viewport) {
    "use strict";

    PRSapp.BlockedContent = (function() {
        var init;

        /**
         * @function init
         * @memberof! PRSapp.BlockedContent
         */
        init = function() {
            setCookieConsentButtonClickEvent();
            showBlockedContentMessage();
            setTimeout(function () {
              showBlockedContentMessageDelay()
            }, 3000);
        };
 

        /*function resizeIframe() {    
            this.style.height = (this.contentWindow.document.documentElement.scrollHeight + 5) + 'px';
          };*/  
          
        function updatePreferences(event) {    
            var x = document.getElementById("teconsent");
            x.querySelector("a").click();
            event.preventDefault();
        };    
        
        function setCookieConsentButtonClickEvent() {  

            document.querySelectorAll('.cookie-button-panel').forEach((blockedContentComponent)=> {
                if(blockedContentComponent != null)
                {
                    blockedContentComponent.addEventListener("click", updatePreferences, false);  
                } 
            });     
        }            

        function showBlockedContentMessage() {  
        
                var blockedContentMessage = document.querySelector('.blocked-content');
                if(blockedContentMessage != null)
                {
                    //show the PRS blocked content for each instagram placeholder
                    document.querySelectorAll('.instagram-content-placeholder').forEach((instagramPlaceholder)=> {
                        var parentRow =  instagramPlaceholder.closest(".instagram-media");
                        parentRow.removeAttribute("style");
                        parentRow.classList.add("block-no-styling");
                        let panelInfoHTML = blockedContentMessage.cloneNode(true);
                        panelInfoHTML.querySelector('.update-preferences').addEventListener("click", updatePreferences, false);                          
                        instagramPlaceholder.replaceWith(panelInfoHTML);
                    });

                    //show the PRS blocked content for each instagram placeholder
                    document.querySelectorAll('.instagram-media').forEach((instagramPlaceholder)=> {
                        instagramPlaceholder.removeAttribute("style");
                        instagramPlaceholder.classList.add("block-no-styling");
                        let panelInfoHTML = blockedContentMessage.cloneNode(true);
                        panelInfoHTML.querySelector('.update-preferences').addEventListener("click", updatePreferences, false);                          
                        instagramPlaceholder.innerHTML = '';
                        instagramPlaceholder.appendChild(panelInfoHTML);
                    });                    

                    document.querySelectorAll('iframe').forEach((iframe)=> {
                        if(iframe.getAttribute('data-ta-blocked') == 1) {
                            let panelInfoHTML = blockedContentMessage.cloneNode(true);

                            var parentRow =  iframe.closest(".row") ;
                            if(parentRow != null){
                                var newRow = document.createElement("div");
                                newRow.className = "row";
                                var newRowChild = document.createElement("div");
                                newRowChild.className = "col-xs-12 col-sm-8 col-sm-offset-2";
                                newRowChild.append(iframe);
                                newRow.append(newRowChild);
                                parentRow.replaceWith(newRow);
                            };
                            panelInfoHTML.querySelector('.update-preferences').addEventListener("click", updatePreferences, false);     
                           
                            iframe.replaceWith(panelInfoHTML);
                        }
                    });
                }                  

        };

        function showBlockedContentMessageDelay() {   

          var blockedContentMessage = document.querySelector('.blocked-content');
          if(blockedContentMessage != null)
          {  
            document.querySelectorAll('video.embed-responsive-item').forEach((brightcoveVideo)=> {
              //brightcove video component  
              var videoContainer = brightcoveVideo.parentNode;
              if(videoContainer.querySelector('div.vjs-poster') == null) {
                  videoContainer.innerHTML = "";
                  videoContainer.height = ""; 
                  videoContainer.className += ' remove-bar'; 
                  videoContainer.style.padding = "0";
                  videoContainer.width = "100%";
                  let panelInfoHTML = blockedContentMessage.cloneNode(true);                    
                  videoContainer.append(panelInfoHTML);                      
                  videoContainer.querySelector('.update-preferences').addEventListener("click", updatePreferences, false);                       
              };
            });      
            document.querySelectorAll('div[embed-tweet]').forEach((twitterEmbed)=> {
                //twitter embedded tweet component
                if (twitterEmbed.childNodes.length == 0){ 
                    let panelInfoHTML = blockedContentMessage.cloneNode(true);                    
                    twitterEmbed.append(panelInfoHTML);                      
                    twitterEmbed.querySelector('.update-preferences').addEventListener("click", updatePreferences, false);  
                }                        
            }); 
            document.querySelectorAll('a.twitter-timeline').forEach((twitterTimeLine)=> {
                //twitter timeline component
                var parentRow =  twitterTimeLine.closest(".twitter-timeline__container");

                if(parentRow != null){
                    var newRow = document.createElement("div");
                    let panelInfoHTML = blockedContentMessage.cloneNode(true);                    
                    newRow.append(panelInfoHTML);                      
                    newRow.querySelector('.update-preferences').addEventListener("click", updatePreferences, false);                    
                    parentRow.replaceWith(newRow);
                };
            });                             
          }
        };         

        return {
          init : init
        };
    }());      

    $(document).ready(function()  {

        PRSapp.BlockedContent.init();

    }); 
  

})(jQuery, ResponsiveBootstrapToolkit);