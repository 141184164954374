/* eslint-disable prefer-rest-params */
/* eslint-disable guard-for-in */
/**
 * @namespace mlcs
 * @memberof PRSapp
 */

(function ($) {
  PRSapp.mlcs = (function () {
    const baseUrl = $('#llc_base_url').val();

    let selectedTerritory = '';
    let requiresOfficeVisitors = false;
    let selectedCurrencyCode = '';
    let calculateInputsDisplay = false;

    const basePostOptions = (body) => ({
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        accepts: 'application/json',
      },
      credentials: 'same-origin',
    });

    function showLoader() {
      const overlay = $("<div id='prs-overlay'><div id='prs-spinner'></div></div>");
      $('body').prepend(overlay);
    }

    function hideLoader() {
      $('body #prs-overlay').remove();
    }

    function fetchData(url, options) {
      // GET
      showLoader();

      if (!options) {
        return fetch(url, { credentials: 'same-origin' })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw Error(response);
          }).finally(() => {
            hideLoader();
          });
      }

      return fetch(url, basePostOptions({
        ...options,
      }))
        .then((response) => {
          if (response.ok || response.status === 303) {
            return response.json();
          }
          throw Error(response);
        }).finally(() => {
          hideLoader();
        });
    }

    const canSplit = (str, token) => (str || '').split(token).length > 1;

    const isMobileMode = () => $('#mobile-mode').is(':visible');

    function showSummary(bShow) {
      const elm = $('#cc-page-summary');
      // eslint-disable-next-line no-unused-expressions
      bShow ? elm.show() : elm.hide();
    }

    function showAdditionalDetails(bShow) {
      const elm = $('#cc-page-tabs');
      // eslint-disable-next-line no-unused-expressions
      bShow ? elm.show() : elm.hide();
    }

    function showInputOptions(bShow) {
      const elm = $('#cc-inputs');
      // eslint-disable-next-line no-unused-expressions
      bShow ? elm.show() : elm.hide();
    }

    function showSummaryOverview(bShow) {
      const elm = $('#cc-page-summary-overview');
      // eslint-disable-next-line no-unused-expressions
      bShow ? elm.show() : elm.hide();
    }

    // eslint-disable-next-line no-extend-native
    String.prototype.format = function () {
      let a = this;
      // eslint-disable-next-line no-restricted-syntax
      for (const k in arguments) {
        a = a.replace(`{${k}}`, arguments[k]);
      }
      return a;
    };

    function updateElement(el, val) {
      $(el).each(function () {
        $(this).html(val);
      });
    }

    function renderCountryData(data) {
      updateElement('.licensing-info', data.LicensingText);
      updateElement('.distributionText-info', data.DistributionText);
      updateElement('.additionalText-info', data.AdditionalText);
      updateElement('.mlcsText-info', data.MLCSText);

      $('#cc_amount').attr('placeholder', data.CurrencyCode);

      selectedCurrencyCode = data.CurrencyCode;
      calculateInputsDisplay = data.IsRateCalculated;

      if (data.IsBoxOfficeVisitorsRequiredForCalculation) {
        requiresOfficeVisitors = true;
        $('#cc_ticket_input').show();
      }
    }

    function resetCountryData() {
      updateElement('.licensing-info', '');
      updateElement('.distributionText-info', '');
      updateElement('.additionalText-info', '');
      updateElement('.mlcsText-info', '');

      $('#cc_amount').attr('placeholder', '');

      $('#cc_ticket_input').hide();

      $('#cc_amount').val('');
      requiresOfficeVisitors = false;
      selectedCurrencyCode = '';
      $('#cc_ticket_input').val('');

      if (!isMobileMode()) {
        $('#calculate').attr('disabled', 'disabled');
      } else {
        $('#calculate').removeAttr('disabled');
      }
    }

    function renderRoyaltyDiscountData(data) {
      $('#cc-input-options').children().remove();

      const chxOptions = data.filter((x) => !x.IsMutuallyExclusive).sort((a, b) => a.Rank - b.Rank);
      const radOptions = data.filter((x) => x.IsMutuallyExclusive).sort((a, b) => a.Rank - b.Rank);

      const chxbxItems = chxOptions.map((x) => {
        const p = $('<p class="mt-20 discount-option">');
        const label = $(`<label class="form-check-label" for='${x.Id}'>${x.Value}</label>`);

        const input = $(`<input type='checkbox' class='form-check-input' id='${x.Id}' value='${x.Id}'>`);

        p.append(input);
        p.append(label);
        return p;
      });

      const radioItems = radOptions.map((x) => {
        const p = $('<p class="mt-20 discount-option">');
        const label = $(`<label class="form-check-label" for='${x.Id}'>${x.Value}</label>`);

        const input = $(`<input type='radio' class='form-check-input' id='${x.Id}' value='${x.Id}' name="dateSelection">`);

        p.append(input);
        p.append(label);
        return p;
      });

      const hasChxOptions = chxbxItems.length > 0;

      if (hasChxOptions) {
        $('#cc-input-options').append(...chxbxItems);
      }

      if (radioItems.length > 0) {
        if (hasChxOptions) { $('#cc-input-options').append($('<hr />')); }
        $('#cc-input-options').append(...radioItems);
      }

      $('#cc-input-options').find('input[type=radio]').each(function () {
        const { id } = this;

        $(`label[for="${id}"]`).mousedown(() => {
          $(this).parent().find('input[type=radio]').trigger('mousedown');
        });

        $(`label[for="${id}"]`).click(() => {
          $(this).parent().find('input[type=radio]').trigger('click');
        });

        $(this).mousedown(function () {
          $(this).data('wasChecked', this.checked);
        });

        $(this).click(function () {
          if ($(this).data('wasChecked')) { this.checked = false; }
        });
      });

      return data;
    }

    function renderCalculationData(data) {
      if (data.IsRateCalculationPossible) {
        const currencyCode = selectedCurrencyCode || 'GBP';
        $('#display-total').html(`${currencyCode} ${parseFloat(data.CalculatedRate).toFixed(2)}`);
      }
    }

    function renderUnavailable(unavailable) {
      if (unavailable) {
        $('#cc-unavailable').hide();
        return;
      }

      $('#cc-unavailable').show();
    }

    function getCalculationData(countryDetail) {
      const countryArray = countryDetail.split('-');
      const country = countryArray.shift().trim();
      const society = countryArray.join('-').trim();

      const uri = `${baseUrl}/CalculateRoyalty/`;

      const checkedValues = [];

      $('.form-check-input:checkbox:checked').each(function () {
        checkedValues.push($(this).val());
      });

      $('.form-check-input:radio:checked').each(function () {
        checkedValues.push($(this).val());
      });

      const postData = {
        Territory: country,
        Society: society,
        BoxOfficeVisitors: requiresOfficeVisitors ? $('#cc_ticket_count').val() : '',
        GrossBoxOfficeExcludingTax: $('#cc_amount').val(),
        DiscountIds: checkedValues,
      };
      return fetchData(uri, postData);
    }

    function getRoyaltyDiscountData(country, society) {
      const uri = `${baseUrl}/RoyaltyDiscountsByTerritoryandSociety/`;
      return fetchData(uri, { Society: society, Territory: country });
    }

    function getCountryData(countryDetail) {
      const countryArray = countryDetail.split('-');
      const country = countryArray.shift().trim();
      const society = countryArray.join('-').trim();

      const uri = baseUrl + '/EffectiveRateByTerritoryandSociety/{0}/{1}'.format(country, society);
      return fetchData(uri);
    }

    function setupTabSwitcher() {
      $('#cc-tabs').find('.nav-link').each((idx, el) => {
        $(el).click((e) => {
          e.preventDefault();

          $('#cc-page-tabs').find('.active').removeClass('active');
          $(el).parent().addClass('active');

          $('#cc-tabs-content').find('.tab-content.active').removeClass('active');
          const items = $('#cc-tabs-content').find('.tab-content');
          $(items[idx]).addClass('active');
        });
      });
    }

    function cloneTabContent() {
      $('#cc-tabs').find('.nav-link').each((idx, el) => {
        $(el).parent().append('<span class="open">+</span>');
        $(el).parent().append('<span class="close">-</span>');
        const items = $('#cc-tabs-content').find('.tab-content');

        $(el).append($(items[idx]).clone());
      });
    }

    function setupCountryChangeEvent() {
      $('#cc_country').on('input', (el) => {
        const val = el.target.value;

        if (val !== '' && $('#countryOptions option').filter(function () {
          return this.value.toUpperCase() === val.toUpperCase();
        }).length > 0 && canSplit(val, '-')) {
          selectedTerritory = val;

          getCountryData(val)
            .then((data) => {
              resetCountryData();

              renderUnavailable(data.IsRateCalculated);

              renderCountryData(data);

              return data;
            })
            .then((data) => getRoyaltyDiscountData(data.Territory, data.Society))
            .then((data) => {
              renderRoyaltyDiscountData(data);
              return data;
            })
            .then(() => showInputOptions(calculateInputsDisplay))
            .then(() => showAdditionalDetails(true))
            .catch((err) => {
              console.log(err);
            });
        } else {
          showInputOptions(false);
          showAdditionalDetails(true); // always show tabbed content
          showSummary(false);
          showSummaryOverview(false);
        }
      }).on('focus', (el) => {        
        el.target.value = '';
                
        showInputOptions(false);
        showAdditionalDetails(false);
        showSummary(false);
        showSummaryOverview(false);
      });
    }

    function validateInputsForMobile() {
      let isValid = true;
      if (isMobileMode()) {
        if ($('#cc_amount').val().replace(/ /g, '') === '') {
          isValid = false;
          $('#cc_amount').addClass('error').focus(function () {
            $(this).removeClass('error').unbind('focus');
          });
        }

        if (requiresOfficeVisitors && $('#cc_ticket_count').val().replace(/ /g, '') === '') {
          isValid = false;
          $('#cc_ticket_count').addClass('error').focus(function () {
            $(this).removeClass('error').unbind('focus');
          });
        }
      }

      return isValid;
    }

    function setupCalculateClick() {
      $('#calculate').click(() => {
        if (!validateInputsForMobile()) return;

        $('#calculate').attr('disabled', true);

        getCalculationData(selectedTerritory)
          .then((data) => renderCalculationData(data))
          .then(() => {
            showSummary(true);
            showSummaryOverview(true);

            $('#calculate').removeAttr('disabled');

            if (isMobileMode()) {
              $([document.documentElement, document.body]).animate({
                scrollTop: $('#cc-page-summary').offset().top,
              }, 500);
            }
          });
      });
    }

    function setupInputOnChangeEvents() {
      $('.value-input').keyup(function (e) {
      const value = $(this).val();
      $(this).val(value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'));
      const amount = $('#cc_amount').val().replace(/ /g, '');
      const ticketAmount = $('#cc_ticket_count').val().replace(/ /g, '');

      if (requiresOfficeVisitors) {
        if (ticketAmount === '' || amount === '') {
          $('#calculate').attr('disabled', 'disabled');
          return;
        }

        $('#calculate').removeAttr('disabled');
      } else {
        if (amount === '') {
          $('#calculate').attr('disabled', 'disabled');
          return;
        }
        $('#calculate').removeAttr('disabled');
      }
    });
  }

    const init = () => {
      setupCountryChangeEvent();

      setupTabSwitcher();

      setupCalculateClick();

      setupInputOnChangeEvents();

      cloneTabContent();
    };

    return {
      init,
    };
  }());

  PRSapp.mlcs.init();
}(jQuery));
